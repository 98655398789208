import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import {
  ApplyCouponToCartInput,
  BillingAddressInput,
  Cart,
  CartItemInput,
  CategoryInterface,
  Customer,
  CustomerAddressInput,
  MutationUpdateCustomerAddressArgs,
  PaymentMethodInput,
  ProductInterface,
  RemoveCouponFromCartInput,
  RemoveItemFromCartInput,
  SetShippingAddressesOnCartInput,
  UpdateCartItemsInput,
} from '../models/shop';
import { CountryData, SquareData } from '../models/shopCustom';
import {
  ADD_MUTI_PRODUCT_TO_CART,
  ADD_PRODUCT_VIRTUAL_TO_CART,
  APPLY_COUPON,
  CARD_LOOKUP_MONERIS,
  CREATE_CUSTOMER_ADDRESS,
  CREATE_EMPTY_CART,
  DEACTIVE_CUSTOMER_CART,
  DELETE_CUSTOMER_ADDRESS,
  PROCESS_MPI_3D_AUTHENTICATION,
  REMOVE_COUPON_FROM_CART,
  REMOVE_ITEM_FROM_CART,
  SET_BILLING_ADDRESS_ON_CART,
  SET_PAYMENT_METHOD_AND_PLACE_ORDER,
  SET_SHIPPING_ADDRESSES_ON_CART,
  UPDATE_CART_ITEMS,
  UPDATE_CUSTOMER_ADDRESS,
  UPDATE_QUANTITY_MINI_CART,
} from '../utils/mutations';
import {
  GET_COUNTRIES,
  GET_CUSTOMER_CART,
  GET_CUSTOMER_INFO,
  GET_PRODUCTS_BY_SKU,
  GET_PRODUCTS_BY_URL_KEY,
  GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import countriesData from './country-state.json';
import { signOut } from './user';

export const getProductSkytrakByCategoryId = createAsyncThunk(
  'shopSKytrak/getProductSkytrakByCategoryId',
  async (
    payload: {
      id: string[];
      pageSize: number;
      currentPage: number;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE),
          variables: {
            id: payload.id,
            pageSize: payload.pageSize,
            currentPage: payload.currentPage,
            sortEnum: 'ASC',
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerSkytrakInfo = createAsyncThunk(
  'shopSKytrak/getCustomerSkytrakInfo',
  async (payload: { isSkytrakGolftec: boolean }, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customer },
        },
      } = await request.post(
        'graphql',
        {
          query: print(GET_CUSTOMER_INFO),
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return customer;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deActiveCustomerCart = createAsyncThunk(
  'shopSKytrak/deActiveCustomerCart',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(DEACTIVE_CUSTOMER_CART),
        },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createEmptyCartSkytrak = createAsyncThunk(
  'shopSKytrak/createEmptyCartSkytrak',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: { createEmptyCart },
        },
      } = await request.post(
        'graphql',
        {
          query: print(CREATE_EMPTY_CART),
        },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return createEmptyCart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerSkytrakCart = createAsyncThunk(
  'shopSKytrak/getCustomerSkytrakCart',
  async (
    payload: { isSkytrakGolftec: boolean } | undefined,
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: { customerCart },
        },
      } = await request.post(
        'graphql',
        { query: print(GET_CUSTOMER_CART) },
        {
          params: {
            isSkytrakGolftec: payload?.isSkytrakGolftec,
          },
        }
      );
      return customerCart;
    } catch (e) {
      console.log(e);
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cardLookUpMoneris = createAsyncThunk(
  'shopSkytrak/cardLookUpMoneris',
  async (
    payload: {
      cart_id: string;
      cc_number: string;
      store_id: number;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(CARD_LOOKUP_MONERIS),
          variables: {
            cartId: payload.cart_id,
            ccNumber: payload.cc_number,
            storeId: payload.store_id,
          },
        },
        {
          params: { isSkytrakGolftec: payload.isSkytrakGolftec },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const processMpi3DAuthentication = createAsyncThunk(
  'shopSkytrak/processMpi3DAuthentication',
  async (
    payload: {
      incrementId: string;
      cardName: string;
      ccExpMonth: string;
      ccExpYear: string;
      ccNumber: string;
      userAgent: string;
      uRL: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(PROCESS_MPI_3D_AUTHENTICATION),
          variables: {
            incrementId: payload.incrementId,
            cardName: payload.cardName,
            ccExpMonth: payload.ccExpMonth,
            ccExpYear: payload.ccExpYear,
            ccNumber: payload.ccNumber,
            userAgent: payload.userAgent,
            uRL: payload.uRL,
          },
        },
        {
          params: { isSkytrakGolftec: payload.isSkytrakGolftec },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createCustomerAddressSkytrak = createAsyncThunk(
  'shopSkytrak/createCustomerAddressSkytrak',
  async (
    payload: {
      customerAddressInput: CustomerAddressInput;
      isSkytrakGolftec: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(CREATE_CUSTOMER_ADDRESS),
          variables: { customerAddressInput: payload.customerAddressInput },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      if (data.errors) {
        return rejectWithValue(data.errors);
      }

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCustomerSkytrakAddress = createAsyncThunk(
  'shopSkytrak/updateCustomerSkytrakAddress',
  async (
    payload: {
      update: MutationUpdateCustomerAddressArgs;
      isSkytrakGolftec: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(UPDATE_CUSTOMER_ADDRESS),
          variables: { id: payload.update.id, input: payload.update.input },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCustomerSkytrakAddress = createAsyncThunk(
  'shopSkytrak/deleteCustomerSkytrakAddress',
  async (
    payload: { id: number; isSkytrakGolftec: boolean },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(DELETE_CUSTOMER_ADDRESS),
          variables: { id: payload.id },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setBillingAddressSkytrakOnCart = createAsyncThunk(
  'shopSkytrak/setBillingAddressSkytrakOnCart',
  async (
    payload: {
      billingAddressInput: BillingAddressInput;
      cartId: string;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            setBillingAddressOnCart: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(SET_BILLING_ADDRESS_ON_CART),
          variables: {
            billingAddress: payload.billingAddressInput,
            cartId: payload.cartId,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setShippingAddressesSkytrakOnCart = createAsyncThunk(
  'shopSkytrak/setShippingAddressesSkytrakOnCart',
  async (
    payload: {
      input: SetShippingAddressesOnCartInput;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(SET_SHIPPING_ADDRESSES_ON_CART),
          variables: {
            input: {
              ...payload.input,
              cart_id: payload.input.cart_id,
            },
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setPaymentMethodAndPlaceOrderSkytrak = createAsyncThunk(
  'shopSkytrak/setPaymentMethodAndPlaceOrderSkytrak',
  async (
    payload: {
      cart_id: string;
      paymentInfo: PaymentMethodInput;
      card_name?: string;
      isSkytrakGolftec: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(SET_PAYMENT_METHOD_AND_PLACE_ORDER),
          variables: {
            input: {
              cart_id: payload.cart_id,
              payment_method: payload.paymentInfo,
            },
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addProductVirtualSkytrakToCart = createAsyncThunk(
  'shopSkytrak/addProductVirtualSkytrakToCart',
  async (
    payload: {
      quantity: number;
      sku: string;
      cartId: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(ADD_PRODUCT_VIRTUAL_TO_CART),
          variables: {
            cartId: payload.cartId,
            quantity: payload.quantity,
            sku: payload.sku,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addProductsToCart = createAsyncThunk(
  'shopSkytrak/addProductVirtualSkytrakToCart',
  async (
    payload: {
      cartId: string;
      cartItems: CartItemInput[];
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            addProductsToCart: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(ADD_MUTI_PRODUCT_TO_CART),
          variables: {
            cartId: payload.cartId,
            cartItems: payload.cartItems,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return cart;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateQuantityMiniCartSkytrak = createAsyncThunk(
  'shopSkytrak/updateQuantityMiniCartSkytrak',
  async (
    payload: {
      cartId: string;
      quantity: number;
      itemId: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            updateCartItems: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(UPDATE_QUANTITY_MINI_CART),
          variables: {
            cartId: payload.cartId,
            quantity: payload.quantity,
            itemId: payload.itemId,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateItemCartSkytrak = createAsyncThunk(
  'shopSkytrak/updateItemCartSkytrak',
  async (
    payload: {
      input: UpdateCartItemsInput;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            updateCartItems: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(UPDATE_CART_ITEMS),
          variables: {
            input: payload.input,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeItemInCartSkytrak = createAsyncThunk(
  'shopSkytrak/removeItemInCartSkytrak',
  async (
    payload: {
      input: RemoveItemFromCartInput;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            removeItemFromCart: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(REMOVE_ITEM_FROM_CART),
          variables: {
            input: payload.input,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCountries = createAsyncThunk(
  'shop/getCountries',
  async (payload: { isSkytrakGolftec: boolean }, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { countries },
        },
      } = await request.post(
        'graphql',
        {
          query: print(GET_COUNTRIES),
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      if (countries) {
        return countries;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductByUrlKeySkytrak = createAsyncThunk(
  'shopSkytrak/getProductByUrlKeySkytrak',
  async (
    payload: { url_key: string[]; isSkytrakGolftec: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(GET_PRODUCTS_BY_URL_KEY),
          variables: {
            url_key: payload.url_key,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductBySkuSkytrak = createAsyncThunk(
  'shopSkytrak/getProductBySkuSkytrak',
  async (
    payload: { sku: string[]; isSkytrakGolftec: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(GET_PRODUCTS_BY_SKU),
          variables: {
            sku: payload.sku,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSimulationProductBySkuSkytrak = createAsyncThunk(
  'shopSkytrak/getSimulationProductBySkuSkytrak',
  async (
    payload: { sku: string[]; isSkytrakGolftec: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        'graphql',
        {
          query: print(GET_PRODUCTS_BY_SKU),
          variables: {
            sku: payload.sku,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const applyCouponSkytrak = createAsyncThunk(
  'shopSkytrak/applyCouponSkytrak',
  async (
    payload: { input: ApplyCouponToCartInput; isSkytrakGolftec: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            applyCouponToCart: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(APPLY_COUPON),
          variables: {
            input: payload.input,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeCouponSkytrak = createAsyncThunk(
  'shopSkytrak/removeCouponSkytrak',
  async (
    payload: { input: RemoveCouponFromCartInput; isSkytrakGolftec: boolean },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        data: {
          data: {
            removeCouponFromCart: { cart },
          },
        },
      } = await request.post(
        'graphql',
        {
          query: print(REMOVE_COUPON_FROM_CART),
          variables: {
            input: payload.input,
          },
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );

      return cart;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changeStatusSubscriptionSkytrakPlan = createAsyncThunk(
  'shopSkytrak/changeStatusSubscriptionSkytrakPlan',
  async (
    payload: {
      status: 'pause' | 'resume';
      subscription_id: number;
      skytrakStoreCode: string;
      isSkytrakGolftec: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/rest/${payload.skytrakStoreCode}/V1/square/subscription/${payload.status}`,
        {
          subscription_id: payload.subscription_id,
        },
        {
          params: {
            isSkytrakGolftec: payload.isSkytrakGolftec,
          },
        }
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ShopSkytrakState {
  dataByCategory: null | CategoryInterface[];
  customer: null | Customer;
  loadingAddress: boolean;
  cart: null | Cart;
  cartCloneSimulation: null | Cart;
  chooseCustomerAddressShippingId: null | number;
  chooseCustomerAddressBillingId: null | number;
  defaultAddress: string;
  countriesData: CountryData[] | null;
  dataProductSkytrak: null | ProductInterface[];
  squareData: SquareData | null;
  dataSimulationProductSkytrak: null | ProductInterface[];
  loadingUpdateMiniCartSkytrak: boolean;
  cartIdSkytrak: string | null;
  loadingDeactiveCart: boolean;
  loadingCreateEmptyCart: boolean;
  loadingSwitchSubscriptions: boolean;
}

const initialState: ShopSkytrakState = {
  dataByCategory: null,
  customer: null,
  loadingAddress: false,
  cart: null,
  cartCloneSimulation: null,
  chooseCustomerAddressShippingId: null,
  chooseCustomerAddressBillingId: null,
  defaultAddress: '',
  countriesData: null,
  dataProductSkytrak: null,
  squareData: null,
  dataSimulationProductSkytrak: null,
  loadingUpdateMiniCartSkytrak: false,
  cartIdSkytrak: null,
  loadingDeactiveCart: false,
  loadingCreateEmptyCart: false,
  loadingSwitchSubscriptions: false,
};

const shopSkytrakSlice = createSlice({
  name: 'shopSkytrak',
  initialState,
  reducers: {
    changeChooseCustomerAddressShippingSkytrakId: (state, action) => {
      state.chooseCustomerAddressShippingId = action.payload;
    },
    changeChooseCustomerAddressBillingSkytrakId: (state, action) => {
      state.chooseCustomerAddressBillingId = action.payload;
    },
    changeDefaultAddressSkytrak: (state, action) => {
      state.defaultAddress = action.payload;
    },
    changeDataSquare: (state, action) => {
      state.squareData = action.payload;
    },
    changeCartCloneSimulation: (state, action) => {
      state.cartCloneSimulation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProductSkytrakByCategoryId.fulfilled,
      (state, action) => {
        if (action.payload.data?.categories?.items) {
          state.dataByCategory = action.payload.data?.categories.items;
        }
      }
    );
    builder.addCase(getProductByUrlKeySkytrak.fulfilled, (state, action) => {
      if (action.payload.data?.products?.items?.length > 0) {
        state.dataProductSkytrak = action.payload.data?.products?.items;
      }
    });
    builder.addCase(getProductBySkuSkytrak.fulfilled, (state, action) => {
      if (action.payload.data?.products?.items?.length > 0) {
        state.dataProductSkytrak = action.payload.data?.products?.items;
      }
    });
    builder.addCase(
      getSimulationProductBySkuSkytrak.fulfilled,
      (state, action) => {
        if (action.payload.data?.products?.items?.length > 0) {
          state.dataSimulationProductSkytrak =
            action.payload.data?.products?.items;
        }
      }
    );
    builder.addCase(getCustomerSkytrakInfo.pending, (state, action) => {
      state.loadingAddress = true;
    });
    builder.addCase(getCustomerSkytrakInfo.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.loadingAddress = false;
    });
    builder
      .addCase(deActiveCustomerCart.pending, (state, action) => {
        state.loadingDeactiveCart = true;
      })
      .addCase(deActiveCustomerCart.fulfilled, (state, action) => {
        state.loadingDeactiveCart = false;
      });
    builder
      .addCase(createEmptyCartSkytrak.pending, (state, action) => {
        state.loadingCreateEmptyCart = true;
      })
      .addCase(createEmptyCartSkytrak.fulfilled, (state, action) => {
        state.loadingCreateEmptyCart = false;
        state.cartIdSkytrak = action.payload;
        state.cart = null;
        state.cartCloneSimulation = null;
      });
    builder
      .addCase(getCustomerSkytrakCart.pending, (state) => {
        // state.loadingAddress = true;
      })
      .addCase(getCustomerSkytrakCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        // state.loadingAddress = false;
      });
    builder
      .addCase(setBillingAddressSkytrakOnCart.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(setBillingAddressSkytrakOnCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingAddress = false;
      })
      .addCase(addProductsToCart.pending, (state, action) => {
        state.loadingSwitchSubscriptions = true;
      })
      .addCase(addProductsToCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingSwitchSubscriptions = false;
      })
      .addCase(applyCouponSkytrak.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(updateQuantityMiniCartSkytrak.pending, (state, action) => {
        state.loadingUpdateMiniCartSkytrak = true;
      })
      .addCase(updateQuantityMiniCartSkytrak.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingUpdateMiniCartSkytrak = false;
      })
      .addCase(updateItemCartSkytrak.pending, (state) => {
        state.loadingUpdateMiniCartSkytrak = true;
      })
      .addCase(removeItemInCartSkytrak.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingUpdateMiniCartSkytrak = false;
      })
      .addCase(applyCouponSkytrak.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingAddress = false;
      })
      .addCase(removeCouponSkytrak.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(removeCouponSkytrak.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.cartIdSkytrak = action.payload.id;
        state.loadingAddress = false;
      });
    builder
      .addCase(createCustomerAddressSkytrak.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(createCustomerAddressSkytrak.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(updateCustomerSkytrakAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(updateCustomerSkytrakAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder
      .addCase(deleteCustomerSkytrakAddress.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(deleteCustomerSkytrakAddress.fulfilled, (state, action) => {
        state.loadingAddress = false;
      });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      if (action.payload) {
        state.countriesData = action.payload;
      } else {
        state.countriesData = countriesData.countries;
      }
    });
    builder.addCase(getCountries.rejected, (state) => {
      state.countriesData = countriesData.countries;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingAddress = false;
        state.loadingUpdateMiniCartSkytrak = false;
      }
    );
  },
});

export const {
  changeDefaultAddressSkytrak,
  changeChooseCustomerAddressShippingSkytrakId,
  changeChooseCustomerAddressBillingSkytrakId,
  changeDataSquare,
  changeCartCloneSimulation,
} = shopSkytrakSlice.actions;

export const selectDefaultAddressSkytrak = (state: RootState) =>
  state.shop.defaultAddress;
export const selectDataProductByCategorySkytrak = (state: RootState) =>
  state.shopSkytrak.dataByCategory;
export const selectCustomerSkytrak = (state: RootState) =>
  state.shopSkytrak.customer;
export const selectLoadingAddressSkytrak = (state: RootState) =>
  state.shopSkytrak.loadingAddress;
export const selectCustomerCartSkytrak = (state: RootState) =>
  state.shopSkytrak.cart;
export const selectCustomerAddressShippingSkytrakId = (state: RootState) =>
  state.shopSkytrak.chooseCustomerAddressShippingId;
export const selectCustomerAddressBillingSkytrakId = (state: RootState) =>
  state.shopSkytrak.chooseCustomerAddressBillingId;
export const selectCustomerAddressesSkytrak = (state: RootState) =>
  state.shopSkytrak.customer?.addresses;
export const selectCountriesData = (state: RootState) =>
  state.shopSkytrak.countriesData;
export const selectDataProductSkytrak = (state: RootState) =>
  state.shopSkytrak.dataProductSkytrak;
export const selectDataSquare = (state: RootState) =>
  state.shopSkytrak.squareData;
export const selectDataSimulationProductSkytrak = (state: RootState) =>
  state.shopSkytrak.dataSimulationProductSkytrak;
export const selectLoadingUpdateMiniCartSkytrak = (state: RootState) =>
  state.shopSkytrak.loadingUpdateMiniCartSkytrak;
export const selectCartIdSkytrak = (state: RootState) =>
  state.shopSkytrak.cartIdSkytrak;
export const selectLoadingDeactiveCart = (state: RootState) =>
  state.shopSkytrak.loadingDeactiveCart;
export const selectLoadingCreateEmptyCart = (state: RootState) =>
  state.shopSkytrak.loadingCreateEmptyCart;
export const selectLoadingSwitchSubscriptions = (state: RootState) =>
  state.shopSkytrak.loadingSwitchSubscriptions;
export const selectCartCloneSimulation = (state: RootState) =>
  state.shopSkytrak.cartCloneSimulation;

export default shopSkytrakSlice.reducer;
