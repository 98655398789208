import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import _ from 'lodash';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchMyBags = createAsyncThunk(
  'myBags/fetchMyBags',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/my-bags');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllManufacturer = createAsyncThunk(
  'myBags/fetchAllManufacturer',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/manufacturers');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllClubCategoriesParents = createAsyncThunk(
  'myBags/fetchAllClubCategoriesParents',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/club-categoiries-parent');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMyBagById = createAsyncThunk(
  'myBags/getMyBagById',
  async (bagId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/bag/${bagId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteBag = createAsyncThunk(
  'myBags/deleteBag',
  async (bagId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`/api/bag/${bagId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createBag = createAsyncThunk(
  'myBags/createBag',
  async (bagName: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/bag`, { bagName });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editBag = createAsyncThunk(
  'myBags/editBag',
  async (payload: { id: number; bagName: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`/api/bag`, {
        id: payload.id,
        bagName: payload.bagName,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchClubOfBags = createAsyncThunk(
  'myBags/fetchClubOfBags',
  async (bagId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/clubs?bagId=${bagId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addMyClub = createAsyncThunk(
  'myBags/addMyClub',
  async (payload: ClubData, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/club`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editMyClub = createAsyncThunk(
  'myBags/editMyClub',
  async (payload: ClubData, { rejectWithValue }) => {
    try {
      const { data } = await request.put(`/api/club`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchClubOfBagByBagId = createAsyncThunk(
  'myBags/fetchClubOfBagByBagId',
  async (bagId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/clubs/${bagId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getClubById = createAsyncThunk(
  'myBags/getClubById',
  async (clubId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/clubs/${clubId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editRangeClubs = createAsyncThunk(
  'myBags/editRangeClubs',
  async (
    payload: Array<{
      id?: number;
      maxRange: number;
      minRange: number;
    } | null>,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        `/api/clubs`,
        payload.map(
          (i) =>
            i !== null && {
              ...i,
            }
        )
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUrlUploadClubImage = createAsyncThunk(
  'myBags/getUrlUploadClubImage',
  async (clubId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/presign-upload-club-image-url/${clubId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const putUrlUploadClubImage = createAsyncThunk(
  'myBags/putUrlUploadClubImage',
  async (payload: { url: string; file: File | '' }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(payload.url, payload.file, {
        headers: {
          'Content-Type': payload.file ? payload.file.type : '',
          'Content-Disposition': `attachment; filename=${
            payload.file ? payload.file.name : ''
          }`,
        },
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUrlClubImage = createAsyncThunk(
  'myBags/getUrlClubImage',
  async (clubId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/presign-get-club-image-url/${clubId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllCategoryClubs = createAsyncThunk(
  'myBags/getAllCategories',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/club-categories`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteClub = createAsyncThunk(
  'myBags/deleteClub',
  async (clubId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`/api/club/${clubId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createMutiClubs = createAsyncThunk(
  'myBags/createMutiClubs',
  async (payload: ClubData[], { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/clubs`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface ClubData {
  id?: number;
  bagId: number;
  loft: number | null;
  averageDistance: number | null;
  minRange: number;
  maxRange: number;
  modelId: number | null;
  modelName: string | null;
  manufacturerId: number | null;
  manufacturerName: string | null;
  categoryId?: number;
  categoryName?: string;
  categoryShortName?: string;
  categoryParentId?: number;
  categoryParentName?: string;
  categoryParentShortName?: string;
  status: boolean;
  sortOrder?: number;
  created?: Date;
  lastModified?: Date;
  slot: number | null;
}

export interface MyBagsData {
  id: number;
  playerId: number;
  customerId: number;
  bagName: string;
  clubsQuantity: string;
  created: Date;
  lastModified?: Date;
}

export interface ClubOfBagsData {
  bagName: string;
  clubsQuantity: string;
  id: number;
  playerClubList: ClubData[];
}

export interface ManufacturerData {
  id: number | null;
  name: string;
}

export interface ClubCategory {
  id: number;
  name: string;
  shortName: string;
  parentId: number;
  subCates: ClubCategory[] | null;
}

export interface MyBagsState {
  dataClubOfBags: ClubOfBagsData[];
  dataClubOfBagById: ClubData[];
  dataMyBags: MyBagsData | null;
  dataMyBagById: MyBagsData | null;
  loadingClubOfBags: boolean;
  loadingMyBags: boolean;
  loadingClubOfBagById: boolean;
  statusOpenDeleteBag: boolean;
  statusOpenAddBag: boolean;
  statusOpenAddClub: boolean;
  dataManufacturer: ManufacturerData[] | null;
  dataClubCategoriesParent: ClubCategory[] | null;
  selectClubEdit: ClubData | null;
  statusOpenEditClub: boolean;
  urlImage: string;
  allCategories: ClubCategory[] | null;
  selectCategoryClubEdit: ClubCategory | null;
  selectCategoryClubCreate: ClubCategory | null;
  statusOpenDeleteClub: boolean;
}

const initialState: MyBagsState = {
  dataClubOfBags: [],
  dataMyBags: null,
  dataMyBagById: null,
  loadingClubOfBags: false,
  loadingMyBags: false,
  dataClubOfBagById: [],
  loadingClubOfBagById: false,
  statusOpenDeleteBag: false,
  statusOpenAddBag: false,
  statusOpenAddClub: false,
  dataManufacturer: null,
  dataClubCategoriesParent: null,
  selectClubEdit: null,
  statusOpenEditClub: false,
  urlImage: '',
  allCategories: null,
  selectCategoryClubEdit: null,
  selectCategoryClubCreate: null,
  statusOpenDeleteClub: false,
};

export const myBagsSlice = createSlice({
  name: 'myBags',
  initialState,
  reducers: {
    changeStatusOpenDeteleBag: (state, action) => {
      state.statusOpenDeleteBag = action.payload;
    },
    changeStatusOpenAddBag: (state, action) => {
      state.statusOpenAddBag = action.payload;
    },
    changeStatusOpenAddClub: (state, action) => {
      state.statusOpenAddClub = action.payload;
    },
    changeStatusOpenEditClub: (state, action) => {
      state.statusOpenEditClub = action.payload;
    },
    changeStatusOpenRemoveClub: (state, action) => {
      state.statusOpenDeleteClub = action.payload;
    },
    changeSelectClubEdit: (state, action) => {
      state.selectClubEdit = action.payload;
    },
    changeSelectCategoryClub: (state, action) => {
      state.selectCategoryClubEdit = action.payload;
    },
    changeSelectCategoryClubCreate: (state, action) => {
      state.selectCategoryClubCreate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyBags.pending, (state, action) => {
        if (!state.dataMyBags) {
          state.loadingMyBags = true;
        }
      })
      .addCase(fetchMyBags.fulfilled, (state, action) => {
        state.loadingMyBags = false;
        if (
          action.payload.data &&
          Array.isArray(action.payload.data) &&
          action.payload.data.length > 0
        ) {
          const array: MyBagsData[] = action.payload.data.filter(
            (i: MyBagsData) => i.lastModified
          );
          if (array.length > 0) {
            const data = _.maxBy(array, 'lastModified');
            if (data) {
              state.dataMyBags = data;
            }
          } else {
            const arrayData: MyBagsData[] = action.payload.data;
            const data = _.maxBy(arrayData, 'id');
            if (data) {
              state.dataMyBags = data;
            }
          }
        }
      })
      .addCase(fetchClubOfBags.pending, (state, action) => {
        if (state.dataClubOfBags.length === 0) {
          state.loadingClubOfBags = true;
        }
      })
      .addCase(fetchClubOfBags.fulfilled, (state, action) => {
        state.loadingClubOfBags = false;
        state.dataClubOfBags = action.payload.data;
      })
      .addCase(fetchClubOfBagByBagId.pending, (state, action) => {
        if (state.dataClubOfBagById.length === 0) {
          state.loadingClubOfBagById = true;
        }
      })
      .addCase(fetchClubOfBagByBagId.fulfilled, (state, action) => {
        state.loadingClubOfBagById = false;
        state.dataClubOfBagById = action.payload.data;
      })
      .addCase(getMyBagById.fulfilled, (state, action) => {
        state.dataMyBagById = action.payload.data;
      })
      .addCase(fetchAllManufacturer.fulfilled, (state, action) => {
        state.dataManufacturer = action.payload.data;
      })
      .addCase(fetchAllClubCategoriesParents.fulfilled, (state, action) => {
        state.dataClubCategoriesParent = action.payload.data;
      })
      .addCase(getUrlClubImage.pending, (state, action) => {
        state.urlImage = '';
      })
      .addCase(deleteBag.fulfilled, (state, action) => {
        state.dataMyBags = null;
      })
      .addCase(getUrlClubImage.fulfilled, (state, action) => {
        if (action.payload.data?.url) {
          state.urlImage = action.payload.data.url;
        } else {
          state.urlImage = '';
        }
      })
      .addCase(getAllCategoryClubs.fulfilled, (state, action) => {
        state.allCategories = action.payload.data;
      });

    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingClubOfBagById = false;
        state.loadingClubOfBags = false;
        state.loadingMyBags = false;
      }
    );
  },
});

export const {
  changeStatusOpenRemoveClub,
  changeSelectCategoryClubCreate,
  changeSelectCategoryClub,
  changeSelectClubEdit,
  changeStatusOpenEditClub,
  changeStatusOpenDeteleBag,
  changeStatusOpenAddBag,
  changeStatusOpenAddClub,
} = myBagsSlice.actions;

export const selectLoadingMyBags = (state: RootState) =>
  state.myBags.loadingMyBags;
export const selectLoadingClubOfBags = (state: RootState) =>
  state.myBags.loadingClubOfBags;
export const selectClubOfBagsData = (state: RootState) =>
  state.myBags.dataClubOfBags;
export const selectMyBagsData = (state: RootState) => state.myBags.dataMyBags;
export const selectMyBagDataById = (state: RootState) =>
  state.myBags.dataMyBagById;
export const selectClubOfBagByIdData = (state: RootState) =>
  state.myBags.dataClubOfBagById;
export const selectStatusOpenDeleteBag = (state: RootState) =>
  state.myBags.statusOpenDeleteBag;
export const selectStatusOpenAddBag = (state: RootState) =>
  state.myBags.statusOpenAddBag;
export const selectStatusOpenAddClub = (state: RootState) =>
  state.myBags.statusOpenAddClub;
export const selectManuFacturer = (state: RootState) =>
  state.myBags.dataManufacturer;
export const selectClubCatgoriesParent = (state: RootState) =>
  state.myBags.dataClubCategoriesParent;
export const selectStatusOpenEditClub = (state: RootState) =>
  state.myBags.statusOpenEditClub;
export const selectClubEdit = (state: RootState) => state.myBags.selectClubEdit;
export const selectUrlImageClub = (state: RootState) => state.myBags.urlImage;
export const selectAllCategoryClubs = (state: RootState) =>
  state.myBags.allCategories;
export const selectCategoryClub = (state: RootState) =>
  state.myBags.selectCategoryClubEdit;
export const selectCategoryClubCreate = (state: RootState) =>
  state.myBags.selectCategoryClubCreate;
export const selectStatusOpenRemoveClub = (state: RootState) =>
  state.myBags.statusOpenDeleteClub;
export const selectLoadingClubOfBagById = (state: RootState) =>
  state.myBags.loadingClubOfBagById;

export default myBagsSlice.reducer;
