import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NavigationMenuState } from '../models/shop';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const getNavigationMenu = createAsyncThunk(
  'navigation/getNavigationMenu',
  async (rootCategory: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(GET_NAVIGATION_MENU),
        variables: {
          id: rootCategory,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const navigationMenuSlice = createSlice({
  name: 'navigation',
  initialState: { detail: null, loading: false } as NavigationMenuState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNavigationMenu.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getNavigationMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload.data.category;
      });
  },
});

export const selectNavigationMenu = (state: RootState) =>
  state.navigationMenu.detail;
export const loadingNavigationMenu = (state: RootState) =>
  state.navigationMenu.loading;

export default navigationMenuSlice.reducer;
