import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  BallPoint,
  BallPointPayload,
  PlayTrackingCommentData,
  PlayTrackingData,
  TrackingPointData,
} from '../models/playTracking';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

type PlayTrackingDetail = {
  roundId: number;
};

export const fetchPlayTrackingDetail = createAsyncThunk(
  'playTracking/fetchPlayTrackingDetail',
  async (payload: PlayTrackingDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-tracking/detail?roundId=${payload.roundId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePlayTrackingPoints = createAsyncThunk(
  'playTracking/updatePlayTrackingPoints',
  async (
    payload: {
      roundId: number;
      currentHole: number;
      roundTime: string;
      trackingData: PayloadUpdatePlayTracking[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(`/api/play-tracking/points`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPlayGameComment = createAsyncThunk(
  'playTracking/fetchPlayGameComment',
  async (payload: PlayTrackingDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-golf/comments?roundId=${payload.roundId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface PayloadUpdatePlayTracking {
  holeNumber: number;
  par: number;
  targets: [] | BallPoint[];
  balls: [] | BallPoint[];
  holeLocation: BallPointPayload;
}

interface PlayTrackingState {
  loading: boolean;
  error: null | number;
  data: null | PlayTrackingData;
  commentData: null | PlayTrackingCommentData;
  ballPointNumber: number;
  targetPointNumber: number;
  ballPointDetail: null | BallPoint;
  targetPointDetail: null | BallPoint;
  trackingPoint: null | TrackingPointData;
  ballPointEdit: null | BallPoint[];
  targetPointEdit: null | BallPoint[];
  currentHole: number;
}

const initialState: PlayTrackingState = {
  loading: false,
  error: null,
  data: null,
  commentData: null,
  ballPointNumber: -1,
  targetPointNumber: -1,
  ballPointDetail: null,
  targetPointDetail: null,
  trackingPoint: null,
  ballPointEdit: null,
  targetPointEdit: null,
  currentHole: 0,
};

export const playTrackingSlice = createSlice({
  name: 'playTracking',
  initialState,
  reducers: {
    setCurrentBallPoint: (state, action) => {
      state.ballPointNumber = action.payload;
    },
    setTargetPoint: (state, action) => {
      state.targetPointNumber = action.payload;
    },
    setBallPointDetail: (state, action) => {
      state.ballPointDetail = action.payload;
    },
    setTargetPointDetail: (state, action) => {
      state.targetPointDetail = action.payload;
    },
    setTrackingPointData: (state, action) => {
      state.trackingPoint = action.payload;
    },
    setCurrentHole: (state, action) => {
      state.currentHole = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePlayTrackingPoints.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlayTrackingPoints.fulfilled, () => ({ ...initialState }));
    builder
      .addCase(fetchPlayTrackingDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPlayTrackingDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
      });
    builder.addCase(fetchPlayGameComment.fulfilled, (state, action) => {
      state.commentData = action.payload.data;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  setCurrentBallPoint,
  setTargetPoint,
  setBallPointDetail,
  setTargetPointDetail,
  setTrackingPointData,
  setCurrentHole,
} = playTrackingSlice.actions;

export const selectLoadingPlayTracking = (state: RootState) =>
  state.playTracking.loading;
export const selectDataPlayTracking = (state: RootState) =>
  state.playTracking.data;
export const selectCurrentBallPoint = (state: RootState) =>
  state.playTracking.ballPointNumber;
export const selectTargetPoint = (state: RootState) =>
  state.playTracking.targetPointNumber;
export const selectBallPointDetail = (state: RootState) =>
  state.playTracking.ballPointDetail;
export const selectTargetPointDetail = (state: RootState) =>
  state.playTracking.targetPointDetail;
export const selectPlayGameComment = (state: RootState) =>
  state.playTracking.commentData;
export const selectCurrentHoleData = (state: RootState) =>
  state.playTracking.currentHole;
export default playTrackingSlice.reducer;
