import { extendTheme } from '@chakra-ui/react';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const embedOnProd = urlParams.get('embedOnProd');

const breakpoints =
  embedOnProd === 'true'
    ? {
        mobile: '320px',
        sm: '744px',
        md: '1136px',
        lg: '1200px',
        xl: '1680px',
        xxl: '2200px',
      }
    : {
        mobile: '320px',
        sm: '768px',
        tablet: '968px',
        md: '1280px',
        lg: '1440px',
        xlg: '1680px',
        xl: '1920px',
        xxl: '2440px',
      };

const extendedTheme = extendTheme({
  breakpoints,
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  colors: {
    orange: {
      500: '#FF6C2C',
    },
  },
  components: {
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    CheckBox: {
      size: {
        lg: {
          boxSize: '20px',
        },
      },
    },
    Button: {
      variants: {
        primary: {
          bgImage:
            'linear-gradient(45deg, #FF6C2C 49%, #EE5C1D 49%, #FF9060 94%)',
          bgSize: '250% 100%',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.24)',
          color: 'white',
          rounded: 8,
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },
          _focus: { boxShadow: 'none' },
          bgPos: 'right',
          _hover: {
            bgPos: 'left',
          },
          _disabled: {
            bg: 'linear-gradient(45deg, rgba(120, 120, 120, 0.5) -14%, rgba(160, 160, 160, 0.6) 86%) !important',
            color: 'rgba(255, 255, 255, 0.5)',
          },
          textTransform: 'uppercase',
        },
        transparentBorder: {
          background: 'transparent',
          border: '1px solid #FF6C2C',
          color: '#FF6C2C',
          rounded: 8,
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },
          _focus: { boxShadow: 'none' },
          _hover: {
            bgPos: 'left',
          },
          _disabled: {
            bg: 'linear-gradient(45deg, rgba(120, 120, 120, 0.5) -14%, rgba(160, 160, 160, 0.6) 86%) !important',
            color: 'rgba(255, 255, 255, 0.5)',
          },
        },
        secondary: {
          bg: 'white',
          color: '#FF6C2C',
          rounded: 8,
          svg: {
            fill: '#FF6C2C',
            transitionDuration: '0.2s',
          },
          _focus: { boxShadow: 'none' },
          _hover: {
            bg: '#FF6C2C',
            color: 'white',
            svg: {
              fill: 'white',
            },
          },
          _disabled: {
            bg: 'linear-gradient(45deg, rgba(120, 120, 120, 0.5) -14%, rgba(160, 160, 160, 0.6) 86%) !important',
            color: 'rgba(255, 255, 255, 0.5)',
          },
        },
        secondaryBlur: {
          bg: 'white',
          color: '#FF6C2C',
          rounded: 8,
          svg: {
            fill: '#54565B',
            transitionDuration: '0.2s',
          },
          _focus: { boxShadow: 'none' },
          _hover: {
            bg: '#FF6C2C',
            color: 'white',
            svg: {
              fill: 'white',
            },
            div: {
              background: '#fff',
              color: '#FF6C2C',
            },
          },
          _disabled: {
            bg: 'linear-gradient(45deg, rgba(120, 120, 120, 0.5) -14%, rgba(160, 160, 160, 0.6) 86%) !important',
            color: 'rgba(255, 255, 255, 0.5)',
          },
        },
        tertiary: {
          background: '#55BB2C',
          bgPos: 'right',
          bgSize: '220% 100%',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.24)',
          color: 'white',
          rounded: 5,
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },
          _focus: { boxShadow: 'none' },
          _hover: {
            bgPos: 'left',
            _disabled: {
              bg: 'linear-gradient(45deg, rgba(120, 120, 120) -14%, rgba(160, 160, 160) 86%)',
            },
          },
          _disabled: {
            bg: 'linear-gradient(45deg, rgba(120, 120, 120) -14%, rgba(160, 160, 160) 86%) !important',
            color: '#fff',
            opacity: 1,
          },
          textTransform: 'uppercase',
          fontWeight: 700,
        },
        quaternary: {
          bg: 'white',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          color: '#55BB2C',
          rounded: 5,
          svg: {
            fill: '#55BB2C',
            transitionDuration: '0.2s',
          },
          _focus: { boxShadow: 'none' },
          _hover: {
            bg: '#55BB2C',
            color: 'white',
            svg: {
              fill: 'white',
            },
            _disabled: {
              color: 'lightgray',
              svg: {
                fill: 'lightgray',
              },
            },
          },
          _disabled: {
            boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
            color: 'lightgray',
            svg: {
              fill: 'lightgray',
            },
          },
        },
        primarySkyTrak: {
          bgImage:
            'linear-gradient(45deg, #FF6C2C 49%, #EE5C1D 49%, #FF9060 94%)',
          bgSize: '250% 100%',
          bgPos: 'right',
          _hover: {
            bgPos: 'left',
          },
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          color: 'white',
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          fontWeight: 500,
          _focus: { outline: 'none' },
          svg: {
            fill: 'white',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'uppercase',
        },
        secondarySkyTrak: {
          bg: '#55BB2C',
          _hover: {
            bgPos: 'left',
            outline: 'none',
          },
          bgSize: '250% 100%',
          bgPos: 'right',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          color: 'white',
          fontWeight: 700,
          _focus: { outline: 'none', boxShadow: 'none !important' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },

          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'uppercase',
        },
        tertiarySkyTrak: {
          bg: '#FFFFFF',
          _hover: {
            bg: '#FFFFFF',
          },

          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          color: '#54565B',
          fontWeight: 700,
          _focus: { boxShadow: 'none', outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: '#54565B',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'uppercase',
        },
        quaternarySkyTrak: {
          bg: 'rgba(255, 255, 255, 0.2)',
          _hover: {
            bg: 'rgba(255, 255, 255, 0.2)',
          },
          color: 'white',
          fontWeight: 700,
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          _focus: { outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'uppercase',
        },
        semiGraySkyTrak: {
          bg: 'rgba(0, 0, 0, 0.05)',
          _hover: {
            bg: 'rgba(0, 0, 0, 0.05)',
          },
          color: '#36383E',
          fontWeight: 700,
          _focus: { outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'uppercase',
        },
        transparentSkyTrak: {
          bg: 'transparent',
          _hover: {
            bg: 'transparent',
          },
          color: '#54565B',
          fontWeight: 500,
          _focus: { outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: '#54565B',
          },

          textTransform: 'uppercase',
        },
        bgSemiGreen: {
          bg: '#55BB2C',
          color: '#FFF',
          rounded: 100,
          _focus: {
            outline: 'none',
            background: '#007833',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _hover: {
            background: '#88CF6B',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _active: {
            background: '#007833',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _disabled: {
            bg: '#D9D9D9',
            color: '#8A8A8D',
            opacity: '1 !important',
          },
        },
        bgSemiGreenBorder: {
          bg: '#FFF',
          color: '#55BB2C',
          rounded: 100,
          border: '1px solid #55BB2C',
          _focus: {
            outline: 'none',
            background: '#007833',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            border: '1px solid #007833',
            color: '#FFF',
          },
          _hover: {
            background: '#88CF6B',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            border: '1px solid #88CF6B',
            color: '#FFF',
          },
          _active: {
            background: '#007833',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            border: '1px solid #007833',
            color: '#FFF',
          },
          _disabled: {
            bg: '#D9D9D9',
            color: '#8A8A8D',
            opacity: '1 !important',
            border: '1px solid #8A8A8D',
          },
        },
        bgSemiGreenOpacity: {
          bg: 'rgba(85, 187, 44, 0.1)',
          color: '#55BB2C',
          rounded: 100,
          _focus: {
            outline: 'none',
            background: '#55BB2C',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            color: '#FFF',
          },
          _hover: {
            background: '#88CF6B',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            color: '#FFF',
          },
          _active: {
            background: '#007833',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
            color: '#FFF',
          },
          _disabled: {
            bg: '#D9D9D9',
            color: '#8A8A8D',
            opacity: '1 !important',
          },
        },
        bgGray: {
          bg: '#F3F3F3',
          color: '#36383E',
          rounded: 100,
          _hover: {
            background: '#F3F3F3',
            color: '#36383E',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _focus: {
            outline: 'none',
            background: '#D9D9D9',
            color: '#36383E',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _active: {
            background: '#D9D9D9',
            color: '#36383E',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _disabled: {
            bg: 'rgba(0, 0, 0, 0.05)',
          },
        },
        bgSemiGray: {
          bg: 'rgba(255, 255, 255, 0.2)',
          color: '#FFF',
          rounded: 100,
          _hover: {
            background: 'rgba(255, 255, 255, 0.1)',
          },
          _focus: {
            outline: 'none',
            background: 'rgba(255, 255, 255, 0.3)',
            boxShadow: 'none',
          },
          _active: {
            background: 'rgba(255, 255, 255, 0.3)',
          },
          _disabled: {
            bg: 'rgba(0, 0, 0, 0.05)',
          },
        },
        bgOrange: {
          bg: '#FF6C2C',
          color: '#FFF',
          rounded: 100,
          _hover: {
            background: '#FF8956',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _focus: {
            outline: 'none',
            background: '#E76548',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _active: {
            background: '#E76548',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _disabled: {
            bg: '#D9D9D9',
          },
        },
        bgRed: {
          bg: '#FF5050',
          color: '#FFF',
          rounded: 100,
          _hover: {
            background: '#FD6868',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _focus: {
            outline: 'none',
            background: '#CE4646',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _active: {
            background: '#CE4646',
            boxShadow: '0 4px 4px rgba(0, 0, 0, 0.08)',
          },
          _disabled: {
            bg: '#D9D9D9',
          },
        },
      },
    },
    Link: {
      variants: {
        primarySkyTrak: {
          bg: 'linear-gradient(45deg, #EE5C1D 0%, #FF9060 93.52%)',
          _hover: {
            bg: 'linear-gradient(45deg, #EE5C1D 0%, #FF9060 93.52%)',
          },
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          textDecoration: 'none !important',
          color: 'white',
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          fontWeight: 500,
          _focus: { outline: 'none' },
          svg: {
            fill: 'white',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'capitalize',
        },
        secondarySkyTrak: {
          bg: '#55BB2C',
          _hover: {
            bg: '#55BB2C',
          },
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)',
          textDecoration: 'none !important',
          color: 'white',
          fontWeight: 500,
          _focus: { outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: 'white',
          },

          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'capitalize',
        },
        tertiarySkyTrak: {
          bg: '#FFFFFF',
          _hover: {
            bg: '#FFFFFF',
          },
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1) !important',
          color: '#54565B',
          textDecoration: 'none !important',
          fontWeight: 500,
          _focus: { outline: 'none' },
          borderRadius: '100px',
          transitionDuration: '0.4s',
          transitionProperty: 'all',
          svg: {
            fill: '#54565B',
          },
          _disabled: {
            bg: 'linear-gradient(45.7deg, rgba(120, 120, 120, 0.5) -14.12%, rgba(160, 160, 160, 0.6) 86.02%) !important',
            color: '#8A8A8D',
            opacity: 1,
          },
          textTransform: 'capitalize',
        },
      },
    },
  },
  initialColorMode: 'light',
  useSystemColorMode: false,
});

export default extendedTheme;
