import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const fetchTranslations = createAsyncThunk(
  'language/fetchTranslation',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('api/translation?channel=web3');
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface TranslationType {
  [key: string]:
    | Record<string, string>
    | Record<string, MessageFormatElement[]>;
}

interface LanguageState {
  locale: string;
  baseLocale: string;
  translations: TranslationType;
  loading: string;
}
const initialState: LanguageState = {
  locale: navigator.language.substr(0, 2),
  baseLocale: navigator.language,
  translations: {
    en: {},
    fr: {},
    ja: {},
    zh: {},
  },
  loading: '',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setBaseLocale: (state, action) => {
      state.baseLocale = action.payload;
    },
    setLanguageLoadingStatus: (state, action) => {
      state.loading = action.payload;
    },
    // setTranslations: (state, action) => {
    //   state.translations = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTranslations.pending, (state, action) => {
        state.loading = 'wait';
      })
      .addCase(fetchTranslations.fulfilled, (state, action) => {
        state.loading = 'success';
        state.translations = action.payload.data.translations;
      });
    // builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { setLocale, setBaseLocale, setLanguageLoadingStatus } =
  languageSlice.actions;

export const selectLanguageLoading = (state: RootState) =>
  state.language.loading;
export const selectBaseLocale = (state: RootState) => state.language.baseLocale;
export const selectLocale = (state: RootState) => state.language.locale;

export default languageSlice.reducer;
