import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchTrackingData = createAsyncThunk(
  'tracking/gameTracking',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/game-tracking');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAddTracking = createAsyncThunk(
  'tracking/addTrackingScore',
  async (
    payload: Omit<PayloadTrackingData, 'trackingID'>,
    { rejectWithValue }
  ) => {
    try {
      const params = {
        score: payload.score,
        roundHole: payload.roundHole,
        ntg: payload.ntg,
        scrambling: payload.scrambling,
        badThreePutts: payload.badThreePutts,
        tts: payload.tts,
        otg: payload.otg,
        igolfId: payload.igolfId,
        courseName: payload.courseName ? payload.courseName : '',
        playDate: payload.playDate,
        mode: payload.mode,
      };
      const { data } = await request.post('/api/game-tracking', params);

      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchDeleteTracking = createAsyncThunk(
  'tracking/deleteTrackingScore',
  async (payload: { trackingID: number }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.delete(
        `/api/game-tracking/${payload.trackingID}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchEditTracking = createAsyncThunk(
  'tracking/editTrackingScore',
  async (
    payload: Omit<PayloadTrackingData, 'mode'>,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        `/api/game-tracking/${payload.trackingID}`,
        {
          score: payload.score,
          roundHole: payload.roundHole,
          ntg: payload.ntg,
          scrambling: payload.scrambling,
          badThreePutts: payload.badThreePutts,
          tts: payload.tts,
          otg: payload.otg,
          courseName: payload.courseName,
          igolfId: payload.igolfId,
          playDate: payload.playDate,
        }
      );

      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface PayloadTrackingData {
  score: number;
  roundHole: number;
  ntg: number;
  scrambling: number;
  badThreePutts: number;
  tts: number;
  otg: number;
  playDate: string;
  courseName: string;
  igolfId: string;
  trackingID: number;
  mode: string;
}

export interface DataTracking {
  value: number;
  deviation: number;
}

export interface HistoryCourse {
  igolfId: string;
  courseName: null | string;
}

export interface Tracking {
  id: number;
  date: string;
  score: number;
  ntg: DataTracking;
  otg: DataTracking;
  scrambling: DataTracking;
  badThreePutts: DataTracking;
  tts: DataTracking;
  roundHole: number;
  goalScore: number;
  playDate: Date;
  igolfId: string;
  name: string;
  mode: string;
}

export interface TrackingData {
  historyCourses: HistoryCourse[];
  tracking: Tracking[];
  isOverDays: boolean;
}

interface TrackingState {
  loadingAddTrackingData: boolean;
  data: null | TrackingData;
  loadingTracking: boolean;
  loadingDeleteTrackingData: boolean;
  isEditing: string;
  loadingEditTrackingData: boolean;
  statusOpenModalTrackingDetail: boolean;
  statusOpenModalInfoGameTracking: boolean;
  statusOpenModalDeleteGameTracking: boolean;
  statusOpenModalAddEditGameTracking: boolean;
  statusAddEditGameTracking: 'Add' | 'Edit' | 'None';
  statusOpenModalScrambling: boolean;
  chooseGameTracking: Tracking | null;
  typeGameTracking:
    | 'onGIR'
    | 'nearGIR'
    | 'bad3Putts'
    | 'terTeeShots'
    | 'scrambling';
  deleteTrackingID: number | null;
}

const initialState: TrackingState = {
  data: null,
  loadingDeleteTrackingData: false,
  loadingEditTrackingData: false,
  loadingAddTrackingData: false,
  loadingTracking: false,
  isEditing: '',
  statusOpenModalTrackingDetail: false,
  statusOpenModalInfoGameTracking: false,
  statusOpenModalDeleteGameTracking: false,
  statusOpenModalAddEditGameTracking: false,
  statusOpenModalScrambling: false,
  chooseGameTracking: null,
  typeGameTracking: 'onGIR',
  statusAddEditGameTracking: 'None',
  deleteTrackingID: null,
};

export const gameTrackingSlice = createSlice({
  name: 'gameTracking',
  initialState,
  reducers: {
    changeStatusOpenModalScrambling: (state, action) => {
      state.statusOpenModalScrambling = action.payload;
    },
    changeChooseGameTracking: (state, action) => {
      state.chooseGameTracking = action.payload;
    },
    changeChooseTypeGameTracking: (state, action) => {
      state.typeGameTracking = action.payload;
    },
    changeStatusOpenAddEditTracking: (state, action) => {
      state.statusOpenModalAddEditGameTracking = action.payload;
    },
    changeStatusOpenDeleteTracking: (state, action) => {
      state.statusOpenModalDeleteGameTracking = action.payload;
    },
    changeStatusOpenInfoTracking: (state, action) => {
      state.statusOpenModalInfoGameTracking = action.payload;
    },
    changeStatusAddEditTracking: (state, action) => {
      state.statusAddEditGameTracking = action.payload;
    },
    changeDeleteTrackingId: (state, action) => {
      state.deleteTrackingID = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddTracking.pending, (state, action) => {
        state.loadingAddTrackingData = true;
      })
      .addCase(fetchAddTracking.fulfilled, (state, action) => {
        state.loadingAddTrackingData = false;
      })
      .addCase(fetchTrackingData.pending, (state, action) => {
        state.loadingTracking = true;
      })
      .addCase(fetchTrackingData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loadingTracking = false;
      })
      .addCase(fetchDeleteTracking.pending, (state, action) => {
        state.loadingDeleteTrackingData = true;
      })
      .addCase(fetchDeleteTracking.fulfilled, (state, action) => {
        state.loadingDeleteTrackingData = false;
      })
      .addCase(fetchEditTracking.pending, (state, action) => {
        state.loadingEditTrackingData = true;
        state.isEditing = 'wait';
      })
      .addCase(fetchEditTracking.fulfilled, (state, action) => {
        state.loadingEditTrackingData = false;
        state.isEditing = 'success';
      });
    builder
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state) => {
          state.loadingAddTrackingData = false;
          state.loadingDeleteTrackingData = false;
          state.loadingEditTrackingData = false;
          state.loadingTracking = false;
        }
      );
  },
});

export const {
  changeChooseGameTracking,
  changeChooseTypeGameTracking,
  changeStatusOpenAddEditTracking,
  changeStatusOpenDeleteTracking,
  changeStatusOpenInfoTracking,
  changeStatusOpenModalScrambling,
  changeStatusAddEditTracking,
  changeDeleteTrackingId,
} = gameTrackingSlice.actions;

export const loadingAddTracking = (state: RootState) =>
  state.gameTracking.loadingAddTrackingData;
export const selectTrackingData = (state: RootState) =>
  state.gameTracking.data as TrackingData;
export const selectLoadingTrackingData = (state: RootState) =>
  state.gameTracking.loadingTracking;
export const selectLoadingDeleteTracking = (state: RootState) =>
  state.gameTracking.loadingDeleteTrackingData;
export const loadingEditTracking = (state: RootState) =>
  state.gameTracking.loadingEditTrackingData;
export const loadingIsEditTracking = (state: RootState) =>
  state.gameTracking.isEditing;
export const selectChooseGameTracking = (state: RootState) =>
  state.gameTracking.chooseGameTracking;
export const selectTypeGameTracking = (state: RootState) =>
  state.gameTracking.typeGameTracking;
export const selectStatusOpenModalScrambling = (state: RootState) =>
  state.gameTracking.statusOpenModalScrambling;
export const selectStatusOpenAddEditTracking = (state: RootState) =>
  state.gameTracking.statusOpenModalAddEditGameTracking;
export const selectStatusOpenDeleteTracking = (state: RootState) =>
  state.gameTracking.statusOpenModalDeleteGameTracking;
export const selectStatusOpenInfoTracking = (state: RootState) =>
  state.gameTracking.statusOpenModalInfoGameTracking;
export const selectStatusAddEditGameTracking = (state: RootState) =>
  state.gameTracking.statusAddEditGameTracking;
export const selectDeleteTrackingId = (state: RootState) =>
  state.gameTracking.deleteTrackingID;

export default gameTrackingSlice.reducer;
