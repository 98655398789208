import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const requestForgotPassword = createAsyncThunk(
  'register/requestForgotPassword',
  async (
    params: { username: string; identify?: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/api/v3/player/reset-password`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestSkytrakForgotUsername = createAsyncThunk(
  'register/requestSkytrakForgotUsername',
  async (params: { username: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/skytrak/forgot-username?email=${params.username}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sendCodeForgotPassword = createAsyncThunk(
  'register/sendCodeForgotPassword',
  async (params: { method: number; token: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/v3/player/reset-password/send-code`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const confirmCodeForgotPassword = createAsyncThunk(
  'register/confirmCodeForgotPassword',
  async (params: { code: string; token: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/v3/player/reset-password/confirm-code`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changeNewPassword = createAsyncThunk(
  'register/changeNewPassword',
  async (params: { password: string; token: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.put(
        `/api/v3/player/reset-password`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changePasswordDefault = createAsyncThunk(
  'register/changePasswordDefault',
  async (params: { password: string; token: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.put(
        `/api/v2/player/reset-password`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type resetPasswordState = {
  email: string | '';
  method: number | 2;
  phoneNumber: string | '';
  token: string | null;
};

const initialState: resetPasswordState = {
  email: '',
  method: 2,
  phoneNumber: '',
  token: null,
};

export const resetPasswordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    changeMethodSendCode: (state, action) => {
      state.method = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestForgotPassword.fulfilled, (state, action) => {
      state.email = action.payload.data.email;
      state.token = action.payload.data.token;
      state.phoneNumber = action.payload.data.phoneNumber;
    });
  },
});

export const { changeMethodSendCode } = resetPasswordSlice.actions;

export const selectTokenChangePassword = (state: RootState) =>
  state.password.token;
export const selectEmailChangePassword = (state: RootState) =>
  state.password.email;
export const selectPhoneChangePassword = (state: RootState) =>
  state.password.phoneNumber;
export const selectMethodSendCode = (state: RootState) => state.password.method;

export default resetPasswordSlice.reducer;
