import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export interface StatDetail {
  roundId: number;
  date: string;
  onGIR: number;
  normalizedOnGIR: number;
  nearGIR: number;
  normalizedNearGIR: number;
  scrambling: number;
  bad3Putts: number;
  normalizedBad3Putts: number;
  terTeeShots: number;
  normalizedTerTeeShots: number;
  score: number;
  normalizedScore: number;
  courseName: string;
  mode: number;
  address: string;
  city: string;
  country: string;
  state: string;
  holesPlayed: number;
  scramblingChange: number;
  scramblingUpAndDown: number;
}

export interface ChartData {
  onGIR: number;
  scrambling: number;
  nearGIR: number;
  score: number;
  terTeeShots: number;
  bad3Putts: number;
  date: string;
  date1: string;
  date2: string;
}

export interface SkillScoreTrailing {
  averageOnGIR: number;
  skillScoreOnGIR: number;
  averageNearGIR: number;
  skillScoreNearGIR: number;
  averageScrambling: number;
  skillScoreScrambling: number;
  averageBad3Puts: number;
  skillScoreBad3Puts: number;
  averageTerribleTeeShot: number;
  skillScoreTerribleTeeShot: number;
  averageScore: number;
}

export interface StatHistory {
  statDetailFormals: StatDetail[];
  skillScoreTrailing5: SkillScoreTrailing;
  skillScoreTrailing20: SkillScoreTrailing;
}

export interface StatHistoryState {
  dataStatHistoryState: StatHistory | null;
  loadingStatHistoryState: boolean;
  statusFilterReporting:
    | 'lastMonth'
    | 'last3Months'
    | 'lastYear'
    | 'allTime'
    | '';
  statusFilterTrailing5Round:
    | 'lastMonth'
    | 'last3Months'
    | 'lastYear'
    | 'allTime'
    | '';
}

export const fetchStatHistoryReport = createAsyncThunk(
  'statHistory/fetchStatHistoryReport',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/play-golf/stat-history');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: StatHistoryState = {
  dataStatHistoryState: null,
  loadingStatHistoryState: false,
  statusFilterReporting: '',
  statusFilterTrailing5Round: '',
};

export const statHistorySlice = createSlice({
  name: 'statHistory',
  initialState,
  reducers: {
    changeStatusFilterReporting: (state, action) => {
      state.statusFilterReporting = action.payload;
    },
    changeStatusFilterTrailing5Round: (state, action) => {
      state.statusFilterTrailing5Round = action.payload;
    },
    changeLoadingStatHistory: (state, action) => {
      state.loadingStatHistoryState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStatHistoryReport.pending, (state, action) => {
      state.loadingStatHistoryState = true;
    });
    builder.addCase(fetchStatHistoryReport.fulfilled, (state, action) => {
      state.loadingStatHistoryState = false;
      state.dataStatHistoryState = action.payload.data;
    });

    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingStatHistoryState = false;
      }
    );
  },
});

export const {
  changeStatusFilterReporting,
  changeStatusFilterTrailing5Round,
  changeLoadingStatHistory,
} = statHistorySlice.actions;

export const selectLoadingStatHistoryReport = (state: RootState) =>
  state.statHistory.loadingStatHistoryState;
export const selectStatHistoryReport = (state: RootState) =>
  state.statHistory.dataStatHistoryState;
export const selectStatusFilterReporting = (state: RootState) =>
  state.statHistory.statusFilterReporting;
export const selectStatusFilterTrailing5Round = (state: RootState) =>
  state.statHistory.statusFilterTrailing5Round;

export default statHistorySlice.reducer;
