import { useEffect, useState } from 'react';
import { useNavigate, useRoutes, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from './hooks/store';
import { fetchUserData, selectAuthenticationStatus } from './slices/user';
import { setBaseLocale, setLocale } from './slices/language';
import loadable from '@loadable/component';
import {
  selectIsTabletApp,
  selectUrlProShop,
  changeIsTabletApp,
  changeUrlProShop,
} from './slices/config';
import { checkDomainJP } from './pages/SkyTrak/MyDevice/ModalBuyMembershipV2/FeaturePlans';

const PageWrapper = loadable(() => import('./components/PageWrapper'));
const PrivateRoute = loadable(() => import('./components/PrivateRoute'));
const PublicRoute = loadable(() => import('./components/PublicRoute'));
const PublicPage = loadable(() => import('./components/PublicPage'));
const Home = loadable(() => import('./pages/Home'));
const LeaderBoardPage = loadable(() => import('./pages/LeaderBoard'));
const MyStat = loadable(() => import('./pages/MyGameV2/MyStat'));
const NotFoundPage = loadable(() => import('./pages/NotFound'));
const SignInPage = loadable(() => import('./pages/SignIn'));
const SkillChallengePage = loadable(
  () => import('./pages/MyGameV2/SkillChallenge')
);
const WebLesson = loadable(() => import('./pages/MyGameV2/WebLesson'));
const Renewal = loadable(() => import('./pages/Renewal'));
const RenewalJapan = loadable(() => import('./pages/RenewalJapan'));
const ChildrenPrivacy = loadable(() => import('./pages/ChildrenPrivacy'));
const ForgotPassword = loadable(() => import('./pages/ForgotPassword'));
const ResetPassword = loadable(() => import('./pages/ResetPassword'));
const VerifyEmail = loadable(() => import('./pages/VerifyChangeEmail'));
const AgeAffirmation = loadable(() => import('./pages/AgeAffirmation'));
const Schedule = loadable(() => import('./pages/Schedule'));
const MyAccount = loadable(() => import('./pages/MyAccount'));
const Referral = loadable(() => import('./pages/Referral'));
const SharedVideoLesson = loadable(() => import('./pages/SharedVideoLesson'));
const ErrorBoundaryWrapper = loadable(
  () => import('./components/ErrorBoundary')
);
const RecommendedGears = loadable(
  () => import('./pages/MyGameV2/RecommendedGears')
);
const MySwings = loadable(() => import('./pages/MyGameV2/MySwings'));
const MyRound = loadable(() => import('./pages/MyGameV2/MyRound'));
const MyGameV2 = loadable(() => import('./pages/MyGameV2'));
const Profile = loadable(() => import('./pages/MyAccount/Profile'));
const Account = loadable(() => import('./pages/MyAccount/Account'));
const LanguageSettings = loadable(
  () => import('./pages/MyAccount/Profile/LanguageSettings')
);
const ListBuck = loadable(() => import('./pages/MyAccount/Account/ListBuck'));
const ListCoupon = loadable(
  () => import('./pages/MyAccount/Account/ListCoupon')
);
const PurchaseHistory = loadable(
  () => import('./pages/MyAccount/Account/PurchaseHistory')
);
const PurchasedHistoryJapan = loadable(
  () => import('./pages/MyAccount/Account/PurchasedHistoryJapan')
);
const Subscription = loadable(
  () => import('./pages/MyAccount/Account/Subscription')
);
const UserAgreement = loadable(
  () => import('./pages/MyAccount/Account/UserAgreement')
);
const Help = loadable(() => import('./pages/MyAccount/Help'));
const ChangePassword = loadable(
  () => import('./pages/MyAccount/Profile/ChangePassword')
);
const EmailSettings = loadable(
  () => import('./pages/MyAccount/Profile/EmailSettings')
);
const FamilyAccount = loadable(
  () => import('./pages/MyAccount/Account/FamilyAccount')
);

const About = loadable(() => import('./pages/MyAccount/Help/About'));
const FAQ = loadable(() => import('./pages/MyAccount/Help/FAQ'));
const Care = loadable(() => import('./pages/MyAccount/Help/Care'));
const SatisfactionSurvey = loadable(
  () => import('./pages/MyAccount/Account/SatisfactionSurvey')
);
const SkyTrakMyDevice = loadable(() => import('./pages/SkyTrak/MyDevice'));

const MyBagsV2 = loadable(() => import('./pages/MyGameV2/MyBagsV2'));
const FetchDataCH = loadable(() => import('./components/FetchDataCH'));
const Activity = loadable(() => import('./pages/MyGameV2/Activity'));
const UniversalSignUp = loadable(() => import('./pages/Universal/SignUp'));
const SkyTrakSignUp = loadable(() => import('./pages/SkyTrak/Registration'));
const TermsConditions = loadable(() => import('./pages/TermsConditions'));
const GTShop = loadable(() => import('./pages/GTShop'));
const ProfileInformation = loadable(
  () => import('./pages/MyAccount/Profile/ProfileInformation')
);
const Returns = loadable(
  () => import('./pages/SkyTrak/MyAccount/MyPurchases/Returns')
);
const RenewalMembership = loadable(
  () => import('./pages/Universal/Renewal/Membership')
);
// const ClubFitting = loadable(() => import('./pages/MyGameV2/ClubFitting'));
const ClubFittingV2 = loadable(() => import('./pages/MyGameV2/ClubFittingV2'));
const PutterFitting = loadable(() => import('./pages/MyGameV2/PutterFitting'));
const VideoLibrary = loadable(() => import('./pages/MyGameV2/VideoLibrary'));
const Handicap = loadable(() => import('./pages/MyGameV2/Handicap'));
const PublicSwingStory = loadable(
  () => import('./pages/MyGameV2/PublicSwingStory')
);
const SimulationSoftWave = loadable(
  () => import('./pages/SkyTrak/SimulationSoftWave')
);
// const PersonalInfo = loadable(
//   () => import('./pages/MyAccount/Profile/PersonalInfo')
// );

// const SkyTrakMyAccount = loadable(() => import('./pages/SkyTrak/MyAccount'));
// const SkyTrakMyGame = loadable(() => import('./pages/SkyTrak/MyGame'));
// const SkyTrakMySubscriptions = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MySubscriptionsPlans')
// );
// const SkyTrakMyProfile = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyProfile')
// );
// const SkyTrakMyPurchases = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyPurchases')
// );
// const SkyTrakMembershipPlan = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MySubscriptionsPlans/PlanMembership')
// );
// const SkyTrakSimulationPlan = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MySubscriptionsPlans/PlanSimulation')
// );
// const SkyTrakProfileInformation = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyProfile/ProfileInformation')
// );
// const SkyTrakPurchasedHistory = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyPurchases/PurchasedHistory')
// );

// const SkyTrakChangePassword = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyProfile/ChangePassword')
// );
// const SkyTrakAddressBook = loadable(
//   () => import('./pages/SkyTrak/MyAccount/MyProfile/AddressBook')
// );
// const FetchDataSkyTrak = loadable(
//   () => import('./components/FetchDataSkyTrak')
// );

// const PlayGolf = loadable(() => import('./pages/PlayGolf'));

function App() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const skytrakTicket = searchParams.get('skytrakTicket');
  const ticket = searchParams.get('ticket');
  const redirectLink = searchParams.get('redirect');
  const isAuthenticated = useTypedSelector(selectAuthenticationStatus);
  const langFromUrl = searchParams.get('lang');
  const location = window.location.hostname;
  const isTablet = searchParams.get('isTablet');
  const urlProShop = searchParams.get('urlProShop');
  const isTabletApp = useTypedSelector(selectIsTabletApp);
  const urlProShopApp = useTypedSelector(selectUrlProShop);
  const [loadLang, setLoadLang] = useState(false);

  useEffect(() => {
    if (isTablet && !isTabletApp) {
      dispatch(changeIsTabletApp(true));
    }
  }, [dispatch, isTablet, isTabletApp]);

  useEffect(() => {
    if (!urlProShopApp && urlProShop && isTabletApp) {
      dispatch(changeUrlProShop(urlProShop));
      navigate('/ecommerce');
    }
  }, [dispatch, navigate, urlProShop, urlProShopApp, isTabletApp]);

  useEffect(() => {
    if (!loadLang) {
      if (!langFromUrl) {
        if (location.match(/localhost|.com/)) {
          if (location.match(/gpro/)) {
            dispatch(setLocale('ja'));
            dispatch(setBaseLocale('ja-JP'));
          } else {
            dispatch(setLocale('en'));
            dispatch(setBaseLocale('en-US'));
          }
        } else if (location.match(/.hk/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-HK'));
        } else if (location.match(/.sg/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-SG'));
        } else if (location.match(/golftec.ca/)) {
          dispatch(setLocale('fr'));
          dispatch(setBaseLocale('fr-CA'));
        } else if (location.match(/.jp/)) {
          dispatch(setLocale('ja'));
          dispatch(setBaseLocale('ja-JP'));
        } else if (location.match(/.cn/)) {
          dispatch(setLocale('zh'));
          dispatch(setBaseLocale('zh-CN'));
        } else if (location.match(/.ae/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-AE'));
        } else if (location.match(/.za/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-ZA'));
        }
      } else {
        if (location.match(/localhost|.com/)) {
          if (location.match(/gpro/)) {
            dispatch(setLocale(langFromUrl === 'ja' ? 'ja' : 'en'));
            dispatch(setBaseLocale(langFromUrl === 'ja' ? 'ja-JP' : 'en-JP'));
          } else {
            dispatch(setLocale('en'));
            dispatch(setBaseLocale('en-US'));
          }
        } else if (location.match(/.hk/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-HK'));
        } else if (location.match(/.sg/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-SG'));
        } else if (location.match(/golftec.ca/)) {
          dispatch(setLocale(langFromUrl === 'fr' ? 'fr' : 'en'));
          dispatch(setBaseLocale(langFromUrl === 'fr' ? 'fr-CA' : 'en-CA'));
        } else if (location.match(/.jp/)) {
          dispatch(setLocale(langFromUrl === 'ja' ? 'ja' : 'en'));
          dispatch(setBaseLocale(langFromUrl === 'ja' ? 'ja-JP' : 'en-JP'));
        } else if (location.match(/.cn/)) {
          dispatch(setLocale(langFromUrl === 'zh' ? 'zh' : 'en'));
          dispatch(setBaseLocale(langFromUrl === 'zh' ? 'zh-CN' : 'en-CN'));
        } else if (location.match(/.ae/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-AE'));
        } else if (location.match(/.za/)) {
          dispatch(setLocale('en'));
          dispatch(setBaseLocale('en-ZA'));
        }
      }
      setLoadLang(true);
    }
  }, [dispatch, langFromUrl, loadLang, location]);

  useEffect(() => {
    if (redirectLink && !isTablet) {
      window.location.href = redirectLink;
    }
  }, [redirectLink, isTablet]);

  useEffect(() => {
    if (skytrakTicket) {
      sessionStorage.setItem('skytrakTicket', skytrakTicket);
    }
  }, [skytrakTicket]);

  useEffect(() => {
    if (isAuthenticated && !ticket && !skytrakTicket) {
      dispatch(fetchUserData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return useRoutes([
    {
      element: <PublicRoute />,
      children: [
        {
          path: '/login',
          element: <SignInPage />,
        },
        {
          path: '/sign-up',
          element: <UniversalSignUp />,
        },
        {
          path: '/register',
          element: <SkyTrakSignUp />,
        },
        {
          path: '/age-affirmation',
          element: <AgeAffirmation />,
        },
        {
          path: '/verify-email',
          element: <VerifyEmail />,
        },
        {
          path: '/children-privacy',
          element: <ChildrenPrivacy />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/terms-and-conditions',
          element: <TermsConditions />,
        },
      ],
    },
    {
      element: <PrivateRoute />,
      children: [
        {
          element: <FetchDataCH />,
          children: [
            {
              element: <PageWrapper />,
              children: [
                {
                  element: <ErrorBoundaryWrapper />,
                  children: [
                    {
                      path: '/',
                      children: [
                        {
                          path: '/ecommerce',
                          element: <GTShop />,
                        },
                        {
                          index: true,
                          element: <Home />,
                        },
                        {
                          element: <MyAccount />,
                          children: [
                            {
                              element: <Profile />,

                              children: [
                                {
                                  path: 'my-profile',
                                  element: <ProfileInformation />,
                                },
                                {
                                  path: 'email-sms-setting',
                                  element: <EmailSettings />,
                                },
                                {
                                  path: 'change-password',
                                  element: <ChangePassword />,
                                },
                                {
                                  path: 'language-settings',
                                  element: <LanguageSettings />,
                                },
                              ],
                            },
                            {
                              element: <Account />,
                              children: [
                                {
                                  path: 'family-account',
                                  element: <FamilyAccount />,
                                },

                                {
                                  path: window.location.hostname.match(
                                    /golfdigest.co.jp/
                                  )
                                    ? undefined
                                    : 'gimme',
                                  element: <ListBuck />,
                                },
                                {
                                  path: window.location.hostname.match(
                                    /golfdigest.co.jp/
                                  )
                                    ? 'list-coupon'
                                    : undefined,
                                  element: <ListCoupon />,
                                },
                                {
                                  path: 'purchased-history',
                                  element: window.location.hostname.match(
                                    /golfdigest.co.jp/
                                  ) ? (
                                    <PurchasedHistoryJapan />
                                  ) : (
                                    <PurchaseHistory />
                                  ),
                                  children: [
                                    {
                                      path: ':id',
                                    },
                                  ],
                                },
                                {
                                  path: 'returns',
                                  element: <Returns />,
                                  children: [
                                    {
                                      path: ':id',
                                    },
                                  ],
                                },
                                {
                                  path: 'subscriptions',
                                  element: <Subscription />,
                                },
                                {
                                  path: 'user-agreement',
                                  element: <UserAgreement />,
                                },
                                {
                                  path: 'satisfaction-survey',
                                  element: <SatisfactionSurvey />,
                                },
                              ],
                            },
                            {
                              element: <Help />,
                              children: [
                                {
                                  path: 'golftec-cares',
                                  element: <Care />,
                                },
                                {
                                  path: 'about-golftec',
                                  element: <About />,
                                },
                                {
                                  path: 'faq',
                                  element: <FAQ />,
                                },
                              ],
                            },
                          ],
                        },
                        {
                          path: !checkDomainJP()
                            ? 'simulation-software'
                            : undefined,
                          element: <SimulationSoftWave />,
                        },
                        // {
                        //   path: 'merge-profile',
                        //   element: <MergeProfile />,
                        // },
                        {
                          path: 'renewal',
                          element: window.location.hostname.match(
                            /golfdigest.co.jp/
                          ) ? (
                            <RenewalJapan />
                          ) : (
                            <Renewal />
                          ),
                          children: [
                            {
                              element: <RenewalMembership />,
                              path: 'membership',
                            },
                          ],
                        },
                        // {
                        //   path: 'lesson-plan',
                        //   element: <LessonPlan />
                        // },

                        {
                          path: 'leaderboard/:leaderboardType/:challengeType',
                          element: <LeaderBoardPage />,
                          children: [
                            {
                              path: ':mode',
                            },
                          ],
                        },
                        {
                          element: <MyGameV2 />,
                          children: [
                            {
                              element: <Activity />,
                              path: 'activity',
                              children: [
                                {
                                  path: ':id',
                                  element: <Activity />,
                                },
                                {
                                  path: 'multiple-activities',
                                  element: <Activity />,
                                },
                              ],
                            },
                            {
                              path: window.location.hostname.match(
                                /golfdigest.co.jp/
                              )
                                ? 'recommended-gears'
                                : undefined,
                              element: <RecommendedGears />,
                            },
                            {
                              path: 'club-fitting',
                              element: <ClubFittingV2 />,
                              children: [
                                {
                                  path: ':mode',
                                },
                              ],
                            },
                            {
                              path: 'putter-fitting',
                              element: <PutterFitting />,
                              children: [
                                {
                                  path: ':mode',
                                },
                              ],
                            },
                            {
                              path: 'tecput/session',
                              element: <PutterFitting />,
                            },
                            {
                              path: 'my-game',
                              element: <MyStat />,
                              children: [
                                {
                                  path: ':mode',
                                  element: <MyStat />,
                                },
                                {
                                  path: 'stat-history-trailing',
                                  element: <MyStat />,
                                },
                              ],
                            },
                            {
                              path: 'my-stats',
                              element: <MyStat />,
                              children: [
                                {
                                  path: ':mode',
                                  element: <MyStat />,
                                },
                                {
                                  path: 'stat-history-trailing',
                                  element: <MyStat />,
                                },
                              ],
                            },
                            {
                              path: 'skill-challenge',
                              element: <SkillChallengePage />,
                              children: [
                                {
                                  path: ':id',
                                  children: [
                                    {
                                      path: ':mode',
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              path: 'weblesson',
                              element: <WebLesson />,
                              children: [
                                {
                                  path: ':id',
                                },
                              ],
                            },
                            {
                              path: 'schedule',
                              element: <Schedule />,
                              children: [
                                {
                                  path: ':mode',
                                },
                                {
                                  path: 'waiting-list/:waitingListId',
                                },
                                {
                                  path: 'book-appointment',
                                  children: [
                                    {
                                      path: ':lesson',
                                      children: [
                                        {
                                          path: ':step',
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              path: 'my-rounds',
                              element: <MyRound />,
                              children: [
                                {
                                  path: 'history/:id',
                                },
                                {
                                  path: 'preview/:id',
                                },
                              ],
                            },
                            {
                              path: 'my-bag',
                              element: <MyBagsV2 />,
                            },
                            {
                              path: 'my-swings',
                              element: <MySwings />,
                              children: [
                                {
                                  path: ':mode',
                                },
                              ],
                            },
                            {
                              path: 'video-library',
                              element: <VideoLibrary />,
                              children: [
                                {
                                  path: 'category',
                                  element: <VideoLibrary />,
                                  children: [
                                    {
                                      path: ':id',
                                      element: <VideoLibrary />,
                                    },
                                  ],
                                },
                                {
                                  path: 'my-libraries',
                                  element: <VideoLibrary />,
                                },
                                {
                                  path: 'my-favorites',
                                  element: <VideoLibrary />,
                                },
                                {
                                  path: 'series',
                                  element: <VideoLibrary />,
                                  children: [
                                    {
                                      path: ':id',
                                      element: <VideoLibrary />,
                                      children: [
                                        {
                                          path: 'video/:videoid',
                                          element: <VideoLibrary />,
                                        },
                                      ],
                                    },
                                  ],
                                },
                                {
                                  path: 'search-video',
                                  element: <VideoLibrary />,
                                },
                              ],
                            },
                            {
                              path: 'handicap',
                              element: <Handicap />,
                            },
                          ],
                        },
                        {
                          path: 'my-device',
                          element: <SkyTrakMyDevice />,
                        },
                        // {
                        //   path: 'play-golf',
                        //   element: <PlayGolf />,
                        //   children: [
                        //     {
                        //       path: ':mode',
                        //     },
                        //   ],
                        // },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    { path: '*', element: <NotFoundPage /> },
    {
      element: <PublicPage />,
      children: [
        {
          path: 'swing-story',
          element: <PublicSwingStory />,
          children: [
            {
              path: ':swingStoryId',
              element: <PublicSwingStory />,
            },
          ],
        },
        { path: 'referral', element: <Referral /> },
        { path: 'share/:language/:id', element: <SharedVideoLesson /> },
      ],
    },
  ]);
}

export default App;
