import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { selectCustomerCart } from './shop';
import { signOut } from './user';

interface VariableProductCustomizable {
  quantity: number;
  sku: string;
  id: number;
  value_string: string;
}

export const addProductCustomizableToCart = createAsyncThunk(
  'addCart/productCustomizable',
  async (
    payload: VariableProductCustomizable,
    { getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data },
        } = await request.post('graphql', {
          // query: print(ADD_PRODUCT_CUSTOMIZABLE_TO_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            sku: payload.sku,
            id: payload.id,
            value_string: payload.value_string,
          },
        });
        if (data.cart) {
          return data.cart;
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface VariableProductCustomizableState {
  loading: boolean;
  isAddCart: string;
}

const initialState: VariableProductCustomizableState = {
  loading: false,
  isAddCart: '',
};

export const addProductCustomizableToCartSlice = createSlice({
  name: 'productSimple',
  initialState,
  reducers: {
    changeStatusIsAddCartCustomizableProduct: (state, action) => {
      state.isAddCart = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductCustomizableToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(addProductCustomizableToCart.fulfilled, (state, action) => {
        state.isAddCart = 'added';
        state.loading = false;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeStatusIsAddCartCustomizableProduct } =
  addProductCustomizableToCartSlice.actions;

export const selectAddProductCustomizableToCart = (state: RootState) =>
  state.productCustomizable.isAddCart;
export const loadingAddProductCustomizableToCart = (state: RootState) =>
  state.productCustomizable.loading;
export default addProductCustomizableToCartSlice.reducer;
