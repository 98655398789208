import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { SubscribeProduct } from '../models/shop';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const subscribeProductBackInStock = createAsyncThunk(
  'subscribeProduct/subscribeProductBackInStock',
  async (payload: { sku: string; proShopUrl: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(SUBSCRIBE_PRODUCT_BACK_IN_STOCK),
        variables: {
          sku: payload.sku,
          proShopUrl: payload.proShopUrl,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const unSubscribeProductBackInStock = createAsyncThunk(
  'subscribeProduct/unSubscribeProductBackInStock',
  async (payload: { sku: string }, { rejectWithValue }) => {
    try {
      await request.post('graphql', {
        // query: print(UNSUBSCRIBE_PRODUCT_BACK_IN_STOCK),
        variables: {
          sku: payload.sku,
        },
      });
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);
const initialState: SubscribeProduct = {
  loadingSubscribe: false,
  productStatus: false,
  dataSubscribe: null,
};

export const subscribeProductSlice = createSlice({
  name: 'subscribeProduct',
  initialState,
  reducers: {
    setProductStatus: (state, action) => {
      state.productStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribeProductBackInStock.pending, (state, action) => {
        state.loadingSubscribe = true;
      })
      .addCase(subscribeProductBackInStock.fulfilled, (state, action) => {
        state.loadingSubscribe = false;
        state.dataSubscribe = action.payload.data;
      });
  },
});

export const { setProductStatus } = subscribeProductSlice.actions;
export const selectLoadingSubscribe = (state: RootState) =>
  state.subscribeProduct.loadingSubscribe;
export const selectProductStatus = (state: RootState) =>
  state.subscribeProduct.productStatus;
export const selectDataSubscribeProduct = (state: RootState) =>
  state.subscribeProduct.dataSubscribe;
export default subscribeProductSlice.reducer;
