import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { setMonth, setYear, startOfMonth } from 'date-fns';
import {
  MyRoundState,
  payloadAllCourse,
  payloadWeatherInformation,
} from '../models/myRounds';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { PlaySettings, TeeBoxDetail } from './../models/myRounds';
import { ScorecardHole } from './scoreCard';
import { signOut } from './user';

export const fetchMyRound = createAsyncThunk(
  'myRound/fetchMyRound',
  async (
    payload: { lat: string; long: string; size: number; page: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/v2/play-game/rounds?lat=${payload.lat}&lon=${payload.long}&size=${payload.size}&page=${payload.page}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllCourse = createAsyncThunk(
  'myRound/fetchAllCourse',
  async (payload: payloadAllCourse, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/v2/game-tracking/course?long=${payload.long}&lat=${payload.lat}&name=${payload.name}&state=${payload.state}&country=${payload.country}&${payload.next}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCourseDetailById = createAsyncThunk(
  'myRound/getCourseDetailById',
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/v2/game-tracking/course/${payload.id}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchAllTeeBox = createAsyncThunk(
  'myRound/fetchAllTeeBox',
  async (
    payload: { courseId: string; roundId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/play-game/round/tee-box?courseId=${payload.courseId}&roundId=${payload.roundId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addTeeBox = createAsyncThunk(
  'myRound/addTeeBox',
  async (
    payload: { courseId: string; roundId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/api/play-game/round/tee-box?courseId=${payload.courseId}&roundId=${payload.roundId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTeeBox = createAsyncThunk(
  'myRound/deleteTeeBox',
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(
        `/api/play-game/round/tee-box/${payload.id}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchPlaySetting = createAsyncThunk(
  'myRound/fetchPlaySetting',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/play-golf/play-setting/latest`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchFavoriteCourse = createAsyncThunk(
  'myRound/fetchFavoriteCourse',
  async (payload: { playerId: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/game-tracking/course/favorite?playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addFavoriteCourse = createAsyncThunk(
  'myRound/addFavoriteCourse',
  async (
    payload: { courseId: string; playerId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/api/game-tracking/course/favorite?courseId=${payload.courseId}&playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deteleFavoriteCourse = createAsyncThunk(
  'myRound/deleteFavoriteCourse',
  async (
    payload: { courseId: string; playerId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.delete(
        `/api/game-tracking/course/favorite?courseId=${payload.courseId}&playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchCountryState = createAsyncThunk(
  'myRound/fetchCountryState',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/course/country-state`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchRoundStats = createAsyncThunk(
  'myRound/fetchRoundStats',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/play-game/round/stats`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchRoundAllStatsSummary = createAsyncThunk(
  'myRound/fetchRoundAllStatsSummary',
  async (payload: { playerId: string }, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-golf/stat-summary?playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchRoundStatsSummaryById = createAsyncThunk(
  'myRound/fetchRoundStatsSummaryById',
  async (
    payload: { roundId: string; playerId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/play-golf/stat-summary?roundId=${payload.roundId}&playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchMyRoundById = createAsyncThunk(
  'myRound/fetchMyRoundById',
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/play-game/round/${id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchWeatherInformation = createAsyncThunk(
  'myRound/fetchWeatherInformation',
  async (payload: payloadWeatherInformation, { rejectWithValue }) => {
    try {
      const time = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const { data } = await request.post(`/api/weather`, {
        location: {
          type: 'Polygon',
          coordinates: [payload.coordinates],
        },
        fields: [
          'temperature',
          'humidity',
          'windSpeed',
          'windDirection',
          'windGust',
          'precipitationProbability',
          'precipitationType',
        ],
        timesteps: [payload.timeSteps],
        timezone: time,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type paramsCreateRound = {
  scope: string;
  scopeType: string;
  courseId: string;
  courseName: string;
  playerId: string;
  playedAt: string;
  coachId: string;
  playMode: number;
  isCompliantMode: boolean;
  teeBoxDetail: TeeBoxDetail;
  playSettings: PlaySettings;
  scorecardHoles: ScorecardHole[];
};

export const createMyRound = createAsyncThunk(
  'myRound/createRound',
  async (payload: paramsCreateRound, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/play-game/round`, {
        scope: payload.scope,
        scopeType: payload.scopeType,
        courseId: payload.courseId,
        courseName: payload.courseName,
        playerId: payload.playerId,
        playedAt: payload.playedAt,
        coachId: payload.coachId,
        playMode: payload.playMode,
        isCompliantMode: payload.isCompliantMode,
        teeBoxDetail: payload.teeBoxDetail,
        playSettings: payload.playSettings,
        scorecardHoles: payload.scorecardHoles,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: MyRoundState = {
  dataMyRound: null,
  loadingRound: false,
  dataMyRoundById: null,
  loadingRoundById: false,
  statusOpenStatsSummary: false,
  statusOpenCreateRound: false,
  dataCountryState: null,
  dataFavoriteCourse: null,
  dataCourse: null,
  fullDataCourse: null,
  loadingDataCourse: false,
  dataPlaySettings: null,
  dataRoundStats: null,
  startDate: Date.now(),
  statusOpenCalendar: false,
  selectPlayedAtRound: null,
  selectTeeBox: null,
  dataAllStatSummary: null,
  dataStatSummaryById: null,
  myRoundDetail: [],
  myRoundDetailId: '',
  dataCourseDetail: [],
  courseDetailId: '',
  countryCode: '',
  stateCode: null,
  dataWeather: null,
  loadingWeather: false,
};

export const myRoundSlice = createSlice({
  name: 'myRound',
  initialState,
  reducers: {
    changeSelectCountryCodeCourse: (state, action) => {
      state.countryCode = action.payload;
    },
    changeSelectStateCodeCourse: (state, action) => {
      state.stateCode = action.payload;
    },
    changeSelectMyCourseId: (state, action) => {
      state.courseDetailId = action.payload;
    },
    changeSelectMyRoundId: (state, action) => {
      state.myRoundDetailId = action.payload;
    },
    changeStatusOpenStatsSummary: (state, action) => {
      state.statusOpenStatsSummary = action.payload;
    },
    changeStatusOpenCreateRound: (state, action) => {
      state.statusOpenCreateRound = action.payload;
    },
    changeStatusOpenCalendar: (state, action) => {
      state.statusOpenCalendar = action.payload;
    },
    onClickGoToMonthRound: (state, action) => {
      state.startDate = startOfMonth(
        setMonth(state.startDate, action.payload)
      ).getTime();
    },
    onClickGoToYearRound: (state, action) => {
      state.startDate = startOfMonth(
        setYear(state.startDate, action.payload)
      ).getTime();
    },
    changeStartDateRound: (state, action) => {
      state.startDate = action.payload;
    },
    changeSelectPlayedAtRound: (state, action) => {
      state.selectPlayedAtRound = action.payload;
    },
    changeSelectTeeBox: (state, action) => {
      state.selectTeeBox = action.payload;
    },
    changeDataMyRound: (state, action) => {
      state.dataMyRound = action.payload;
    },
    resetStateCreateRound: (state) => {
      state.selectPlayedAtRound = null;
      state.selectTeeBox = null;
      state.dataCourse = null;
      state.fullDataCourse = null;
      state.countryCode = '';
      state.stateCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyRound.pending, (state, action) => {
        state.loadingRound = true;
      })
      .addCase(fetchMyRound.fulfilled, (state, action) => {
        state.loadingRound = false;
        state.dataMyRound = action.payload.data;
      })
      .addCase(fetchMyRoundById.pending, (state, action) => {
        state.loadingRoundById = true;
      })
      .addCase(fetchMyRoundById.fulfilled, (state, action) => {
        state.loadingRoundById = false;
        const idx = state.myRoundDetail.findIndex(
          (i) => i.id === action.payload.data.id
        );
        if (idx >= 0) {
          state.myRoundDetail[idx] = action.payload.data;
        } else {
          state.myRoundDetail.push(action.payload.data);
        }
      })
      .addCase(fetchCountryState.fulfilled, (state, action) => {
        state.dataCountryState = action.payload.data;
      })
      .addCase(fetchFavoriteCourse.fulfilled, (state, action) => {
        state.dataFavoriteCourse = action.payload.data;
      })
      .addCase(fetchAllCourse.pending, (state, action) => {
        state.loadingDataCourse = true;
      })
      .addCase(fetchAllCourse.fulfilled, (state, action) => {
        state.loadingDataCourse = false;
        state.dataCourse = action.payload.data;
        state.fullDataCourse = action.payload;
      })
      .addCase(getCourseDetailById.fulfilled, (state, action) => {
        const idx = state.dataCourseDetail.findIndex(
          (i) => i.id === action.payload.data.id
        );

        if (idx >= 0) {
          state.dataCourseDetail[idx] = action.payload.data;
        } else {
          state.dataCourseDetail.push(action.payload.data);
        }
      })
      .addCase(fetchRoundStats.fulfilled, (state, action) => {
        state.dataRoundStats = action.payload.data;
      })
      .addCase(fetchRoundAllStatsSummary.fulfilled, (state, action) => {
        state.dataAllStatSummary = action.payload.data;
      })
      .addCase(fetchRoundStatsSummaryById.fulfilled, (state, action) => {
        state.dataStatSummaryById = action.payload.data;
      })
      .addCase(fetchPlaySetting.fulfilled, (state, action) => {
        if (action.payload.data && action.payload.data.settingData) {
          state.dataPlaySettings = action.payload.data.settingData;
        }
      })
      .addCase(fetchWeatherInformation.pending, (state, action) => {
        state.loadingWeather = true;
      })
      .addCase(fetchWeatherInformation.fulfilled, (state, action) => {
        state.loadingWeather = false;
        state.dataWeather = action.payload.data;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingWeather = false;
        state.loadingDataCourse = false;
        state.loadingRound = false;
        state.loadingRoundById = false;
      }
    );
  },
});

export const {
  changeSelectCountryCodeCourse,
  changeSelectStateCodeCourse,
  changeSelectMyCourseId,
  changeSelectMyRoundId,
  resetStateCreateRound,
  changeSelectTeeBox,
  changeSelectPlayedAtRound,
  changeStatusOpenCalendar,
  changeStartDateRound,
  onClickGoToMonthRound,
  onClickGoToYearRound,
  changeStatusOpenStatsSummary,
  changeStatusOpenCreateRound,
  changeDataMyRound,
} = myRoundSlice.actions;

export const selectMyRoundData = (state: RootState) =>
  state.myRound.dataMyRound;
export const selectLoadingMyRound = (state: RootState) =>
  state.myRound.loadingRound;
export const selectMyRoundDataById = (state: RootState) =>
  state.myRound.myRoundDetail.find(
    (i) =>
      state.myRound.myRoundDetailId &&
      i.id === parseInt(state.myRound.myRoundDetailId)
  );
export const selectLoadingMyRoundById = (state: RootState) =>
  state.myRound.loadingRoundById;
export const selectStatusOpenStatsSummary = (state: RootState) =>
  state.myRound.statusOpenStatsSummary;
export const selectStatusOpenCreateRound = (state: RootState) =>
  state.myRound.statusOpenCreateRound;
export const selectCountryStateRoundData = (state: RootState) =>
  state.myRound.dataCountryState;
export const selectFavoriteCourseData = (state: RootState) =>
  state.myRound.dataFavoriteCourse;
export const selectCourseData = (state: RootState) => state.myRound.dataCourse;
export const selectFullCourseData = (state: RootState) =>
  state.myRound.fullDataCourse;
export const selectLoadingCourseData = (state: RootState) =>
  state.myRound.loadingDataCourse;
export const selectPlaySettings = (state: RootState) =>
  state.myRound.dataPlaySettings;
export const selectCourseByDetailId = (state: RootState) => {
  return state.myRound.dataCourseDetail.find(
    (i) => state.myRound.courseDetailId && i.id === state.myRound.courseDetailId
  );
};
export const selectRoundStatsData = (state: RootState) =>
  state.myRound.dataRoundStats;
export const selectStartDateRound = (state: RootState) =>
  state.myRound.startDate;
export const selectStatusOpenCalendar = (state: RootState) =>
  state.myRound.statusOpenCalendar;
export const selectPlayedAtRound = (state: RootState) =>
  state.myRound.selectPlayedAtRound;
export const selectTeeBox = (state: RootState) => state.myRound.selectTeeBox;
export const selectAllStatSummary = (state: RootState) =>
  state.myRound.dataAllStatSummary;
export const selectStatSummaryById = (state: RootState) =>
  state.myRound.dataStatSummaryById;
export const selectMyRoundDetail = (state: RootState) =>
  state.myRound.myRoundDetail;
export const selectMyCourseDetail = (state: RootState) =>
  state.myRound.dataCourseDetail;
export const selectMyRoundId = (state: RootState) =>
  state.myRound.myRoundDetailId;
export const selectMyCountryCodeCourse = (state: RootState) =>
  state.myRound.countryCode;
export const selectMyStateCodeCourse = (state: RootState) =>
  state.myRound.stateCode;
export const selectWeatherInformation = (state: RootState) =>
  state.myRound.dataWeather;
export const selectLoadingWeather = (state: RootState) =>
  state.myRound.loadingWeather;

export default myRoundSlice.reducer;
