import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import _ from 'lodash';
import request from '../utils/request';
import { RootState } from '../utils/store';
import {
  ActitivyClubType,
  ActitivyType,
  ActivitySkytrak,
  MapSettings,
  ShotTrajActivity,
  ClubStat,
} from './../models/activitySkytrak';
import { signOut } from './user';

interface ActivitySkytrakState {
  statusOpenChangeClub: boolean;
  activitySkytrakData: ActivitySkytrak[];
  mapSettings: MapSettings | null;
  expectedClubStat: ClubStat[] | null;
  skytrakAppSettings: string;
  statusOpenDeleteActivity: boolean;
  activityDelete: ActivitySkytrak | null;
  chosenShotsActivity: ShotTrajActivity[];
  statusOpenDeleteShots: boolean;
  loadingActivityData: boolean;
  loadingActivityLoadMore: boolean;
  loadingDeleteShot: boolean;
  totalRecord: number | null;
  sizeRecord: number;
  pageRecord: number;
  fromDateActivity: string;
  toDateActivity: string;
  selectClubFilter: string;
  chooseType: string[];
  userClubType: ActitivyClubType[];
  userActivityType: ActitivyType[];
  loadingActivityFilter: boolean;
  chooseRowShot: ShotTrajActivity | null;
  statusOpenDetailShot: boolean;
  showFilter: boolean;
  loadingAnalyzeAll: boolean;
  dataShotTrajById: ShotTrajActivity[];
  loadingDetailShotById: boolean;
  checkSkytrakPlus: boolean;
  statusOpenCompareShot: boolean;
  dataCompare: string;
  statusOpenTargetSA: boolean;
  dataShotTrajTargetSA: ShotTrajActivity[];
  isLefty: boolean;
  dataClubDetailBagMapping: ShotTrajActivity[];
  statusOpenDetailClubBagMapping: boolean;
  practiceGreensIndexChoose: number;
}

const initialState: ActivitySkytrakState = {
  statusOpenChangeClub: false,
  activitySkytrakData: [],
  mapSettings: null,
  expectedClubStat: null,
  skytrakAppSettings: '',
  statusOpenDeleteActivity: false,
  activityDelete: null,
  chosenShotsActivity: [],
  statusOpenDeleteShots: false,
  loadingActivityData: false,
  loadingActivityLoadMore: false,
  loadingDeleteShot: false,
  totalRecord: null,
  sizeRecord: 20,
  pageRecord: 0,
  fromDateActivity: '',
  toDateActivity: '',
  selectClubFilter: '',
  chooseType: [],
  userClubType: [],
  userActivityType: [],
  loadingActivityFilter: false,
  chooseRowShot: null,
  statusOpenDetailShot: false,
  showFilter: false,
  loadingAnalyzeAll: false,
  dataShotTrajById: [],
  loadingDetailShotById: false,
  checkSkytrakPlus: false,
  statusOpenCompareShot: false,
  dataCompare: '',
  statusOpenTargetSA: false,
  dataShotTrajTargetSA: [],
  isLefty: false,
  dataClubDetailBagMapping: [],
  statusOpenDetailClubBagMapping: false,
  practiceGreensIndexChoose: 0,
};

export const fetchActivitySkytrak = createAsyncThunk(
  'activitySkyTrak/fetchActivitySkytrak',
  async (
    payload: {
      page: number;
      toDate?: string;
      fromDate?: string;
      clubType?: string;
      activityType?: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const sizeRecord = selectSizeRecordActivity(getState() as RootState);
      let url = `/skytrak/my-game/activity?page=${payload.page}&size=${sizeRecord}`;
      if (payload.activityType) {
        url += `&activityType=${payload.activityType}`;
      }
      if (payload.fromDate && payload.toDate) {
        url += `&fromDate=${payload.fromDate}&toDate=${payload.toDate}`;
      }
      if (payload.clubType) {
        url += `&clubType=${payload.clubType}`;
      }
      const { data } = await request.get(url);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchActivitySkytrakByESNId = createAsyncThunk(
  'activitySkyTrak/fetchActivitySkytrakByESNId',
  async (esnAcitivityId: string, { rejectWithValue }) => {
    try {
      let url = `/skytrak/my-game/activity/${esnAcitivityId}`;
      const { data } = await request.get(url);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getActivityTypes = createAsyncThunk(
  'activitySkyTrak/getActivityTypes',
  async (_, { getState, rejectWithValue }) => {
    try {
      const { data } = await request.get(`/skytrak/my-game/activity/type`);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateShotActivity = createAsyncThunk(
  'activitySkyTrak/updateShotActivity',
  async (
    payload: {
      arrayUpdate: {
        skyTrakShotId: number;
        clubType: string;
        changeAllClubType: boolean;
      }[];
      esnActivityId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        '/skytrak/my-game/shot',
        payload.arrayUpdate
      );
      return { data: data, esnActivityId: payload.esnActivityId };
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteShotsActivity = createAsyncThunk(
  'activitySkyTrak/deleteShotsActivity',
  async (
    payload: { shotIdArray: number[]; esnActivityId: number },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.delete(`/skytrak/my-game/activity/shot`, {
        data: payload.shotIdArray,
      });
      return { data: data, esnActivityId: payload.esnActivityId };
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const deleteActivity = createAsyncThunk(
  'activitySkyTrak/deleteActivity',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.delete(
        `/skytrak/my-game/activity?id=${id}`
      );
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteListActivity = createAsyncThunk(
  'activitySkyTrak/deleteListActivity',
  async (payload: number[], { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.delete(`/skytrak/my-game/activity`, {
        data: payload,
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const activitySkyTrak = createSlice({
  name: 'activitySkyTrak',
  initialState,
  reducers: {
    changeStatusOpenChangeClub: (state, action) => {
      state.statusOpenChangeClub = action.payload;
    },
    changeStatusOpenDeleteActivity: (state, action) => {
      state.statusOpenDeleteActivity = action.payload;
    },
    changeStatusOpenDeleteShots: (state, action) => {
      state.statusOpenDeleteShots = action.payload;
    },
    chooseActivityDelete: (state, action) => {
      state.activityDelete = action.payload;
    },
    changeChosenShotsActivity: (state, action) => {
      state.chosenShotsActivity = action.payload;
    },
    changeFromDateActivity: (state, action) => {
      state.fromDateActivity = action.payload;
    },
    changeToDateActivity: (state, action) => {
      state.toDateActivity = action.payload;
    },
    changeSelectClubActivity: (state, action) => {
      state.selectClubFilter = action.payload;
    },
    changeChooseTypeActivity: (state, action) => {
      state.chooseType = action.payload;
    },
    changeResetFilter: (state) => {
      state.selectClubFilter = '';
      state.fromDateActivity = '';
      state.toDateActivity = '';
    },
    changeLoadingFilterActivity: (state) => {
      state.loadingActivityFilter = true;
    },
    changeStatusOpenDetailShotActivity: (state, action) => {
      state.statusOpenDetailShot = action.payload;
    },
    changeChooseDetailShotActivity: (state, action) => {
      state.chooseRowShot = action.payload;
    },
    changeShowFilterActivity: (state, action) => {
      state.showFilter = action.payload;
    },
    changeLoadingAnalyze: (state, action) => {
      state.loadingAnalyzeAll = action.payload;
    },
    changeShotTrajectoryActivityById: (state, action) => {
      state.dataShotTrajById = action.payload;
    },
    changeLoadingDetailShotById: (state, action) => {
      state.loadingDetailShotById = action.payload;
    },
    changeStatusSkytrakPlus: (state, action) => {
      state.checkSkytrakPlus = action.payload;
    },
    changeStatusCompareShot: (state, action) => {
      state.statusOpenCompareShot = action.payload;
    },
    changeDataCompareShot: (state, action) => {
      state.dataCompare = action.payload;
    },
    changeStatusOpenTargetSA: (state, action) => {
      state.statusOpenTargetSA = action.payload;
    },
    changeTargetSA: (state, action) => {
      state.dataShotTrajTargetSA = action.payload;
    },
    changeIsLefty: (state, action) => {
      state.isLefty = action.payload;
    },
    changeStatusClubDetailBagMapping: (state, action) => {
      state.statusOpenDetailClubBagMapping = action.payload;
    },
    changeDataClubDetailBagMapping: (state, action) => {
      state.dataClubDetailBagMapping = action.payload;
    },
    changePracticeGreensIndexChoose: (state, action) => {
      state.practiceGreensIndexChoose = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActivityTypes.fulfilled, (state, action) => {
      state.userClubType = action.payload.data.clubs;
      state.userActivityType = action.payload.data.types;
    });
    builder.addCase(fetchActivitySkytrak.pending, (state, action) => {
      if (state.activitySkytrakData.length === 0) {
        state.loadingActivityData = true;
      }
      state.loadingActivityLoadMore = true;
    });
    builder.addCase(fetchActivitySkytrak.fulfilled, (state, action) => {
      if (action.payload.data) {
        if (!state.loadingActivityFilter) {
          state.mapSettings = action.payload.data.mapSettings;
          state.skytrakAppSettings = action.payload.data.skytrakAppSettings;

          if (action.payload.data.totalRecord > 0) {
            if (state.pageRecord < action.payload.data.pageNo) {
              const array: ActivitySkytrak[] = action.payload.data.activities;
              array.forEach((item) => state.activitySkytrakData.push(item));
            } else {
              state.activitySkytrakData = action.payload.data.activities;
            }
          }
        } else {
          state.activitySkytrakData = action.payload.data.activities;
        }
        state.expectedClubStat = action.payload.data.expectedClubStat;
        state.pageRecord = action.payload.data.pageNo;
        state.totalRecord = action.payload.data.totalRecord;
      } else {
        if (!state.totalRecord) {
          state.totalRecord = 0;
        }
      }
      state.loadingActivityData = false;
      state.loadingActivityLoadMore = false;
      state.loadingActivityFilter = false;
    });
    builder.addCase(fetchActivitySkytrakByESNId.pending, (state, action) => {
      state.loadingActivityData = true;
    });
    builder.addCase(fetchActivitySkytrakByESNId.fulfilled, (state, action) => {
      if (
        action.payload.data &&
        action.payload.data.activities &&
        action.payload.data.activities.length > 0
      ) {
        const idx = state.activitySkytrakData.findIndex(
          (i) =>
            i.esnActivityId === action.payload.data.activities[0].esnActivityId
        );
        if (idx >= 0) {
          state.activitySkytrakData[idx] = action.payload.data.activities[0];
        } else {
          state.activitySkytrakData.push(action.payload.data.activities[0]);
        }
      }

      state.loadingActivityData = false;
    });
    builder.addCase(deleteActivity.pending, (state) => {
      state.loadingActivityData = true;
      state.loadingActivityLoadMore = true;
    });
    builder.addCase(deleteActivity.fulfilled, (state, action) => {
      if (action.payload.data.length > 0) {
        state.activitySkytrakData = state.activitySkytrakData.filter(
          (i) => i.esnActivityId !== action.payload.data[0]
        );
      }
      state.loadingActivityData = false;
      state.loadingActivityLoadMore = false;
    });
    builder.addCase(deleteShotsActivity.pending, (state) => {
      state.loadingActivityData = true;
    });
    builder.addCase(deleteShotsActivity.fulfilled, (state, action) => {
      const idx = state.activitySkytrakData.findIndex(
        (i) => i.esnActivityId === action.payload.esnActivityId
      );

      if (idx >= 0) {
        state.activitySkytrakData[idx] = {
          ...state.activitySkytrakData[idx],
          shots: state.activitySkytrakData[idx].shots.filter(
            (i) => !action.payload.data.data.includes(i.skyTrakShotId)
          ),
        };
      }
      state.loadingActivityData = false;
    });
    builder.addCase(updateShotActivity.pending, (state) => {
      state.loadingActivityData = true;
    });
    builder.addCase(updateShotActivity.fulfilled, (state, action) => {
      const idx = state.activitySkytrakData.findIndex(
        (i) => i.esnActivityId === action.payload.esnActivityId
      );

      if (idx >= 0) {
        state.activitySkytrakData[idx].shots.forEach((item, indexShot) => {
          const indexReplace = _.findIndex(action.payload.data.data, {
            skyTrakShotId: item.skyTrakShotId,
          });
          if (indexReplace !== -1) {
            const shotReplace = action.payload.data.data[indexReplace];
            _.set(state.activitySkytrakData[idx].shots, indexShot, shotReplace);
          }
        });
      }
      state.loadingActivityData = false;
    });
    builder
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loadingActivityData = false;
          state.loadingActivityLoadMore = false;
          state.loadingDeleteShot = false;
          state.loadingActivityFilter = false;
          state.loadingAnalyzeAll = false;
          state.loadingDetailShotById = false;
        }
      );
  },
});

export const {
  changeStatusOpenChangeClub,
  changeStatusOpenDeleteActivity,
  chooseActivityDelete,
  changeChosenShotsActivity,
  changeStatusOpenDeleteShots,
  changeFromDateActivity,
  changeToDateActivity,
  changeSelectClubActivity,
  changeChooseTypeActivity,
  changeResetFilter,
  changeLoadingFilterActivity,
  changeStatusOpenDetailShotActivity,
  changeChooseDetailShotActivity,
  changeShowFilterActivity,
  changeLoadingAnalyze,
  changeShotTrajectoryActivityById,
  changeLoadingDetailShotById,
  changeStatusSkytrakPlus,
  changeStatusCompareShot,
  changeDataCompareShot,
  changeStatusOpenTargetSA,
  changeTargetSA,
  changeIsLefty,
  changeStatusClubDetailBagMapping,
  changeDataClubDetailBagMapping,
  changePracticeGreensIndexChoose,
} = activitySkyTrak.actions;

export const selectStatusOpenChangeClub = (state: RootState) =>
  state.activitySkytrak.statusOpenChangeClub;
export const selectActivitySkytrakData = (state: RootState) =>
  state.activitySkytrak.activitySkytrakData;
export const selectStatusOpenDeleteActivity = (state: RootState) =>
  state.activitySkytrak.statusOpenDeleteActivity;
export const selectActivityDelete = (state: RootState) =>
  state.activitySkytrak.activityDelete;
export const selectChosenShotsActivity = (state: RootState) =>
  state.activitySkytrak.chosenShotsActivity;
export const selectStatusOpenDeleteShots = (state: RootState) =>
  state.activitySkytrak.statusOpenDeleteShots;
export const selectLoadingActivityData = (state: RootState) =>
  state.activitySkytrak.loadingActivityData;
export const selectLoadingActivityMoreData = (state: RootState) =>
  state.activitySkytrak.loadingActivityLoadMore;
export const selectMapSettingData = (state: RootState) =>
  state.activitySkytrak.mapSettings;
export const selectSkytrakAppSetting = (state: RootState) =>
  state.activitySkytrak.skytrakAppSettings;
export const selectTotalRecordActivity = (state: RootState) =>
  state.activitySkytrak.totalRecord;
export const selectSizeRecordActivity = (state: RootState) =>
  state.activitySkytrak.sizeRecord;
export const selectPageNoActivity = (state: RootState) =>
  state.activitySkytrak.pageRecord;
export const selectFromDateActivity = (state: RootState) =>
  state.activitySkytrak.fromDateActivity;
export const selectToDateActivity = (state: RootState) =>
  state.activitySkytrak.toDateActivity;
export const selectSelectActivityClubFilter = (state: RootState) =>
  state.activitySkytrak.selectClubFilter;
export const selectChooseTypeActivity = (state: RootState) =>
  state.activitySkytrak.chooseType;
export const selectUserActivityClubTypes = (state: RootState) =>
  state.activitySkytrak.userClubType;
export const selectUserActivityTypes = (state: RootState) =>
  state.activitySkytrak.userActivityType;
export const selectLoadingActivityFilter = (state: RootState) =>
  state.activitySkytrak.loadingActivityFilter;
export const selectStatusOpenDetailShotActivity = (state: RootState) =>
  state.activitySkytrak.statusOpenDetailShot;
export const selectChooseShotDetailActivity = (state: RootState) =>
  state.activitySkytrak.chooseRowShot;
export const selectShowFilterActivity = (state: RootState) =>
  state.activitySkytrak.showFilter;
export const selectLoadingAnaylyzeAll = (state: RootState) =>
  state.activitySkytrak.loadingAnalyzeAll;
export const selectDataShotTrajectoryActivity = (state: RootState) =>
  state.activitySkytrak.dataShotTrajById;
export const selectLoadingDetailShotById = (state: RootState) =>
  state.activitySkytrak.loadingDetailShotById;
export const selectStatusSkytrakPlus = (state: RootState) =>
  state.activitySkytrak.checkSkytrakPlus;
export const selectStatusOpenCompareShot = (state: RootState) =>
  state.activitySkytrak.statusOpenCompareShot;
export const selectDataCompareShot = (state: RootState) =>
  state.activitySkytrak.dataCompare;
export const selectStatusOpenTargetSA = (state: RootState) =>
  state.activitySkytrak.statusOpenTargetSA;
export const selectDataShotTrajTargetSA = (state: RootState) =>
  state.activitySkytrak.dataShotTrajTargetSA;
export const selectIsLefty = (state: RootState) =>
  state.activitySkytrak.isLefty;
export const selectStatusOpenClubDetailBagMapping = (state: RootState) =>
  state.activitySkytrak.statusOpenDetailClubBagMapping;
export const selectDataClubDetailBagMapping = (state: RootState) =>
  state.activitySkytrak.dataClubDetailBagMapping;
export const selectPracticeGreensIndexChoose = (state: RootState) =>
  state.activitySkytrak.practiceGreensIndexChoose;
export const selectExpectedClubStat = (state: RootState) =>
  state.activitySkytrak.expectedClubStat;

export default activitySkyTrak.reducer;
