import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../utils/store';

interface FilterAttributes {
  name: string;
  value: string;
  operator: string;
}
interface ProductFilterState {
  priceFrom: string;
  priceTo: string;
  filterAttributes: FilterAttributes[];
  isOpenFilter: boolean;
  event: string;
}

const initialState: ProductFilterState = {
  priceFrom: '',
  priceTo: '',
  filterAttributes: [],
  isOpenFilter: false,
  event: '',
};

export const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    setFilterPriceFrom: (state, action) => {
      state.priceFrom = action.payload;
    },
    setFilterPriceTo: (state, action) => {
      state.priceTo = action.payload;
    },
    setFilterAttributes: (state, action) => {
      state.filterAttributes = action.payload;
    },
    setOpenFilter: (state, action) => {
      state.isOpenFilter = action.payload;
    },
    setEventFilterType: (state, action) => {
      state.event = action.payload;
    },
    resetFilterValue: (state) => ({
      ...initialState,
    }),
  },
  extraReducers: {},
});

export const {
  setFilterPriceFrom,
  setFilterPriceTo,
  setFilterAttributes,
  resetFilterValue,
  setOpenFilter,
  setEventFilterType,
} = productFilterSlice.actions;

export const selectValueProductFilterPriceFrom = (state: RootState) =>
  state.productFilter.priceFrom;

export const selectValueProductFilterPriceTo = (state: RootState) =>
  state.productFilter.priceTo;

export const selectFilterAttributes = (state: RootState) =>
  state.productFilter.filterAttributes;

export const selectStatusOpenFilter = (state: RootState) =>
  state.productFilter.isOpenFilter;

export const selectEventFilterType = (state: RootState) =>
  state.productFilter.event;

export default productFilterSlice.reducer;
