import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NearstCenterData } from '../models/register';
import { ProductDetail, ProductInterface } from '../models/shop';
import { ExtendsProductInterface } from '../models/shopCustom';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { clearValueAddCart, setStatusAddCart } from './getValueAddToCart';
import { signOut } from './user';
import { GET_PRODUCT_DETAIL } from '../utils/queries';
import { print } from 'graphql';

interface CategoryId {
  url_key: string;
}

export const getProductDetail = createAsyncThunk(
  'product/productDetail',
  async (payload: CategoryId, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_DETAIL),
        variables: {
          urlKey: payload.url_key,
        },
      });
      // const summaryDetail = data?.data.products.items.filter((d: {url_key: string}) => d.url_key === payload.url_key);
      dispatch(clearValueAddCart());
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getProductDetailBySku = createAsyncThunk(
  'product/productDetailBySku',
  async (payload: { sku: string }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(GET_PRODUCT_DETAIL_BY_SKU),
        variables: {
          sku: payload.sku,
        },
      });
      // const summaryDetail = data?.data.products.items.filter((d: {url_key: string}) => d.url_key === payload.url_key);
      dispatch(clearValueAddCart());
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadImageGiftProduct = createAsyncThunk(
  'product/uploadImageGiftProduct',
  async (
    payload: { base64Data: string; name: string; type: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(UPLOAD_IMAGE_GIFT_PRODUCT),
        variables: {
          base64_encoded_data: payload.base64Data,
          name: payload.name,
          type: payload.type,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getTimeZones = createAsyncThunk(
  'product/getTimeZones',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(GET_TIMEZONES),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addGiftProductToCart = createAsyncThunk(
  'product/addGiftProductToCart',
  async (
    payload: {
      cartId: string;
      quantity: number;
      sku: string;
      recipient_name?: string;
      recipient_email?: string;
      message?: string;
      day_to_send?: string;
      timezone_to_send?: string;
      recipient_address?: string;
      notify_success?: number;
      send_friend?: number;
      customer_name?: string;
      giftcard_template_id?: number;
      giftcard_template_image?: string;
      amount?: number;
      giftcard_amount?: number;
      center_id: string;
      realname: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      let obj: object = {
        recipient_address: '',
      };
      if (payload.recipient_name) {
        obj = {
          ...obj,
          recipient_name: payload.recipient_name,
        };
      }
      if (payload.recipient_email) {
        obj = { ...obj, recipient_email: payload.recipient_email };
      }
      if (payload.message) {
        obj = { ...obj, message: payload.message };
      }
      if (payload.day_to_send) {
        obj = { ...obj, day_to_send: payload.day_to_send };
      }
      if (payload.timezone_to_send) {
        obj = { ...obj, timezone_to_send: payload.timezone_to_send };
      }

      if (payload.amount) {
        obj = { ...obj, amount: payload.amount };
      }
      if (payload.giftcard_amount) {
        obj = { ...obj, giftcard_amount: payload.giftcard_amount };
      }
      if (payload.amount) {
        obj = { ...obj, amount: payload.amount };
      }
      if (payload.customer_name) {
        obj = { ...obj, customer_name: payload.customer_name };
      }
      if (payload.giftcard_template_image) {
        obj = {
          ...obj,
          giftcard_template_id: payload.giftcard_template_id,
          giftcard_template_image: payload.giftcard_template_image,
        };
      }

      const {
        data: { data, errors },
      } = await request.post('graphql', {
        // query: print(ADD_GIFT_PRODUCT_TO_CART),
        variables: {
          cartId: payload.cartId,
          quantity: payload.quantity,
          sku: payload.sku,
          center_id: payload.center_id,
          realname: payload.realname,
          notify_success: payload.notify_success,
          send_friend: payload.send_friend,
          ...obj,
        },
      });
      if (
        data.addGiftCardProductsToCart !== null &&
        data.addGiftCardProductsToCart.cart
      ) {
        dispatch(setStatusAddCart('addSuccess'));
        return data;
      } else {
        dispatch(setStatusAddCart('addFailed'));
        return errors[0];
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface ProductDetailState {
  loading: boolean;
  error: null | number;
  detail: null | ProductDetail;
  openQuickView: boolean;
  urlKey: string;
  viewDetails: string;
  selectedProduct: string;
  detailRelated: ProductInterface[];
  detailUpSell: ProductInterface[];
  productDetailInformation: null | ExtendsProductInterface;
  priceProductDetail: number;
  urlImageGiftProduct: string;
  chooseImageGiftProduct: string;
  listTimeZones: { label: string; value: string }[];
  timeZoneReceives: { label: string; value: string } | null;
  centerReceives: NearstCenterData | null;
  statusAddGiftProductToCart: boolean;
}

const initialState: ProductDetailState = {
  detail: null,
  openQuickView: false,
  loading: false,
  urlKey: '',
  viewDetails: '',
  selectedProduct: '',
  error: null,
  detailRelated: [],
  detailUpSell: [],
  productDetailInformation: null,
  priceProductDetail: 0,
  urlImageGiftProduct: '',
  chooseImageGiftProduct: '',
  timeZoneReceives: null,
  listTimeZones: [],
  centerReceives: null,
  statusAddGiftProductToCart: false,
};

const productDetailSlice = createSlice({
  name: 'productByCategoryId',
  initialState,
  reducers: {
    changeOpenQuickView: (state, action) => {
      state.openQuickView = action.payload;
    },
    changeQuickViewUrlKey: (state, action) => {
      state.urlKey = action.payload;
    },
    resetDataProductDetail: (state, action) => {
      state.detail = action.payload;
      state.productDetailInformation = action.payload;
    },
    changeViewProductDetails: (state, action) => {
      state.viewDetails = action.payload;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    changePriceProductDetail: (state, action) => {
      state.priceProductDetail = action.payload;
    },
    changeImageGiftProduct: (state, action) => {
      state.chooseImageGiftProduct = action.payload;
    },
    changeTimeZoneReceives: (state, action) => {
      state.timeZoneReceives = action.payload;
    },
    changeCenterReceives: (state, action) => {
      state.centerReceives = action.payload;
    },
    changeStatusAddGiftProductToCart: (state, action) => {
      state.statusAddGiftProductToCart = action.payload;
    },
    resetDataGiftProduct: (state) => {
      state.priceProductDetail = 0;
      state.urlImageGiftProduct = '';
      state.chooseImageGiftProduct = '';
      state.timeZoneReceives = null;
      state.centerReceives = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductDetail.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.viewDetails = '';
        if (action.payload.data.products.items.length > 0) {
          state.detail = action.payload.data.products.items[0];
          state.productDetailInformation =
            action.payload.data.products.items[0];
          if (
            action.payload.data.products.items[0].upsell_products.length > 0
          ) {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          } else {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          }
          if (
            action.payload.data.products.items[0].related_products.length > 0
          ) {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          } else {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          }
        }
      })
      .addCase(getProductDetailBySku.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getProductDetailBySku.fulfilled, (state, action) => {
        state.loading = false;
        state.viewDetails = '';
        if (action.payload.data.products.items.length > 0) {
          state.detail = action.payload.data.products.items[0];
          state.productDetailInformation =
            action.payload.data.products.items[0];
          if (
            action.payload.data.products.items[0].upsell_products.length > 0
          ) {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          } else {
            state.detailUpSell =
              action.payload.data.products.items[0].upsell_products;
          }
          if (
            action.payload.data.products.items[0].related_products.length > 0
          ) {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          } else {
            state.detailRelated =
              action.payload.data.products.items[0].related_products;
          }
        }
      })
      .addCase(uploadImageGiftProduct.fulfilled, (state, action) => {
        if (action.payload.data?.uploadGiftCardTemplateImage?.url) {
          state.chooseImageGiftProduct =
            action.payload.data.uploadGiftCardTemplateImage.url;
          state.urlImageGiftProduct =
            action.payload.data.uploadGiftCardTemplateImage.url;
        }
      })
      .addCase(getTimeZones.fulfilled, (state, action) => {
        if (
          action.payload.data?.getTimezone &&
          action.payload.data?.getTimezone.length > 0
        ) {
          state.timeZoneReceives = action.payload.data.getTimezone[0];
          state.listTimeZones = action.payload.data.getTimezone;
        }
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeOpenQuickView,
  changeQuickViewUrlKey,
  resetDataProductDetail,
  changeViewProductDetails,
  setSelectedProduct,
  changePriceProductDetail,
  changeImageGiftProduct,
  changeTimeZoneReceives,
  resetDataGiftProduct,
  changeCenterReceives,
  changeStatusAddGiftProductToCart,
} = productDetailSlice.actions;

export const selectChangeViewProductDetails = (state: RootState) =>
  state.productDetail.viewDetails;

export const selectSelectedProduct = (state: RootState) =>
  state.productDetail.selectedProduct;
export const selectQuickViewUrlKey = (state: RootState) =>
  state.productDetail.urlKey;
export const selectOpenQuickView = (state: RootState) =>
  state.productDetail.openQuickView;
export const selectProductDetail = (state: RootState) =>
  state.productDetail.detail;
export const loadingProductDetail = (state: RootState) =>
  state.productDetail.loading;
export const selectProductRelated = (state: RootState) =>
  state.productDetail.detailRelated;
export const selectProductUpSell = (state: RootState) =>
  state.productDetail.detailUpSell;
export const selectProductDetailInformation = (state: RootState) =>
  state.productDetail.productDetailInformation;
export const selectPriceProductDetail = (state: RootState) =>
  state.productDetail.priceProductDetail;
export const selectUrlImageGiftProduct = (state: RootState) =>
  state.productDetail.urlImageGiftProduct;
export const selectChooseImageGiftProduct = (state: RootState) =>
  state.productDetail.chooseImageGiftProduct;
export const selectTimeZoneReceives = (state: RootState) =>
  state.productDetail.timeZoneReceives;
export const selectListTimeZones = (state: RootState) =>
  state.productDetail.listTimeZones;
export const selectCenterReceives = (state: RootState) =>
  state.productDetail.centerReceives;
export const selectStatusAddGiftProductToCart = (state: RootState) =>
  state.productDetail.statusAddGiftProductToCart;

export default productDetailSlice.reducer;
