import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { CategoryState } from '../models/shopCustom';
import {
  GET_PRODUCT_BY_CATEGORY_ID,
  GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE,
} from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { cancelSearchProductByCategory } from './getResultsSearchProduct';
import { clearValueAddCart } from './getValueAddToCart';
import { signOut } from './user';

interface CategoryId {
  id: string[];
  pageSize: number;
  currentPage: number;
  sortEnum: string;
}
interface ListProductByCategoryId {
  id: string[];
  pageSize: number;
  currentPage: number;
  sortPrice: string | null;
  sortPosition: string | null;
  sortName: string | null;
  sortDefault: string | null;
  priceFrom?: string | null;
  priceTo?: string | null;
  filterAttributes?: { name: string; value: string; operator: string }[] | null;
}

export const getProductByCategoryId = createAsyncThunk(
  'productByCategoryId/getProductByCategoryId',
  async (payload: CategoryId, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_BY_CATEGORY_ID_RELEVANCE),
        variables: {
          id: payload.id,
          pageSize: payload.pageSize,
          currentPage: payload.currentPage,
          sortEnum: 'ASC',
        },
      });
      dispatch(clearValueAddCart());
      if (data) {
        dispatch(cancelSearchProductByCategory(''));
      }
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getListProductByCategoryId = createAsyncThunk(
  'productByCategoryId/getListProductByCategoryId',
  async (payload: ListProductByCategoryId, { dispatch, rejectWithValue }) => {
    try {
      let params;
      params = {
        id: payload.id,
        pageSize: payload.pageSize,
        currentPage: payload.currentPage,
      };
      if (payload.priceFrom) {
        params = { ...params, priceFrom: payload.priceFrom };
      }
      if (payload.priceTo) {
        params = { ...params, priceTo: payload.priceTo };
      }
      if (payload.sortPrice) {
        params = { ...params, sortPrice: payload.sortPrice };
      }
      if (payload.sortPosition) {
        params = { ...params, sortPosition: payload.sortPosition };
      }
      if (payload.sortName) {
        params = { ...params, sortName: payload.sortName };
      }
      if (payload.sortDefault) {
        params = { ...params, sortDefault: payload.sortDefault };
      }
      if (payload.filterAttributes && payload.filterAttributes.length > 0) {
        params = { ...params, filterAttributes: payload.filterAttributes };
      }
      const { data } = await request.post('graphql', {
        query: print(GET_PRODUCT_BY_CATEGORY_ID),
        variables: params,
      });
      dispatch(clearValueAddCart());
      if (data) {
        dispatch(cancelSearchProductByCategory(''));
      }
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: CategoryState = {
  detail: null,
  loading: false,
  isChecked: false,
  tabId: '',
  tab: '',
  pageSize: 10,
  chooseLocation: '',
  userLocation: [],
  dataByCategory: null,
};

const productByCategorySlice = createSlice({
  name: 'productByCategoryId',
  initialState,
  reducers: {
    checkSelectCategory: (state, action) => {
      state.isChecked = action.payload;
    },
    checkLoadingCategorySummary: (state, action) => {
      state.loading = action.payload;
    },
    selectTabCategoryId: (state, action) => {
      state.tabId = action.payload;
    },
    changeTabId: (state, action) => {
      state.tab = action.payload;
    },
    setChangeChooseLocation: (state, action) => {
      state.chooseLocation = action.payload;
    },
    checkUserLocation: (state, action) => {
      state.userLocation = action.payload;
    },
    clearDataProductByCategoryId: (state, action) => {
      state.detail = action.payload;
      state.dataByCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductByCategoryId.pending, (state) => {
        state.loading = true;
        state.isChecked = true;
      })
      .addCase(getProductByCategoryId.fulfilled, (state, action) => {
        state.loading = false;
        state.isChecked = false;
        state.tabId = '';
        state.tab = '';
        if (action.payload.data?.categories) {
          state.detail = action.payload.data.categories;
        }
        if (action.payload.data?.categories?.items) {
          state.dataByCategory = action.payload.data?.categories.items;
        }
      })
      .addCase(getListProductByCategoryId.pending, (state) => {
        state.loading = true;
        state.isChecked = true;
      })
      .addCase(getListProductByCategoryId.fulfilled, (state, action) => {
        state.loading = false;
        state.isChecked = false;
        state.tabId = '';
        state.tab = '';
        if (action.payload.data?.categories) {
          state.detail = action.payload.data.categories;
        }
        if (action.payload.data?.categories?.items) {
          state.dataByCategory = action.payload.data?.categories.items;
        }
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  checkSelectCategory,
  checkLoadingCategorySummary,
  selectTabCategoryId,
  changeTabId,
  setChangeChooseLocation,
  clearDataProductByCategoryId,
  checkUserLocation,
} = productByCategorySlice.actions;

export const selectTabId = (state: RootState) =>
  state.productByCategoryId.tabId;
export const selectIsCheckCategory = (state: RootState) =>
  state.productByCategoryId.isChecked;
export const selectProductByCategory = (state: RootState) =>
  state.productByCategoryId.detail;
export const selectDataProductByCategory = (state: RootState) =>
  state.productByCategoryId.dataByCategory;
export const loadingProductByCategory = (state: RootState) =>
  state.productByCategoryId.loading;
export const selectTab = (state: RootState) => state.productByCategoryId.tab;
export const selectPageSizeGetProduct = (state: RootState) =>
  state.productByCategoryId.pageSize;
export const selectChooseLocation = (state: RootState) =>
  state.productByCategoryId.chooseLocation;
export const selectDataUserLocation = (state: RootState) =>
  state.productByCategoryId.userLocation;

export default productByCategorySlice.reducer;
