import { ChakraProvider, localStorageManager } from '@chakra-ui/react';
import '@fontsource/roboto';
import '@fontsource/roboto-condensed';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './css/general.css';
import './css/golftec-font.css';
import { persistor, store } from './utils/store';
import extendedTheme from './utils/theme';
import LanguageProvider from './components/LanguageProvider';
import App from './App';
import reportWebVitals from './reportWebVitals';
import serviceWorker from './serviceWorker';

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker
//       .register('/service-worker.js') // Đường dẫn tới service worker file bạn đã tạo ở bước trước
//       .then((registration) => {
//         console.log('Service Worker registered:', registration);
//       })
//       .catch((error) => {
//         console.error('Error registering Service Worker:', error);
//       });
//   });
// }
const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ChakraProvider
        theme={extendedTheme}
        colorModeManager={localStorageManager}
      >
        <LanguageProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LanguageProvider>
      </ChakraProvider>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker();
reportWebVitals();
