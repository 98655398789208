import { SubscriptionHistoryState, UpdateSubscription } from './../models/shop';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { GET_SUBSCRIPTION_HISTORY } from './../utils/queries';
import {
  CHANGE_STATUS_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '../utils/mutations';
import { signOut } from './user';

export const getSubscriptionsHistory = createAsyncThunk(
  'subscriptions/getSubscriptionHistory',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('graphql', {
        params: {
          query: print(GET_SUBSCRIPTION_HISTORY),
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const changeStatusSubscription = createAsyncThunk(
  'subscriptions/changeStatusSubscription',
  async (
    params: { entity_id: number; status: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(CHANGE_STATUS_SUBSCRIPTION),
        variables: { entity_id: params.entity_id, status: params.status },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  'subscriptions/updateSubscription',
  async (input: UpdateSubscription, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(UPDATE_SUBSCRIPTION),
        variables: {
          entity_id: input.entity_id,
          billing_address: input.billing_address,
          shipping_address: input.shipping_address,
        },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }
      dispatch(getSubscriptionsHistory());
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState: SubscriptionHistoryState = {
  loadingSupbscription: false,
  subscriptionHistoryData: null,
  timeZone: '',
};

const subscriptionsHistorySlice = createSlice({
  name: 'subscriptionsHistory',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionsHistory.pending, (state, action) => {
        state.loadingSupbscription = true;
      })
      .addCase(getSubscriptionsHistory.fulfilled, (state, action) => {
        state.loadingSupbscription = false;
        if (action.payload.data) {
          state.subscriptionHistoryData = action.payload.data.subscriptions;
          state.timeZone = action.payload.data.storeConfig.timeZone;
        }
      })
      .addCase(getSubscriptionsHistory.rejected, (state, action) => {
        state.loadingSupbscription = false;
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectSubscriptionsHistory = (state: RootState) =>
  state.subscriptionHistory.subscriptionHistoryData;
export const loadingSubscriptionsHistory = (state: RootState) =>
  state.subscriptionHistory.loadingSupbscription;
export const selectTimeZoneSubscription = (state: RootState) =>
  state.subscriptionHistory.timeZone;

export default subscriptionsHistorySlice.reducer;
