import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { setStatusAddCart } from './getValueAddToCart';
import { selectCustomerCart } from './shop';
import { signOut } from './user';

interface VariableProductConfigurable {
  quantity: number;
  sku: string;
  parentSku: string;
}

export const addProductConfigurableToCart = createAsyncThunk(
  'addCart/productConfigurable',
  async (
    payload: VariableProductConfigurable,
    { getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data, errors },
        } = await request.post('graphql', {
          // query: print(ADD_PRODUCT_CONFIGURABLE_TO_CART),
          variables: {
            cartId: cart.id,
            quantity: payload.quantity,
            sku: payload.sku,
            parentSku: payload.parentSku,
          },
        });

        if (
          data.addConfigurableProductsToCart !== null &&
          data.addConfigurableProductsToCart.cart
        ) {
          return data;
        } else {
          return errors[0];
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ProductConfigurableSubscription {
  cartItem: any;
}

export const addProductConfigurableSubscriptionToCart = createAsyncThunk(
  'addCart/productConfigurableSubscription',
  async (
    payload: ProductConfigurableSubscription,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as any);
      if (cart) {
        const {
          data: { data, errors },
        } = await request.post('graphql', {
          // query: print(ADD_PRODUCT_CONFIGURABLE_SUBSCRIPTION_TO_CART),
          variables: {
            cartId: cart.id,
            cartItem: payload.cartItem,
          },
        });
        if (
          data.addConfigurableProductsToCart !== null &&
          data.addConfigurableProductsToCart.cart
        ) {
          dispatch(setStatusAddCart('addSuccess'));
          return data;
        } else {
          dispatch(setStatusAddCart('addFailed'));
          return errors[0];
        }
      }
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface VariableProductConfigurableState {
  loading: boolean;
  isAddCart: string;
  data: any;
}

const initialState: VariableProductConfigurableState = {
  loading: false,
  isAddCart: '',
  data: null,
};

export const addProductConfigurableToCartSlice = createSlice({
  name: 'productConfigurable',
  initialState,
  reducers: {
    changeStatusIsAddProductConfigurableCart: (state, action) => {
      state.isAddCart = '';
    },
    clearDataAddConfigurableToCart: (state) => {
      state.data = null;
      state.isAddCart = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addProductConfigurableToCart.pending, (state, action) => {
        state.loading = true;
        state.isAddCart = 'wait';
      })
      .addCase(addProductConfigurableToCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isAddCart = 'added';
        state.data = action.payload;
      });
    builder
      .addCase(
        addProductConfigurableSubscriptionToCart.pending,
        (state, action) => {
          state.loading = true;
          state.isAddCart = 'wait';
        }
      )
      .addCase(
        addProductConfigurableSubscriptionToCart.fulfilled,
        (state, action) => {
          state.loading = false;
          state.isAddCart = 'added';
          state.data = action.payload;
        }
      );
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  changeStatusIsAddProductConfigurableCart,
  clearDataAddConfigurableToCart,
} = addProductConfigurableToCartSlice.actions;

export const selectDataAddCartProductConfigurable = (state: RootState) =>
  state.productConfigurable.data;
export const selectAddProductConfigurableToCart = (state: RootState) =>
  state.productConfigurable.isAddCart;
export const loadingAddProductConfigurableToCart = (state: RootState) =>
  state.productConfigurable.loading;
export default addProductConfigurableToCartSlice.reducer;
