import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ChangeEmailData {
  newEmail: string;
}

export const fetchVerifyEmail = createAsyncThunk(
  'verifyEmail/fetchVerifyEmail',
  async (payload: ChangeEmailData, { getState, rejectWithValue }) => {
    try {
      const response = await request.post(`/api/change-email/verify`, {
        email: payload.newEmail,
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchVerifyPhone = createAsyncThunk(
  'verifyEmail/fetchVerifyPhone',
  async (phone: string, { getState, rejectWithValue }) => {
    try {
      const response = await request.post(`/api/player/verify-phone`, {
        phone: phone,
      });
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const requestVerifyPhone = createAsyncThunk(
  'verifyEmail/requestVerifyPhone',
  async (token: string, { getState, rejectWithValue }) => {
    try {
      const response = await request.get(`/api/player/verify-phone/${token}`);
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface VerifyEmailState {
  loading: boolean;
  error: any;
  errorPhone: number | null;
  isVerify: string;
  isVeyrifyPhone: string;
  errorCodeConfirmPhone: null | number;
  requestVerifyPhone: 'SUCCESS' | 'FAILED' | 'OFF';
}

const initialState: VerifyEmailState = {
  loading: false,
  error: null,
  errorPhone: null,
  isVerify: '',
  isVeyrifyPhone: '',
  errorCodeConfirmPhone: null,
  requestVerifyPhone: 'OFF',
};

export const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {
    resetStatusIsVerifyPhone: (state) => {
      state.isVeyrifyPhone = '';
    },
    resetStatusIsVerifyEmail: (state) => {
      state.isVerify = '';
    },
    clearError: (state, action) => {
      state.error = action.payload;
    },
    setErrorVeriryPhone: (state, action) => {
      state.errorPhone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVerifyEmail.pending, (state, action) => {
        state.loading = true;
        state.isVerify = 'wait';
      })
      .addCase(fetchVerifyEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.isVerify = 'verified';
      })
      .addCase(fetchVerifyEmail.rejected, (state, action) => {
        state.error = action.payload;
        state.isVerify = '';
      });
    builder
      .addCase(fetchVerifyPhone.pending, (state, action) => {
        state.loading = true;
        state.isVeyrifyPhone = 'wait';
      })
      .addCase(fetchVerifyPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.isVeyrifyPhone = 'verified';
      })
      .addCase(fetchVerifyPhone.rejected, (state, action) => {
        state.isVeyrifyPhone = '';
        if (action.payload) {
          state.errorPhone = (action.payload as { code: number }).code;
        }
      });
    builder
      .addCase(requestVerifyPhone.fulfilled, (state, action) => {
        state.errorCodeConfirmPhone = action.payload.code;
        state.requestVerifyPhone = 'SUCCESS';
      })
      .addCase(requestVerifyPhone.rejected, (state, action) => {
        if (action.payload) {
          state.errorCodeConfirmPhone = (
            action.payload as { code: number }
          ).code;
          state.requestVerifyPhone = 'FAILED';
        }
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  resetStatusIsVerifyPhone,
  resetStatusIsVerifyEmail,
  clearError,
  setErrorVeriryPhone,
} = verifyEmailSlice.actions;

export const selectErrorCodeConfirmPhone = (state: RootState) =>
  state.verifyEmail.errorCodeConfirmPhone;
export const loadingVerifyEmail = (state: RootState) =>
  state.verifyEmail.loading;
export const checkChangeVerifyEmail = (state: RootState) =>
  state.verifyEmail.isVerify;
export const selectErrorVerifyEmail = (state: RootState) =>
  state.verifyEmail.error;
export const selectChangeVerifyPhone = (state: RootState) =>
  state.verifyEmail.isVeyrifyPhone;
export const selectErrorVerifyPhone = (state: RootState) =>
  state.verifyEmail.errorPhone;
export const selectRequestVerifyPhone = (state: RootState) =>
  state.verifyEmail.requestVerifyPhone;

export default verifyEmailSlice.reducer;
