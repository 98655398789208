import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PlayGolfConfig } from '../models/playGolfConfig';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

type PlayTrackingDetail = {
  playerId: string;
};

export const fetchPlayGolfConfig = createAsyncThunk(
  'playGolfConfig/fetchPlayGolfConfig',
  async (payload: PlayTrackingDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-golf/configuration?playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface PlayGolfConfigState {
  loading: boolean;
  error: null | number;
  data: null | PlayGolfConfig;
}

const initialState: PlayGolfConfigState = {
  loading: false,
  error: null,
  data: null,
};

export const playGolfConfigSlice = createSlice({
  name: 'playTracking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPlayGolfConfig.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const selectPlayGolfConfigData = (state: RootState) =>
  state.playGolfConfig.data;
export default playGolfConfigSlice.reducer;
