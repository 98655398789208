import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { fetchTranslations, selectLocale } from '../../slices/language';

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'en':
      return import('../../lang/en.json');
    case 'fr':
      return import('../../lang/fr.json');
    case 'ja':
      return import('../../lang/ja.json');
    case 'zh':
      return import('../../lang/zh.json');
    default:
      return import('../../lang/en.json');
  }
}

type LanguageProviderProps = {
  children: React.ReactChild;
};

const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [messages, setMessages] = useState({});
  const dispatch = useAppDispatch();
  const [chooseLocale] = [useTypedSelector(selectLocale)];
  const [locale, apiMessages] = useTypedSelector(
    (state) => [
      chooseLocale ? chooseLocale : state.language.locale,
      chooseLocale
        ? state.language.translations[chooseLocale]
        : state.language.translations[state.language.locale],
    ],
    shallowEqual
  );

  useEffect(() => {
    (async () => {
      const localMessages = await loadLocaleData(locale);

      if (apiMessages && Object.keys(apiMessages).length) {
        setMessages({ ...localMessages, ...apiMessages });
      } else {
        setMessages(localMessages);
      }
    })();
  }, [locale, apiMessages]);

  useEffect(() => {
    if (locale) {
      dispatch(fetchTranslations());
    }
  }, [dispatch, locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LanguageProvider;
