import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { GameHoleData } from '../models/gameHole';
import { BallPoint } from '../models/playTracking';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

type PlayTrackingDetail = {
  courseId: string;
  holeNumber: number;
  backgroundColor: string;
};

type SatelliteMap = {
  courseId: string;
  hole: number;
};

export const fetchPlayGameHoleData = createAsyncThunk(
  'playGameHole/fetchPlayGameHoleData',
  async (payload: PlayTrackingDetail, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-game/hole-data?courseId=${payload.courseId}&backgroundColor=${payload.backgroundColor}&holeNumber=${payload.holeNumber}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const fetchSatelliteMap = createAsyncThunk(
  'playGameHole/fetchSatelliteMap',
  async (payload: SatelliteMap, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/play-game/satellite-image?courseId=${payload.courseId}&hole=${payload.hole}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface PlayGameHoleState {
  loading: boolean;
  error: null | number;
  location: { x: 0; y: 0 };
  teePoint: { x: 0; y: 0 };
  data: null | GameHoleData;
  satelliteMap: string;
  holeNumber: number;
  isChangedHole: string;
  isMoveCrossHair: boolean;
  isMoveTeePoint: boolean;
  isEditMode: boolean;
  isDeletePoint: boolean;
  isMoveBallPoint: string;
  isMoveTargetPoint: string;
  ballPointLocation: { x: number; y: number };
  ballPointIndex: null | number;
  newPoint: null | BallPoint;
  newTargetPoint: null | BallPoint;
  isAddNewPoint: boolean;
  clubId: number;
  clubName: string;
  clubShortName: string;
}

const initialState: PlayGameHoleState = {
  loading: false,
  error: null,
  location: { x: 0, y: 0 },
  teePoint: { x: 0, y: 0 },
  data: null,
  satelliteMap: '',
  holeNumber: 1,
  isChangedHole: '',
  isMoveCrossHair: false,
  isMoveTeePoint: false,
  isEditMode: false,
  isDeletePoint: false,
  isMoveBallPoint: '',
  isMoveTargetPoint: '',
  ballPointLocation: { x: 0, y: 0 },
  ballPointIndex: null,
  newPoint: null,
  newTargetPoint: null,
  isAddNewPoint: false,
  clubId: 0,
  clubName: '',
  clubShortName: '',
};

export const playGameHoleSlice = createSlice({
  name: 'playGameHole',
  initialState,
  reducers: {
    setCrossHairLocation: (state, action) => {
      state.location = action.payload;
    },
    setTeePointLocation: (state, action) => {
      state.teePoint = action.payload;
    },
    setHoleNumber: (state, action) => {
      state.holeNumber = action.payload;
    },
    setIsChangedHole: (state, action) => {
      state.isChangedHole = action.payload;
    },
    setIsMoveCrossHair: (state, action) => {
      state.isMoveCrossHair = action.payload;
    },
    setIsMoveTeePoint: (state, action) => {
      state.isMoveTeePoint = action.payload;
    },
    setBallPointIndex: (state, action) => {
      state.ballPointIndex = action.payload;
    },
    setIsMoveBallPoint: (state, action) => {
      state.isMoveBallPoint = action.payload;
    },
    setIsMoveTargetPoint: (state, action) => {
      state.isMoveTargetPoint = action.payload;
    },
    setBallPointDataLocation: (state, action) => {
      state.ballPointLocation = action.payload;
    },
    enableEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    enableDeletePoint: (state, action) => {
      state.isDeletePoint = action.payload;
    },
    setNewPoint: (state, action) => {
      state.newPoint = action.payload;
    },
    setNewTargetPoint: (state, action) => {
      state.newTargetPoint = action.payload;
    },
    setIsAddNewPoint: (state, action) => {
      state.isAddNewPoint = action.payload;
    },
    setClubId: (state, action) => {
      state.clubId = action.payload;
    },
    setClubName: (state, action) => {
      state.clubName = action.payload;
    },
    setClubShortName: (state, action) => {
      state.clubShortName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayGameHoleData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPlayGameHoleData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchSatelliteMap.fulfilled, (state, action) => {
        state.satelliteMap = action.payload.data;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const {
  setCrossHairLocation,
  setHoleNumber,
  setIsChangedHole,
  setIsMoveCrossHair,
  setIsMoveTeePoint,
  setTeePointLocation,
  setBallPointIndex,
  setIsMoveBallPoint,
  setBallPointDataLocation,
  enableEditMode,
  enableDeletePoint,
  setNewPoint,
  setIsAddNewPoint,
  setClubId,
  setClubName,
  setClubShortName,
  setIsMoveTargetPoint,
  setNewTargetPoint,
} = playGameHoleSlice.actions;

export const selectGameHoleData = (state: RootState) => state.playGameHole.data;
export const selectSatelliteMapImage = (state: RootState) =>
  state.playGameHole.satelliteMap;
export const selectGameHoleLoading = (state: RootState) =>
  state.playGameHole.loading;
export const selectGameHoleNumber = (state: RootState) =>
  state.playGameHole.holeNumber;
export const selectCrossHairLocation = (state: RootState) =>
  state.playGameHole.location;
export const selectIsChangedHole = (state: RootState) =>
  state.playGameHole.isChangedHole;
export const selectIsMoveCrossHair = (state: RootState) =>
  state.playGameHole.isMoveCrossHair;
export const selectBallPointIndex = (state: RootState) =>
  state.playGameHole.ballPointIndex;
export const selectIsMoveBallPoint = (state: RootState) =>
  state.playGameHole.isMoveBallPoint;
export const selectIsMoveTargetPoint = (state: RootState) =>
  state.playGameHole.isMoveTargetPoint;
export const selectBallPointLocation = (state: RootState) =>
  state.playGameHole.ballPointLocation;
export const selectTeePointLocation = (state: RootState) =>
  state.playGameHole.teePoint;
export const selectIsMoveTeePoint = (state: RootState) =>
  state.playGameHole.isMoveTeePoint;
export const selectStatusEnabledEditMode = (state: RootState) =>
  state.playGameHole.isEditMode;
export const selectStatusEnabledDeletePoint = (state: RootState) =>
  state.playGameHole.isDeletePoint;
export const selectNewPointData = (state: RootState) =>
  state.playGameHole.newPoint;
export const selectNewTargetPointData = (state: RootState) =>
  state.playGameHole.newTargetPoint;
export const selectStatusAddNewPointData = (state: RootState) =>
  state.playGameHole.isAddNewPoint;
export const selectClubId = (state: RootState) => state.playGameHole.clubId;
export const selectClubName = (state: RootState) => state.playGameHole.clubName;
export const selectClubShortName = (state: RootState) =>
  state.playGameHole.clubShortName;
export default playGameHoleSlice.reducer;
