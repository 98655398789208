import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';
import {
  PurchasedECommerceOrders,
  PurchasedMerchandiseItem,
} from '../models/purchaseHistory';

export const fetchPurchasedHistory = createAsyncThunk(
  'purchaseHistory/fetchPurchasedHistory',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/purchase');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ReceiptAddress {
  requestId: string;
  receiptAddress: string;
}

export const fetchReceiptAddress = createAsyncThunk(
  'purchaseHistory/fetchReceiptAddress',
  async (payload: ReceiptAddress, { getState, dispatch, rejectWithValue }) => {
    try {
      const { status, data } = await request.post('/api/receipt', {
        requestId: payload.requestId,
        receiptAddress: payload.receiptAddress,
      });
      if (status === 200) {
        dispatch(fetchPurchasedHistory());
      }
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface DownloadReceipt {
  requestId: string;
  receiptAddress: string;
  orderNumber: string;
  total: string;
  date: string;
  studentName: string;
}

export const fetchDownloadReceipt = createAsyncThunk(
  'purchaseHistory/fetchDownloadReceipt',
  async (payload: DownloadReceipt, { rejectWithValue }) => {
    try {
      const { data } = await request.post('/api/receipt/export', {
        requestId: payload.requestId,
        receiptAddress: payload.receiptAddress,
        orderNumber: payload.orderNumber,
        total: payload.total,
        date: payload.date,
        studentName: payload.studentName,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface PurchaseHistoryData {
  eCommerceOrders: PurchasedECommerceOrders;
  purchasedMerchandise: PurchasedMerchandiseItem[];
  purchasedTicket: [];
}

interface PurchaseHistoryState {
  loading: boolean;
  error: null | number;
  data: null | PurchaseHistoryData;
  openBillingId: null | string;
  isPurchased: string;
  isDownload: string;
  dataDownload: {
    html: string;
    link: string;
  };
  isReceipt: string;
}

const initialState: PurchaseHistoryState = {
  loading: true,
  error: null,
  data: null,
  openBillingId: null,
  isPurchased: '',
  isDownload: '',
  dataDownload: {
    html: '',
    link: '',
  },
  isReceipt: '',
};

export const purchaseHistorySlice = createSlice({
  name: 'purchaseHistory',
  initialState,
  reducers: {
    changeSelectOpenBillingAddress: (state, action) => {
      state.openBillingId = action.payload;
    },
    changeActiveTabPurchased: (state, action) => {
      state.isPurchased = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchasedHistory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPurchasedHistory.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isPurchased = '';
        state.loading = false;
      })
      .addCase(fetchReceiptAddress.pending, (state, action) => {
        state.isReceipt = 'wait';
      })
      .addCase(fetchReceiptAddress.fulfilled, (state, action) => {
        state.isReceipt = 'success';
      })
      .addCase(fetchDownloadReceipt.pending, (state, action) => {
        state.isDownload = 'wait';
      })
      .addCase(fetchDownloadReceipt.fulfilled, (state, action) => {
        state.isDownload = 'downloaded';
        state.dataDownload = action.payload.data;
      });
    builder.addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeSelectOpenBillingAddress, changeActiveTabPurchased } =
  purchaseHistorySlice.actions;

export const selectExportOrderData = (state: RootState) =>
  state.purchaseHistory.dataDownload;
export const loadingExportOrderData = (state: RootState) =>
  state.purchaseHistory.isDownload;
export const loadingReceiptAddress = (state: RootState) =>
  state.purchaseHistory.isReceipt;

export const selectActiveTabPurchased = (state: RootState) =>
  state.purchaseHistory.isPurchased;
export const selectSelectedOpenBillingAddress = (state: RootState) =>
  state.purchaseHistory.openBillingId;
export const selectPurchasedHistoryData = (state: RootState) =>
  state.purchaseHistory.data;
export const loadingPurchasedHistory = (state: RootState) =>
  state.purchaseHistory.loading;
export default purchaseHistorySlice.reducer;
