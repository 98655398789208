import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';

export const getSubCategory = createAsyncThunk(
  'subCategory/getSubCategory',
  async (rootCategory: string, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        // query: print(GET_SUB_CATEGORY),
        variables: {
          id: rootCategory,
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface SubCategoryData {
  children: [];
  id: string;
  uid: string;
}

interface SubCategoryState {
  detail: null | SubCategoryData;
  loading: boolean;
}

const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState: { detail: null, loading: false } as SubCategoryState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubCategory.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.detail = action.payload.data.category;
        state.loading = false;
      });
  },
});
export const selectSubCategoryData = (state: RootState) =>
  state.subCategory.detail;
export const selectLoadingSubCategory = (state: RootState) =>
  state.subCategory.loading;
export default subCategorySlice.reducer;
