import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  ContentSubscriptionGolftecPlus,
  SubscriptionGolfTecPlus,
} from '../models/user';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getSubscriptionGolftecPlus = createAsyncThunk(
  'golftecPlus/getSubscriptionGolftecPlus',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/subscription`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const getSubscriptionGolftecPlusTrial = createAsyncThunk(
  'golftecPlus/getSubscriptionGolftecPlusTrial',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/subscription/trial`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const activeSubscriptionGolftecPlusTrial = createAsyncThunk(
  'golftecPlus/activeSubscriptionGolftecPlusTrial',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/subscription/trial`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const getHolePlayed = createAsyncThunk(
  'golftecPlus/getHolePlayed',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/play-golf/played-hole`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

interface GolftecPlusState {
  statusOpenSelectGolftecPlus: boolean;
  optionTypeIdProduct: number;
  statusBuyGolftecPlus: 'SELECT' | 'PAYMENT' | 'SUCCESS' | 'ERROR';
  statusSubscriptionGolfecPlus:
    | ''
    | 'NONE'
    | 'TRIAL'
    | 'TRIALEXPIREDNOTGTPLUS'
    | 'GTPLUSACTIVE'
    | 'GTPLUSPAUSE'
    | 'GTPLUSEXPIRED';
  dataSubscriptionGolftecPlus: null | SubscriptionGolfTecPlus;
  dataSubscriptionGolftecPlusTrial: null | SubscriptionGolfTecPlus;
  dataHolePlayed: null | { courseId: string; holeNumber: number }[];
  dataContentGolftecPlus: null | ContentSubscriptionGolftecPlus;
  statusTryTrial: boolean;
  loadingSubscription: boolean;
}

const initialState: GolftecPlusState = {
  optionTypeIdProduct: 0,
  statusOpenSelectGolftecPlus: false,
  statusBuyGolftecPlus: 'SELECT',
  statusSubscriptionGolfecPlus: '',
  dataSubscriptionGolftecPlus: null,
  dataSubscriptionGolftecPlusTrial: null,
  dataHolePlayed: null,
  dataContentGolftecPlus: null,
  statusTryTrial: false,
  loadingSubscription: false,
};

export const golftecPlus = createSlice({
  name: 'golftecPlus',
  initialState,
  reducers: {
    changeStatusOpenSelectGolftecPlus: (state, action) => {
      state.statusOpenSelectGolftecPlus = action.payload;
    },
    changeProductTypeIdGolftecPlus: (state, action) => {
      state.optionTypeIdProduct = action.payload;
    },
    changeStatusBuyGolftecPlus: (state, action) => {
      state.statusBuyGolftecPlus = action.payload;
    },
    changeStatusSubscriptionGolftecPlus: (state, action) => {
      state.statusSubscriptionGolfecPlus = action.payload;
    },
    changeDataContentGolftecPlus: (state, action) => {
      state.dataContentGolftecPlus = action.payload;
    },
    changeStatusTryTrial: (state, action) => {
      state.statusTryTrial = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionGolftecPlus.pending, (state) => {
        state.loadingSubscription = true;
      })
      .addCase(getSubscriptionGolftecPlus.fulfilled, (state, action) => {
        state.dataSubscriptionGolftecPlus = action.payload.data;
        state.loadingSubscription = false;
      })
      .addCase(getSubscriptionGolftecPlusTrial.pending, (state) => {
        state.loadingSubscription = true;
      })
      .addCase(getSubscriptionGolftecPlusTrial.fulfilled, (state, action) => {
        state.dataSubscriptionGolftecPlusTrial = action.payload.data;
        state.loadingSubscription = false;
      });
    builder.addCase(getHolePlayed.fulfilled, (state, action) => {
      state.dataHolePlayed = action.payload.data;
    });
    builder
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state) => {
          state.loadingSubscription = false;
        }
      );
  },
});

export const {
  changeStatusOpenSelectGolftecPlus,
  changeProductTypeIdGolftecPlus,
  changeStatusBuyGolftecPlus,
  changeStatusSubscriptionGolftecPlus,
  changeDataContentGolftecPlus,
  changeStatusTryTrial,
} = golftecPlus.actions;

export const selectStatusOpenSelectGolftecPlus = (state: RootState) =>
  state.golftecPlus.statusOpenSelectGolftecPlus;
export const selectProductTypeIdGolftecPlus = (state: RootState) =>
  state.golftecPlus.optionTypeIdProduct;
export const selectStatusBuyGolftecPlus = (state: RootState) =>
  state.golftecPlus.statusBuyGolftecPlus;
export const selectStatusSubscriptionGolftecPlus = (state: RootState) =>
  state.golftecPlus.statusSubscriptionGolfecPlus;
export const selectDataSubscriptionGolftecPlus = (state: RootState) =>
  state.golftecPlus.dataSubscriptionGolftecPlus;
export const selectDataSubscriptionGolftecPlusTrial = (state: RootState) =>
  state.golftecPlus.dataSubscriptionGolftecPlusTrial;
export const selectDataHolePlayed = (state: RootState) =>
  state.golftecPlus.dataHolePlayed;
export const selectDataContentGolftecPlus = (state: RootState) =>
  state.golftecPlus.dataContentGolftecPlus;
export const selectStatusTryTrial = (state: RootState) =>
  state.golftecPlus.statusTryTrial;
export const selectLoadingSubscriptionGolftecPlus = (state: RootState) =>
  state.golftecPlus.loadingSubscription;

export default golftecPlus.reducer;
