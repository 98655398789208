const logPerformanceData = ({ name, delta, id }) => {
  console.log(`${name} metric: ${delta}`);
};

const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      ({ onCLS, onFID, onFCP, onLCP, onTTFB, onINP }) => {
        console.log('213');
        onCLS(logPerformanceData);
        onFID(logPerformanceData);
        onFCP(logPerformanceData);
        onLCP(logPerformanceData);
        onTTFB(logPerformanceData);
        onINP(logPerformanceData);
      }
    );
  }
};

export default reportWebVitals;
