import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import type { ChallengeState } from '../models/challenge';
import request from '../utils/request';
import { RootState } from './../utils/store';
import { signOut } from './user';

export const fetchAllSkillChallenges = createAsyncThunk(
  'challenge/fetchAllSkillChallenges',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/skill-challenge`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchChallengeById = createAsyncThunk(
  'challenge/fetchChallengeById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/skill-challenge/${id}`);
      return { data: data, fetchId: id };
    } catch (e) {
      return rejectWithValue(id);
    }
  }
);

const initialState: ChallengeState = {
  challenges: null,
  challengesDetail: [],
  challengeType: 'driver',
  isDisplayDriverChallenge: false,
  challengeId: null,
  station: null,
  swing: null,
  loadingSkillChallenge: false,
  loadingSkillChallengeById: false,
  codeFetchChallenges: null,
  multipleStationChallenge: [],
  challengeIdError: [],
  codeErrorFetchData: false,
};

const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    changeChallengeType: (state, action) => {
      state.challengeType = action.payload;
    },
    changeSelectedChallengeId: (state, action) => {
      state.challengeId = action.payload;
    },
    changeSelectedStation: (state, action) => {
      state.station = action.payload;
      state.swing = action.payload?.swings[0];
    },
    changeSelectedSwing: (state, action) => {
      state.swing = action.payload;
    },
    toggleIsDisplayDriverChallenge: (state, action) => {
      state.isDisplayDriverChallenge = action.payload;
    },
    stationMultipleChallenge: (state, action) => {
      state.multipleStationChallenge = action.payload;
    },
    changeChallengeIdError: (state, action) => {
      state.challengeIdError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSkillChallenges.pending, (state, action) => {
        state.loadingSkillChallenge = true;
        state.codeFetchChallenges = null;
      })
      .addCase(fetchAllSkillChallenges.fulfilled, (state, action) => {
        state.challenges = action.payload.data;
        state.loadingSkillChallenge = false;
        state.codeFetchChallenges = action.payload.code;
      })
      .addCase(fetchAllSkillChallenges.rejected, (state, action) => {
        state.loadingSkillChallenge = false;
        state.codeErrorFetchData = true;
      })
      .addCase(fetchChallengeById.pending, (state, action) => {
        state.loadingSkillChallengeById = true;
      })
      .addCase(fetchChallengeById.fulfilled, (state, action) => {
        state.loadingSkillChallengeById = false;
        state.challengeType = action.payload.data.data.type;
        if (action.payload.fetchId === action.payload.data.data.id) {
          const idx = state.challengesDetail.findIndex(
            (i) => i.id === action.payload.data.data.id
          );
          if (idx >= 0) {
            state.challengesDetail[idx] = action.payload.data.data;
          } else {
            state.challengesDetail.push(action.payload.data.data);
          }
        } else {
          state.challengeIdError = [
            ...state.challengeIdError,
            action.payload.fetchId,
          ];
        }
      })

      .addCase(fetchChallengeById.rejected, (state, action) => {
        if (typeof action.payload === 'number') {
          state.challengeIdError = [...state.challengeIdError, action.payload];
        }
      });

    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.loadingSkillChallengeById = false;
        state.loadingSkillChallenge = false;
      }
    );
  },
});

export const {
  changeChallengeType,
  changeSelectedChallengeId,
  changeSelectedStation,
  changeSelectedSwing,
  toggleIsDisplayDriverChallenge,
  stationMultipleChallenge,
  changeChallengeIdError,
} = challengeSlice.actions;

export const selectChallengeType = (state: RootState) =>
  state.challenge.challengeType;

export const selectAllChallenges = (state: RootState) =>
  state.challenge.challenges;

export const selectCurrentTypeChallenges = (state: RootState) => {
  if (state.challenge.challenges) {
    switch (state.challenge.challengeType) {
      case 'driver':
        return state.challenge.challenges.driver;
      case 'irons':
        return state.challenge.challenges.irons;
      case 'longGame':
        return state.challenge.challenges.longGame;
      case 'shortGame':
        return state.challenge.challenges.shortGame;
      case 'training':
        return state.challenge.challenges.training;
      case 'closPinC':
        return state.challenge.challenges.ClosPinC;
      case 'longDrvC':
        return state.challenge.challenges.LongDrvC;
      case 'targetC':
        return state.challenge.challenges.TargetC;
    }
  }
  return null;
};

export const selectDriverChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.driver;

export const selectIronsChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.irons;

export const selectLongGameChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.longGame;

export const selectShortGameChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.shortGame;

export const selectTrainingChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.training;

export const selectClosPinCChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.ClosPinC;

export const selectLongDrvCChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.LongDrvC;

export const selectTargetCChallenges = (state: RootState) =>
  state.challenge.challenges && state.challenge.challenges.TargetC;

export const selectSelectedChallengeId = (state: RootState) =>
  state.challenge.challengeId;

export const selectSelectedChallenge = (state: RootState) => {
  return state.challenge.challengesDetail.find(
    (i) => i.id === state.challenge.challengeId
  );
};

export const selectSelectedChallengeDetail = (state: RootState) => {
  return state.challenge.challengesDetail;
};

export const selectLoadingSkillChallengeById = (state: RootState) =>
  state.challenge.loadingSkillChallengeById;

export const selectSelectedStation = (state: RootState) =>
  state.challenge.station;

export const selectSelectedMultipleStation = (state: RootState) =>
  state.challenge.multipleStationChallenge;

export const selectSelectedSwing = (state: RootState) => state.challenge.swing;

export const selectIsDisplayDriverChallenge = (state: RootState) =>
  state.challenge.isDisplayDriverChallenge;

export const selectLoadingSkillChallenge = (state: RootState) =>
  state.challenge.loadingSkillChallenge;

export const selectCodeFetchChallenges = (state: RootState) =>
  state.challenge.codeFetchChallenges;

export const selectChallengeIdError = (state: RootState) =>
  state.challenge.challengeIdError;

export const selectCodeErrorFetchData = (state: RootState) =>
  state.challenge.codeErrorFetchData;

export default challengeSlice.reducer;
