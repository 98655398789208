import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import { ListCategoryData } from '../models/shop';
import { GET_CATEGORIES_BY_ID } from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getCategoryById = createAsyncThunk(
  'category/getCategoryById',
  async (category_ids: string[], { rejectWithValue }) => {
    try {
      const { data } = await request.get('graphql', {
        params: {
          query: print(GET_CATEGORIES_BY_ID),
          variables: {
            id: category_ids,
          },
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface GetCategoryById {
  dataCategories: null | ListCategoryData;
  loadingListCategories: boolean;
  specialUrl: string;
}

const initialState: GetCategoryById = {
  dataCategories: null,
  loadingListCategories: false,
  specialUrl: '',
};

const categorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    setSpecialUrl: (state, action) => {
      state.specialUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryById.pending, (state, action) => {
        state.loadingListCategories = true;
        state.specialUrl = '';
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        state.loadingListCategories = false;
        state.dataCategories = action.payload.data.categories;
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const { setSpecialUrl } = categorySlice.actions;

export const selectCategoryDetail = (state: RootState) =>
  state.category.dataCategories;
export const loadingCategoryDetail = (state: RootState) =>
  state.category.loadingListCategories;
export const selectSpecialUrl = (state: RootState) => state.category.specialUrl;

export default categorySlice.reducer;
