import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchScoreCardData = createAsyncThunk(
  'scoreCard/fetchScoreCardData',
  async (
    payload: { roundId: string; playerId: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/play-golf/scorecard?roundId=${payload.roundId}&playerId=${payload.playerId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateScoreCardSetting = createAsyncThunk(
  'scoreCard/updateScoreCardSetting',
  async (payload: ScorecardSetting, { rejectWithValue }) => {
    try {
      const { data } = await request.put(
        `/api/play-golf/scorecard/setting?playerId=${payload.playerId}`,
        {
          roundId: payload.roundId,
          score: payload.score,
          fourStats: payload.fourStats,
          putts: payload.putts,
          fairwayHit: payload.fairwayHit,
          fairwayBunkers: payload.fairwayBunkers,
          greensideBunkers: payload.greensideBunkers,
          drivingDistance: payload.drivingDistance,
          pitches: payload.pitches,
          chips: payload.chips,
          penalties: payload.penalties,
          missedPutts: payload.missedPutts,
          greenInReg: payload.greenInReg,
        }
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateScoreCardHole = createAsyncThunk(
  'scoreCard/updateScoreCardHole',
  async (
    payload: {
      playerId: number;
      roundId: number;
      scorecardHoles: ScorecardHole;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(
        `/api/play-golf/scorecard?playerId=${payload.playerId}`,
        {
          roundId: payload.roundId,
          scorecardHoles: [
            {
              id: payload.scorecardHoles.id,
              hole: payload.scorecardHoles.hole,
              par: payload.scorecardHoles.par,
              handicap: payload.scorecardHoles.handicap,
              score: payload.scorecardHoles.score,
              status: payload.scorecardHoles.status,
              scorecardDetails: {
                // fairwayBunkers:   payload.scorecardHoles.scorecardDetails.fairwayBunkers,
                // greensideBunkers: payload.scorecardHoles.scorecardDetails.greensideBunkers,
                // greenInReg:       payload.scorecardHoles.scorecardDetails.greenInReg,
                // chips:            payload.scorecardHoles.scorecardDetails.chips,
                // penalties:        payload.scorecardHoles.scorecardDetails.penalties,
                // pitches:          payload.scorecardHoles.scorecardDetails.pitches,
                // fairwayHit:       payload.scorecardHoles.scorecardDetails.fairwayHit,
                // missedPutts:      payload.scorecardHoles.scorecardDetails.missedPutts,
                // drivingDistance:  payload.scorecardHoles.scorecardDetails.drivingDistance,
                // drivingDistanceClub: payload.scorecardHoles.scorecardDetails.drivingDistanceClub,
                scramblingPercentNtgUpDownQuest:
                  payload.scorecardHoles.scorecardDetails
                    .scramblingPercentNtgUpDownQuest,
                scramblingPercentNtgChangesQuest:
                  payload.scorecardHoles.scorecardDetails
                    .scramblingPercentNtgChangesQuest,
                badThreePuttsQuest:
                  payload.scorecardHoles.scorecardDetails.badThreePuttsQuest,
                nearTheGreenQuest:
                  payload.scorecardHoles.scorecardDetails.nearTheGreenQuest,
                onGreenInRegulationQuest:
                  payload.scorecardHoles.scorecardDetails
                    .onGreenInRegulationQuest,
                terribleTeeShotsQuest:
                  payload.scorecardHoles.scorecardDetails.terribleTeeShotsQuest,
                putts: payload.scorecardHoles.scorecardDetails.putts,
              },
            },
          ],
        }
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export interface RoundSetting {
  id: number;
  scope: string;
  courseId: string;
  courseName: string;
  teeBox: string;
  playerId: string;
  createdDate: string;
  totalBuddies: number;
  score: number;
  roundTime: string;
  coachId: string;
  gameTrackingId: number;
}

export interface ScorecardHole {
  id: number;
  hole: number;
  par: number;
  handicap: number;
  score: number;
  status: string;
  scorecardDetails: ScorecardDetails;
}

export interface ScorecardDetails {
  fairwayBunkers: string;
  greensideBunkers: string;
  greenInReg: string;
  chips: string;
  penalties: string;
  pitches: string;
  fairwayHit: string;
  putts: string;
  missedPutts: string;
  drivingDistance: string;
  drivingDistanceClub: string;
  terribleTeeShotsQuest: boolean;
  badThreePuttsQuest: boolean;
  nearTheGreenQuest: boolean;
  scramblingPercentNtgChangesQuest: boolean;
  scramblingPercentNtgUpDownQuest: boolean;
  onGreenInRegulationQuest: boolean;
}

export interface ScorecardSetting {
  id: number;
  roundId: number;
  playerId: string;
  score: boolean;
  putts: boolean;
  fairwayHit: boolean;
  fairwayBunkers: boolean;
  greensideBunkers: boolean;
  drivingDistance: boolean;
  pitches: boolean;
  chips: boolean;
  penalties: boolean;
  missedPutts: boolean;
  greenInReg: boolean;
  fourStats: boolean;
}

export interface ScoreCardData {
  id: number;
  roundId: number;
  playerId: number;
  roundSetting: RoundSetting;
  scorecardSetting: ScorecardSetting;
  scorecardHoles: ScorecardHole[];
}

interface ScoreCardState {
  loadingScoreCard: boolean;
  errorCode: null | number;
  data: null | ScoreCardData;
  statusBackHoles: boolean;
  statusModalScorecard: boolean;
  scoreCardDetail: ScoreCardData[];
  myRoundId: string;
}

const initialState: ScoreCardState = {
  loadingScoreCard: false,
  errorCode: null,
  data: null,
  statusBackHoles: false,
  statusModalScorecard: false,
  scoreCardDetail: [],
  myRoundId: '',
};

export const scoreCardSlice = createSlice({
  name: 'scoreCard',
  initialState,
  reducers: {
    changeSelectMyRoundScoreCardId: (state, action) => {
      state.myRoundId = action.payload;
    },
    changeStatusBackHoles: (state, action) => {
      state.statusBackHoles = action.payload;
    },
    changeStatusModalScoreCard: (state, action) => {
      state.statusModalScorecard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchScoreCardData.pending, (state, action) => {
        const idx = state.scoreCardDetail.findIndex(
          (i) => i.roundId === parseInt(state.myRoundId)
        );
        if (idx < 0) {
          state.loadingScoreCard = true;
        }
      })
      .addCase(fetchScoreCardData.fulfilled, (state, action) => {
        state.loadingScoreCard = false;
        const idx = state.scoreCardDetail.findIndex(
          (i) => i.roundId === parseInt(state.myRoundId)
        );
        if (idx >= 0) {
          state.scoreCardDetail[idx] = action.payload.data;
        } else {
          state.scoreCardDetail.push(action.payload.data);
        }
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loadingScoreCard = false;
        }
      );
  },
});

export const {
  changeSelectMyRoundScoreCardId,
  changeStatusBackHoles,
  changeStatusModalScoreCard,
} = scoreCardSlice.actions;

export const selectStatusBackHoles = (state: RootState) =>
  state.scoreCard.statusBackHoles;
export const selectLoadingScoreCard = (state: RootState) =>
  state.scoreCard.loadingScoreCard;
export const selectScoreCardData = (state: RootState) => {
  return state.scoreCard.scoreCardDetail.find(
    (i) => i.roundId === parseInt(state.scoreCard.myRoundId)
  );
};
export const selectMyRoundIdSelect = (state: RootState) =>
  state.scoreCard.myRoundId;
export const selectScoreCardDetail = (state: RootState) =>
  state.scoreCard.scoreCardDetail;
export const statusModalScorecard = (state: RootState) =>
  state.scoreCard.statusModalScorecard;
export default scoreCardSlice.reducer;
