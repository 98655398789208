import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface EditPlayerData {
  firstName: string;
  lastName: string;
  altFirstName: string;
  altLastName: string;
  birthday: string;
  phone?: string;
  newPhone?: string;
  address: string;
  extendedAddress: string;
  city: string;
  state: string;
  pref: string;
  postalCode: string;
  handedness: string;
  gender: string;
  country: string;
  enableMetricDistances: boolean;
  enableMetricSpeeds: boolean;
  enableCelsiusTemperature: boolean;
  type: string | null;
  username?: string;
}

export const fetchUpdatePlayerData = createAsyncThunk(
  'updatePlayer/updatePlayerData',
  async (payload: EditPlayerData, { getState, rejectWithValue }) => {
    try {
      const objPayload = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        fields: {
          handedness: {
            key: 'handedness',
            value: payload.handedness === 'l' ? 'left' : 'right',
          },
        },
        birthday: payload.birthday,
        gender: payload.gender,
        street1: payload.address,
        street2: payload.extendedAddress,
        city: payload.city,
        postalCode: payload.postalCode,
        country: payload.country,
        enableMetricDistances: payload.enableMetricDistances,
        enableMetricSpeeds: payload.enableMetricSpeeds,
        enableCelsiusTemperature: payload.enableCelsiusTemperature,
        type: payload.type ? payload.type : null,
      };
      // const userData = (getState() as RootState).user.data;
      let params: {} = { ...objPayload };
      if (window.location.hostname.match(/skytrakgolf|localhost/)) {
        params = {
          ...params,
          phone: payload.phone,
        };
      }
      if (window.location.hostname.match(/.jp/)) {
        params = {
          ...params,
          altFirstName: payload.altFirstName,
          altLastName: payload.altLastName,
          phone: payload.phone,
        };
        if (window.location.hostname.match(/golfdigest.co.jp/)) {
          params = {
            ...params,
            pref: payload.pref,
          };
        } else {
          params = {
            ...params,
            state: payload.state,
          };
        }
      } else {
        params = { ...params, state: payload.state };
      }
      if (payload.username) {
        params = { ...params, username: payload.username };
      }
      // if (
      //   !(
      //     userData?.emailSubscription.includes('smsGolftec') ||
      //     userData?.emailSubscription.includes('smsSpecialOffers')
      //   )
      // ) {
      //   params = { ...params, phone: payload.phone };
      // }

      const response = await request.put(`/api/v2/player`, params);
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateHandednessData = createAsyncThunk(
  'updatePlayer/updateHandednessData',
  async (payload: string, { getState, rejectWithValue }) => {
    try {
      let params;
      const userData = (getState() as RootState).user.data;
      if (userData?.type) {
        params = {
          type: userData?.type,
          fields: {
            handedness: {
              key: 'handedness',
              value: payload === 'l' ? 'left' : 'right',
            },
          },
        };
      } else {
        params = {
          fields: {
            handedness: {
              key: 'handedness',
              value: payload === 'l' ? 'left' : 'right',
            },
          },
        };
      }
      const response = await request.put(`/api/v2/player`, params);
      return response.data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface UpdatePlayerDataState {
  loading: boolean;
  error: any;
  isUpdated: string;
  userState: string;
  userPref: string;
}

const initialState: UpdatePlayerDataState = {
  loading: false,
  error: null,
  isUpdated: '',
  userState: '',
  userPref: '',
};

export const updatePlayerDataSlice = createSlice({
  name: 'updatePlayer',
  initialState,
  reducers: {
    changeSelectedUserState: (state, action) => {
      state.userState = action.payload;
    },
    changeSelectedUserPref: (state, action) => {
      state.userPref = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUpdatePlayerData.pending, (state, action) => {
        state.loading = true;
        state.isUpdated = 'wait';
      })
      .addCase(fetchUpdatePlayerData.fulfilled, (state, action) => {
        state.loading = false;
        state.isUpdated = 'updated';
      })
      .addCase(signOut, () => ({ ...initialState }));
  },
});

export const { changeSelectedUserState, changeSelectedUserPref } =
  updatePlayerDataSlice.actions;
export const selectSelectedState = (state: RootState) =>
  state.updatePlayer.userState;
export const selectSelectedPref = (state: RootState) =>
  state.updatePlayer.userPref;

export const loadingUpdatePlayerData = (state: RootState) =>
  state.updatePlayer.loading;
export const checkUpdatePlayerData = (state: RootState) =>
  state.updatePlayer.isUpdated;
export default updatePlayerDataSlice.reducer;
