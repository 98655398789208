import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { print } from 'graphql';
import {
  ApplyCouponToCartInput,
  BillingAddressInput,
  Cart,
  Customer,
  CustomerAddressInput,
  MutationUpdateCustomerAddressArgs,
  PaymentMethodInput,
  Region,
  SetShippingAddressesOnCartInput,
  UpdateCartItemsInput,
} from '../models/shop';
import { CouponData } from '../models/shopCustom';
import {
  CREATE_CUSTOMER_ADDRESS,
  DELETE_CUSTOMER_ADDRESS,
  SET_BILLING_ADDRESS_ON_CART,
  SET_PAYMENT_METHOD_AND_PLACE_ORDER,
  SET_SHIPPING_ADDRESSES_ON_CART,
  SET_SHIPPING_METHODS_ON_CART,
  UPDATE_CUSTOMER_ADDRESS,
} from '../utils/mutations';
import { GET_COUNTRY_BY_ID, GET_CUSTOMER_CART } from '../utils/queries';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getCountryById = createAsyncThunk(
  'shop/getCountryById',
  async (id: string, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { country },
        },
      } = await request.get('graphql', {
        params: {
          query: print(GET_COUNTRY_BY_ID),
          variables: {
            id,
          },
        },
      });
      if (country) {
        return country;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerCart = createAsyncThunk(
  'shop/getCustomerCart',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customerCart },
        },
      } = await request.get('graphql', {
        params: {
          query: print(GET_CUSTOMER_CART),
        },
      });
      if (customerCart) {
        dispatch(changeLoadingSetBilling(false));
        dispatch(changeLoadingSetShipping(false));
        return customerCart;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
  // ,
  // {
  //   condition(arg, { getState }) {
  //     const { cartLoading } = (getState() as RootState).shop;
  //     return !cartLoading;
  //   }
  // }
);

export const updateCartItems = createAsyncThunk(
  'shop/updateCartItems',
  async (
    input: Omit<UpdateCartItemsInput, 'cart_id'>,
    { getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(UPDATE_CART_ITEMS),
          variables: {
            input: {
              ...input,
              cart_id: cart.id,
            },
          },
        });
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeItemFromCart = createAsyncThunk(
  'shop/removeItemFromCart',
  async (cartItemId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(REMOVE_ITEM_FROM_CART),
          variables: {
            removeItemFromCartInput: {
              cart_id: cart.id,
              cart_item_id: cartItemId,
            },
          },
        });
        if (data.errors) {
          return rejectWithValue(data.errors);
        }
        dispatch(getCustomerCart());
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCustomerInfo = createAsyncThunk(
  'shop/getCustomerInfo',
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { customer },
        },
      } = await request.get('graphql', {
        params: {
          // query: print(GET_CUSTOMER_INFO),
        },
      });
      return customer;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createCustomerAddress = createAsyncThunk(
  'shop/createCustomerAddress',
  async (
    customerAddressInput: CustomerAddressInput,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(CREATE_CUSTOMER_ADDRESS),
        variables: { customerAddressInput },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }

      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateCustomerAddress = createAsyncThunk(
  'shop/updateCustomerAddress',
  async (
    { id, input }: MutationUpdateCustomerAddressArgs,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(UPDATE_CUSTOMER_ADDRESS),
        variables: { id, input },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteCustomerAddress = createAsyncThunk(
  'shop/deleteCustomerAddress',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(DELETE_CUSTOMER_ADDRESS),
        variables: { id },
      });
      if (data.errors) {
        return rejectWithValue(data.errors);
      }
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setBillingAddressOnCart = createAsyncThunk(
  'shop/saveBillingAddress',
  async (
    billingAddressInput: BillingAddressInput,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(SET_BILLING_ADDRESS_ON_CART),
          variables: {
            billingAddress: billingAddressInput,
            cartId: cart.id,
          },
        });
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setShippingAddressesOnCart = createAsyncThunk(
  'shop/setShippingAddressesOnCart',
  async (
    input: Omit<SetShippingAddressesOnCartInput, 'cart_id'>,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          query: print(SET_SHIPPING_ADDRESSES_ON_CART),
          variables: {
            input: {
              ...input,
              cart_id: cart.id,
            },
          },
        });

        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setShippingMethodsOnCart = createAsyncThunk(
  'shop/setShippingMethodsOnCart',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);

      const { data } = await request.post('graphql', {
        query: print(SET_SHIPPING_METHODS_ON_CART),
        variables: {
          input: {
            cart_id: cart?.id,
            shipping_methods: [
              {
                // This is fixed across mobile and desktop development
                carrier_code: 'localPickup',
                method_code: 'flatrate',
              },
            ],
          },
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
  // {
  //   condition: (_, { getState }) => {
  //     const cart = selectCustomerCart(getState() as RootState);
  //     console.log(cart && cart.items?.length);
  //     return !!(cart && cart.items?.length);
  //   },
  // }
);

export const applyCoupon = createAsyncThunk(
  'shop/applyCoupon',
  async (
    input: Omit<ApplyCouponToCartInput, 'cart_id'>,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(APPLY_COUPON),
          variables: {
            input: {
              ...input,
              cart_id: cart.id,
            },
          },
        });
        if (data && !data.errors) {
          dispatch(getCustomerCart());
        }
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const checkGiftCode = createAsyncThunk(
  'shop/checkGiftCode',
  async (giftCode: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/rest/V1/giftcard/check/${giftCode}`);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const applyGiftCode = createAsyncThunk(
  'shop/applyGiftCode',
  async (
    input: { gift_code: string; amount_applied: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(APPLY_GIFT_CODE_TO_CART),
          variables: {
            ...input,
            cart_id: cart.id,
          },
        });

        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeGiftCodeFromCart = createAsyncThunk(
  'shop/removeGiftCodeFromCart',
  async (gift_code: string, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        await request.post('graphql', {
          // query: print(REMOVE_GIFT_CODE_IN_CART),
          variables: {
            cart_id: cart.id,
            is_remove_all: false, // remove all if true
            gift_code,
          },
        });
        dispatch(getCustomerCart());
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const removeCouponFromCart = createAsyncThunk(
  'shop/removeCouponFromCart',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        await request.post('graphql', {
          // query: print(REMOVE_COUPON_FROM_CART),
          variables: {
            input: {
              cart_id: cart.id,
            },
          },
        });
        dispatch(getCustomerCart());
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

type SetPaymentMethodOnCartInput = {
  cc_type: string;
  cc_last4: string;
  cc_exp_year: string;
  cc_exp_month: string;
  cc_cid: string;
  acceptjs_key: string;
  acceptjs_value: string;
};

export const setPaymentMethodOnCart = createAsyncThunk(
  'shop/setPaymentMethodOnCart',
  async (
    tokenbase_data: SetPaymentMethodOnCartInput,
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(SET_PAYMENT_METHOD_ON_CART),
          variables: {
            input: {
              cart_id: cart.id,
              payment_method: {
                code: 'authnetcim',
                tokenbase_data: {
                  ...tokenbase_data,
                  save: false,
                },
              },
            },
          },
        });
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setPaymentMethodOnCartFree = createAsyncThunk(
  'shop/setPaymentMethodOnCartFree',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(SET_PAYMENT_METHOD_ON_CART),
          variables: {
            input: {
              cart_id: cart.id,
              payment_method: {
                code: 'free',
              },
            },
          },
        });
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const placeOrder = createAsyncThunk(
  'shop/placeOrder',
  async (_, { dispatch, getState, rejectWithValue }) => {
    try {
      const cart = selectCustomerCart(getState() as RootState);
      if (cart) {
        const { data } = await request.post('graphql', {
          // query: print(PLACE_ORDER),
          variables: {
            input: {
              cart_id: cart.id,
            },
          },
        });
        // await dispatch(getCustomerCart()).unwrap();
        return data;
      }
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const setPaymentMethodAndPlaceOrder = createAsyncThunk(
  'shop/setPaymentMethodAndPlaceOrder',
  async (
    payload: {
      cart_id: string;
      paymentInfo: PaymentMethodInput;
      card_name?: string;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(SET_PAYMENT_METHOD_AND_PLACE_ORDER),
        variables: {
          input: {
            cart_id: payload.cart_id,
            payment_method: payload.paymentInfo,
          },
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface ShopState {
  cart: null | Cart;
  cartLoading: boolean;
  loading: string;
  loadingCustomer: boolean;
  customer: null | Customer;
  regions: Region[] | null;
  coupon: CouponData | null;
  defaultAddress: string;
  setBilling: string;
  setShipping: string;
  loadingCustomerCart: string;
  dataUpdateCart: any;
  saveNew: string;
  loadingSaveNew: string;
  chooseCustomerAddressShippingId: null | number;
  chooseCustomerAddressBillingId: null | number;
  loadingSetBilling: boolean;
  loadingSetShipping: boolean;
  loadingAddress: boolean;
}

const initialState: ShopState = {
  cart: null,
  cartLoading: false,
  loading: '',
  loadingCustomer: false,
  customer: null,
  regions: null,
  coupon: null,
  defaultAddress: '',
  setBilling: '',
  setShipping: '',
  saveNew: '',
  loadingCustomerCart: '',
  dataUpdateCart: null,
  loadingSaveNew: '',
  chooseCustomerAddressShippingId: null,
  chooseCustomerAddressBillingId: null,
  loadingSetBilling: false,
  loadingSetShipping: false,
  loadingAddress: false,
};

const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    changeDefaultAddress: (state, action) => {
      state.defaultAddress = action.payload;
    },
    changeStatusLoadingAddress: (state, action) => {
      state.loading = '';
    },
    changeStatusLoadingSaveNewAddress: (state, action) => {
      state.loadingSaveNew = action.payload;
    },
    clearCouponError: (state) => {
      state.coupon = null;
    },
    checkStatusOpenModalEditAddress: (state, action) => {
      state.saveNew = action.payload;
    },
    clearUpdateCart: (state) => {
      state.dataUpdateCart = null;
    },
    changeChooseCustomerAddressShippingId: (state, action) => {
      state.chooseCustomerAddressShippingId = action.payload;
    },
    changeChooseCustomerAddressBillingId: (state, action) => {
      state.chooseCustomerAddressBillingId = action.payload;
    },
    clearLoadingCustomerCart: (state) => {
      state.loadingCustomerCart = '';
    },
    changeLoadingSetBilling: (state, action) => {
      state.loadingSetBilling = action.payload;
    },
    changeLoadingSetShipping: (state, action) => {
      state.loadingSetShipping = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerAddress.pending, (state, action) => {
        state.loading = 'wait';
        state.loadingSaveNew = 'wait';
        state.loadingAddress = true;
      })
      .addCase(createCustomerAddress.fulfilled, (state, action) => {
        state.loading = 'added';
        state.loadingSaveNew = 'added';
        state.loadingAddress = false;
      })
      .addCase(updateCustomerAddress.pending, (state, action) => {
        state.loading = 'wait';
        state.loadingAddress = true;
      })
      .addCase(updateCustomerAddress.fulfilled, (state, action) => {
        state.loading = 'added';
        state.setBilling = 'success';
        state.setShipping = 'success';
        state.loadingAddress = false;
      })
      .addCase(placeOrder.pending, (state, action) => {
        state.loading = 'wait';
      })
      .addCase(placeOrder.fulfilled, (state, action) => {
        state.loading = 'added';
      })
      .addCase(setBillingAddressOnCart.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(setShippingAddressesOnCart.pending, (state, action) => {
        state.loadingAddress = true;
      })
      .addCase(setBillingAddressOnCart.fulfilled, (state, action) => {
        state.setBilling = 'success';
        state.loadingAddress = false;
      })
      .addCase(setShippingAddressesOnCart.fulfilled, (state, action) => {
        state.setShipping = 'success';
        state.loadingAddress = false;
      })
      .addCase(applyCoupon.fulfilled, (state, action) => {
        state.coupon = action.payload;
      })
      .addCase(getCustomerCart.pending, (state) => {
        state.cartLoading = true;
        state.setBilling = '';
        state.loadingCustomerCart = 'wait';
        state.loadingAddress = true;
      })
      .addCase(getCustomerCart.fulfilled, (state, action) => {
        state.cart = action.payload;
        state.setBilling = '';
        state.cartLoading = false;
        state.loadingCustomerCart = 'success';
        state.loadingAddress = false;
      })
      .addCase(getCustomerCart.rejected, (state) => {
        state.cartLoading = false;
      })
      .addCase(updateCartItems.fulfilled, (state, action) => {
        state.dataUpdateCart = action.payload;
      });

    builder.addCase(getCountryById.fulfilled, (state, action) => {
      state.regions = action.payload.available_regions;
    });
    builder.addCase(getCustomerInfo.pending, (state, action) => {
      state.loadingCustomer = true;
    });
    builder.addCase(getCustomerInfo.fulfilled, (state, action) => {
      state.customer = action.payload;
      state.loadingCustomer = false;
    });

    builder.addCase(signOut, () => ({ ...initialState }));
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state, action) => {
        state.loadingCustomer = false;
        state.loadingAddress = false;
      }
    );
  },
});

export const {
  changeDefaultAddress,
  changeStatusLoadingAddress,
  clearCouponError,
  clearUpdateCart,
  checkStatusOpenModalEditAddress,
  changeStatusLoadingSaveNewAddress,
  changeChooseCustomerAddressShippingId,
  changeChooseCustomerAddressBillingId,
  clearLoadingCustomerCart,
  changeLoadingSetBilling,
  changeLoadingSetShipping,
} = shopSlice.actions;

export const selectDefaultAddress = (state: RootState) =>
  state.shop.defaultAddress;
export const selectLoadingAddAddress = (state: RootState) => state.shop.loading;
export const selectSetBillingAddress = (state: RootState) =>
  state.shop.setBilling;
export const selectSetShippingAddress = (state: RootState) =>
  state.shop.setShipping;
export const selectCouponData = (state: RootState) => state.shop.coupon;
export const selectAppliedDiscounts = (state: RootState) =>
  state.shop.cart?.prices?.discounts;
export const selectAppliedCoupon = (state: RootState) =>
  state.shop.cart?.applied_coupons;
export const selectAppliedDiscount = (state: RootState) =>
  state.shop.cart?.prices?.discount;
export const selectAppliedGiftCode = (state: RootState) =>
  state.shop.cart?.prices?.ms_gift_voucher_info?.giftcodes_applied_discount;
export const selectAppliedGiftCodeAmount = (state: RootState) =>
  state.shop.cart?.prices?.ms_gift_voucher_info?.gift_voucher_discount;
export const selectCustomer = (state: RootState) => state.shop.customer;
export const selectCustomerAddresses = (state: RootState) =>
  state.shop.customer?.addresses;
export const selectCustomerCart = (state: RootState) => state.shop.cart;
export const selectLoadingCustomerCart = (state: RootState) =>
  state.shop.loadingCustomerCart;
export const selectCustomerEmail = (state: RootState) =>
  state.shop.customer?.email;
export const selectRegions = (state: RootState) => state.shop.regions;
export const selectDataUpdatedCard = (state: RootState) =>
  state.shop.dataUpdateCart;
export const selectStatusSaveNew = (state: RootState) => state.shop.saveNew;
export const selectLoadingSaveNewAddress = (state: RootState) =>
  state.shop.loadingSaveNew;
export const selectCustomerAddressShippingId = (state: RootState) =>
  state.shop.chooseCustomerAddressShippingId;
export const selectCustomerAddressBillingId = (state: RootState) =>
  state.shop.chooseCustomerAddressBillingId;
export const selectLoadingCustomer = (state: RootState) =>
  state.shop.loadingCustomer;

export const selectLoadingSetBilling = (state: RootState) =>
  state.shop.loadingSetBilling;
export const selectLoadingSetShipping = (state: RootState) =>
  state.shop.loadingSetShipping;
export const selectLoadingAddress = (state: RootState) =>
  state.shop.loadingAddress;
export default shopSlice.reducer;
