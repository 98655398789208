import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import _ from 'lodash';
import {
  GolferState,
  HandicapCardData,
  HandicapHistory,
  SearchGolferData,
  TeeBoxUSGA,
  TeeSetUSGA,
  linkGHIN,
  linkGHINData,
} from '../models/handicap';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const fetchLinkGHIN = createAsyncThunk(
  'handicap/fetchLinkGHIN',
  async (payload: linkGHIN, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/usga/link-ghin`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchGetMyGolfer = createAsyncThunk(
  'handicap/fetchGetMyGolfer',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/usga/golfer`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchGetGolferByGHIN = createAsyncThunk(
  'handicap/fetchGetGolferByGHIN',
  async (ghinNumber: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/usga/golfer/${ghinNumber}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchRevisionScore = createAsyncThunk(
  'handicap/fetchRevisionScore',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/usga/revision-score');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchSearchGolfer = createAsyncThunk(
  'handicap/fetchSearchGolfer',
  async (
    payload: { last_name: string; state: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/usga/golfer/search?last_name=${payload.last_name}&state=${payload.state}&per_page=999`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchHandicapHistory = createAsyncThunk(
  'handicap/fetchHandicapHistory',
  async (
    payload: { from_date: string; to_date: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/usga/handicap-history?from_date=${payload.from_date}&to_date=${payload.to_date}`
      );

      return data;

      // return {
      //   message: 'Process success.',
      //   data: [
      //     {
      //       id: 368248609,
      //       assoc: '98',
      //       club: 210,
      //       clubName: 'The Kelly Club',
      //       hardSoftCap: 'Y',
      //       hardCap: 'N',
      //       softCap: 'Y',
      //       service: 0,
      //       revDate: '2023-04-07T00:00:00',
      //       display: '+5.0',
      //       value: '-5.0',
      //       lowHIDisplay: '+9.2',
      //       lowHI: '-9.2',
      //       hidden: false,
      //       clubID: 29896,
      //       gHINNumber: 7900344,
      //       yTDRounds: '4',
      //       hIBeforeSoftCap: '-3.9',
      //       hIBeforeSoftCapDisplay: '+3.9',
      //     },
      //     {
      //       id: 368248609,
      //       assoc: '98',
      //       club: 210,
      //       clubName: 'The Kelly Club',
      //       hardSoftCap: 'Y',
      //       hardCap: 'N',
      //       softCap: 'Y',
      //       service: 0,
      //       revDate: '2023-03-07T00:00:00',
      //       display: '+1.0',
      //       value: '-5.0',
      //       lowHIDisplay: '+9.2',
      //       lowHI: '-9.2',
      //       hidden: false,
      //       clubID: 29896,
      //       gHINNumber: 7900344,
      //       yTDRounds: '4',
      //       hIBeforeSoftCap: '-3.9',
      //       hIBeforeSoftCapDisplay: '+3.9',
      //     },
      //     // {
      //     //   id: 368248609,
      //     //   assoc: '98',
      //     //   club: 210,
      //     //   clubName: 'The Kelly Club',
      //     //   hardSoftCap: 'Y',
      //     //   hardCap: 'N',
      //     //   softCap: 'Y',
      //     //   service: 0,
      //     //   revDate: '2023-02-07T00:00:00',
      //     //   display: '+3.0',
      //     //   value: '-5.0',
      //     //   lowHIDisplay: '+9.2',
      //     //   lowHI: '-9.2',
      //     //   hidden: false,
      //     //   clubID: 29896,
      //     //   gHINNumber: 7900344,
      //     //   yTDRounds: '4',
      //     //   hIBeforeSoftCap: '-3.9',
      //     //   hIBeforeSoftCapDisplay: '+3.9',
      //     // },
      //   ],
      //   code: 0,
      // };
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const getTeeBoxByCourseId = createAsyncThunk(
  'handicap/getTeeBoxByCourseId',
  async (courseId: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/usga/tee-box/${courseId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export interface ParamsPostScoreExistingRound {
  round_id: number;
  tee_set_id: string;
  played_at: string;
  score_type: string;
  number_of_holes: number;
  tee_set_side: string;
}

export const postScoreExistingRound = createAsyncThunk(
  'handicap/postScoreExistingRound',
  async (params: ParamsPostScoreExistingRound, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/usga/score/${params.round_id}`,
        params
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export interface ParamsePostScoreNewRound {
  uSGASelectedTeeId: string;
  scope: string;
  courseId: string;
  playedAt: string;
  uSGAScoreType: string;
  courseName: string;
  tee_set_side: string;
  score?: number;
  scorecardHoles?: ScorecardHoleUSGA[];
}

export interface ScorecardHoleUSGA {
  hole: number;
  score?: number;
  status: string;
  putts?: number;
  gir?: boolean;
  par?: number;
  handicap?: number;
}

export const postScoreNewRound = createAsyncThunk(
  'handicap/postScoreNewRound',
  async (payload: ParamsePostScoreNewRound, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/usga/score`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

interface handicapState {
  data: linkGHINData | null;
  checkLoadData: boolean;
  dataGolfer: GolferState | null;
  dataTeeBoxByCourseId: TeeBoxUSGA | null;
  dataHandicapCard: HandicapCardData | null;
  searchGolferData: SearchGolferData[] | null;
  handicapHistory: HandicapHistory[] | null;
  error: boolean;
  teeBoxUSGASelected: TeeSetUSGA | null;
  countryAddNewRound: string;
  stateAddNewRound: string | null;
  courseAddNewRound: { courseName: string; courseId: string } | null;
  countryGolferLookup: string;
  stateGolferLookup: string | null;
  statusOpenScoreCardHandicap: boolean;
  scoreCardAddNewRound: ScorecardHoleUSGA[];
  statusOpenNotEnoughScoreCard: boolean;
  statusOpenMissTeeBox: boolean;
  statusOpenNotice: 'success' | 'failed' | 'close';
  messPostScore: string;
  statusSignIn: boolean;
  loadingRevisionScore: boolean;
  loadingHandicapHistory: boolean;
  loadingTeeBoxByCourseId: boolean;
}

const initialState: handicapState = {
  data: null,
  error: false,
  dataGolfer: null,
  dataTeeBoxByCourseId: null,
  dataHandicapCard: null,
  searchGolferData: null,
  handicapHistory: null,
  teeBoxUSGASelected: null,
  countryAddNewRound: '',
  stateAddNewRound: null,
  countryGolferLookup: '',
  stateGolferLookup: null,
  courseAddNewRound: null,
  statusOpenScoreCardHandicap: false,
  scoreCardAddNewRound: _.map(_.range(1, 19), (number) => ({
    hole: number,
    status: 'completed',
  })),
  statusOpenNotEnoughScoreCard: false,
  statusOpenMissTeeBox: false,
  statusOpenNotice: 'close',
  messPostScore: '',
  statusSignIn: false,
  checkLoadData: false,
  loadingRevisionScore: false,
  loadingHandicapHistory: false,
  loadingTeeBoxByCourseId: false,
};

const handicapSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    removeLinkGHINError: (state, action) => {
      state.error = action.payload;
    },
    changeTeeBoxUSGASelected: (state, action) => {
      state.teeBoxUSGASelected = action.payload;
    },
    changeCountryAddNewRound: (state, action) => {
      state.countryAddNewRound = action.payload;
    },
    changeStateAddNewRound: (state, action) => {
      state.stateAddNewRound = action.payload;
    },
    changeCourseAddNewRound: (state, action) => {
      state.courseAddNewRound = action.payload;
    },
    changeCountryGolferLookup: (state, action) => {
      state.countryGolferLookup = action.payload;
    },
    changeStateGolferLookup: (state, action) => {
      state.stateGolferLookup = action.payload;
    },
    changeStatusOpenScoreCardHandicap: (state, action) => {
      state.statusOpenScoreCardHandicap = action.payload;
    },
    changeScoreCardAddNewRound: (state, action) => {
      state.scoreCardAddNewRound = action.payload;
    },
    changeStatusOpenNotEnoughScoreCard: (state, action) => {
      state.statusOpenNotEnoughScoreCard = action.payload;
    },
    changeStatusOpenMissTeeBox: (state, action) => {
      state.statusOpenMissTeeBox = action.payload;
    },
    changeStatusOpenNotice: (state, action) => {
      state.statusOpenNotice = action.payload;
    },
    changeMessagePostScore: (state, action) => {
      state.messPostScore = action.payload;
    },
    changeStatusSignInUSGA: (state, action) => {
      state.statusSignIn = action.payload;
    },
    changeCheckLoadDataGolfter: (state, action) => {
      state.checkLoadData = action.payload;
    },
    resetCountryStateAddNewRound: (state) => {
      state.countryAddNewRound = '';
      state.stateAddNewRound = null;
      state.courseAddNewRound = null;
      state.scoreCardAddNewRound = _.map(_.range(1, 19), (number) => ({
        hole: number,
        status: 'completed',
      }));
      state.statusOpenScoreCardHandicap = false;
    },
    resetCountryStateLookup: (state) => {
      state.countryGolferLookup = '';
      state.stateGolferLookup = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLinkGHIN.fulfilled, (state, action) => {
        state.data = action.payload;
        state.error = false;
        state.checkLoadData = true;
      })
      .addCase(fetchLinkGHIN.rejected, (state, action) => {
        state.error = true;
      });
    builder.addCase(fetchGetMyGolfer.fulfilled, (state, action) => {
      state.dataGolfer = action.payload.data;
    });
    builder
      .addCase(fetchRevisionScore.pending, (state, action) => {
        state.loadingRevisionScore = true;
      })
      .addCase(fetchRevisionScore.fulfilled, (state, action) => {
        state.dataHandicapCard = action.payload.data;
        state.loadingRevisionScore = false;
      });
    builder.addCase(fetchSearchGolfer.fulfilled, (state, action) => {
      state.searchGolferData = action.payload.data;
    });
    builder.addCase(getTeeBoxByCourseId.pending, (state, action) => {
      state.loadingTeeBoxByCourseId = true;
    });
    builder.addCase(getTeeBoxByCourseId.fulfilled, (state, action) => {
      state.loadingTeeBoxByCourseId = false;
      state.dataTeeBoxByCourseId = action.payload.data;
    });
    builder.addCase(getTeeBoxByCourseId.rejected, (state) => {
      state.dataTeeBoxByCourseId = null;
      state.loadingTeeBoxByCourseId = false;
    });
    builder
      .addCase(fetchHandicapHistory.pending, (state, action) => {
        state.loadingHandicapHistory = true;
      })
      .addCase(fetchHandicapHistory.fulfilled, (state, action) => {
        state.loadingHandicapHistory = false;
        state.handicapHistory = action.payload.data;
      });
    builder
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {}
      );
  },
});

export const {
  removeLinkGHINError,
  changeTeeBoxUSGASelected,
  changeCountryAddNewRound,
  changeStateAddNewRound,
  changeCountryGolferLookup,
  changeStateGolferLookup,
  resetCountryStateAddNewRound,
  changeCourseAddNewRound,
  changeStatusOpenScoreCardHandicap,
  changeScoreCardAddNewRound,
  changeStatusOpenNotEnoughScoreCard,
  changeStatusOpenMissTeeBox,
  resetCountryStateLookup,
  changeStatusOpenNotice,
  changeMessagePostScore,
  changeStatusSignInUSGA,
  changeCheckLoadDataGolfter,
} = handicapSlice.actions;

export const selectLinkGHINData = (state: RootState) => state.handicap.data;
export const selectMyGolferData = (state: RootState) =>
  state.handicap.dataGolfer;
export const selectHandicapCardData = (state: RootState) =>
  state.handicap.dataHandicapCard;
export const selectLinkGHINDataError = (state: RootState) =>
  state.handicap.error;
export const selectSearchGolferData = (state: RootState) =>
  state.handicap.searchGolferData;
export const selectHandicapHistoryData = (state: RootState) =>
  state.handicap.handicapHistory;
export const selectDataTeeBoxByCourseId = (state: RootState) =>
  state.handicap.dataTeeBoxByCourseId;
export const selectTeeBoxUSGASelected = (state: RootState) =>
  state.handicap.teeBoxUSGASelected;
export const selectCountryAddNewRound = (state: RootState) =>
  state.handicap.countryAddNewRound;
export const selectStateAddNewRound = (state: RootState) =>
  state.handicap.stateAddNewRound;
export const selectCourseAddNewRound = (state: RootState) =>
  state.handicap.courseAddNewRound;
export const selectStatusOpenScoreCardHandicap = (state: RootState) =>
  state.handicap.statusOpenScoreCardHandicap;
export const selectScoreCardAddNewRound = (state: RootState) =>
  state.handicap.scoreCardAddNewRound;
export const selectStatusOpenNotEnoughScoreCard = (state: RootState) =>
  state.handicap.statusOpenNotEnoughScoreCard;
export const selectStatusOpenMissTeeBox = (state: RootState) =>
  state.handicap.statusOpenMissTeeBox;
export const selectCountryLookup = (state: RootState) =>
  state.handicap.countryGolferLookup;
export const selectStateLookup = (state: RootState) =>
  state.handicap.stateGolferLookup;
export const selectStatusOpenNotice = (state: RootState) =>
  state.handicap.statusOpenNotice;
export const selectMessagePostScore = (state: RootState) =>
  state.handicap.messPostScore;
export const selectStatusSignInUSGA = (state: RootState) =>
  state.handicap.statusSignIn;
export const selectCheckLoadDataGolfter = (state: RootState) =>
  state.handicap.checkLoadData;
export const selectLoadingHandicapHistory = (state: RootState) =>
  state.handicap.loadingHandicapHistory;
export const selectLoadingRevisionScore = (state: RootState) =>
  state.handicap.loadingRevisionScore;
export const selectLoadingTeeBoxByCourseId = (state: RootState) =>
  state.handicap.loadingTeeBoxByCourseId;

export default handicapSlice.reducer;
