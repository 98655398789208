import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export interface ParamUrl {
  type: string ;
  distanceToPin: string ;
  centerId: string ;
  coachId: string ;
}

export interface dataLeader {
  rankStatus: string;
  coachName: string;
  index: number;
  modified: string;
  playerFirstName: string;
  playerLastName: string;
  score: number;
  stationDistanc: number;
  year: number;
  centerShortNameCountry: string;
  centerName: string;
  playerCreatedDate: string;
  stationDistance: number;
  playerAvatar: string;
}
export interface ListLeaderBoardData {
  details: dataLeader[];
  boardName: string;
  lastModified: string;
  year: number;
}

interface LeaderBoard {
  loading: boolean;
  error: null | number;
  data: null | ListLeaderBoardData;
}

const initialState: LeaderBoard = {
  loading: false,
  error: null,
  data: null,
};

export const fetchListLeaderBoard = createAsyncThunk(
  'leaderboard/showleaderboard',
  async (params: ParamUrl, { rejectWithValue }) => {
    let url = `/api/leader-board/challenge?type=driver`;
    if (params.type) {
      if (params.type === 'global' && params.distanceToPin) {
        url = `/api/leader-board/challenge?type=${params.distanceToPin}`;
      }
      if (params.type === 'center' && params.centerId) {
        url = `/api/leader-board/challenge?type=${params.distanceToPin}&centerId=${params.centerId}`;
      }
      if (params.type === 'coach' && params.coachId) {
        url = `/api/leader-board/challenge?type=${params.distanceToPin}&coachId=${params.coachId}`;
      }
    }

    try {
      const { data } = await request.get(url);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const leaderBoardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchListLeaderBoard.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchListLeaderBoard.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = { ...data };
        state.loading = false;
      })
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const selectListLeaderBoardData = (state: RootState) =>
  state.listLeaderBoard.data;
export const loadingListLeaderBoardData = (state: RootState) =>
  state.listLeaderBoard.loading;
export default leaderBoardSlice.reducer;
