import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../utils/store';
import { signOut } from './user';

interface ConfigState {
  mainNavigationStatus: boolean;
  profileSummaryWidth: number;
  countItem: number;
  isChangeBackground: boolean;
  isHideProfileSummary: boolean;
  open: boolean;
  openIFrameShop: boolean;
  isTabletApp: boolean;
  showPopupRedirect: boolean;
  urlProShop: string;
}

const initialState: ConfigState = {
  mainNavigationStatus: false,
  profileSummaryWidth: 235,
  countItem: 0,
  isChangeBackground: false,
  isHideProfileSummary: false,
  open: false,
  openIFrameShop: false,
  isTabletApp: false,
  showPopupRedirect: false,
  urlProShop: '',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    countMenuItem: (state, action) => {
      state.countItem = action.payload;
    },
    changeMainNavigationStatus: (state, action) => {
      state.mainNavigationStatus = action.payload;
    },
    changeProfileSummaryWidth: (state, action) => {
      state.profileSummaryWidth = action.payload;
    },
    changeBackgroundSite: (state, action) => {
      state.isChangeBackground = action.payload;
    },
    setHideProfileSummary: (state, action) => {
      state.isHideProfileSummary = action.payload;
    },
    setOpenSearchProduct: (state, action) => {
      state.open = action.payload;
    },
    changeOpenIframeShop: (state, action) => {
      state.openIFrameShop = action.payload;
    },
    changeIsTabletApp: (state, action) => {
      state.isTabletApp = action.payload;
    },
    changeStatusShowPopupRedirect: (state, action) => {
      state.showPopupRedirect = action.payload;
    },
    changeUrlProShop: (state, action) => {
      state.urlProShop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signOut, (state) => {
      return {
        ...initialState,
        isTabletApp: state.isTabletApp,
      };
    });
  },
});

export const {
  changeMainNavigationStatus,
  changeProfileSummaryWidth,
  countMenuItem,
  changeBackgroundSite,
  setHideProfileSummary,
  setOpenSearchProduct,
  changeOpenIframeShop,
  changeIsTabletApp,
  changeStatusShowPopupRedirect,
  changeUrlProShop,
} = configSlice.actions;

export const selectMainNavigationStatus = (state: RootState) =>
  state.config.mainNavigationStatus;

export const selectNumberMenuItem = (state: RootState) =>
  state.config.countItem;

export const selectProfileSummaryWidth = (state: RootState) =>
  state.config.profileSummaryWidth;

export const selectIsChangeBackgroundSite = (state: RootState) =>
  state.config.isChangeBackground;

export const selectIsHideProfileSummary = (state: RootState) =>
  state.config.isHideProfileSummary;

export const selectOpenSearchProduct = (state: RootState) => state.config.open;

export const selectOpenIframeShop = (state: RootState) =>
  state.config.openIFrameShop;

export const selectIsTabletApp = (state: RootState) => state.config.isTabletApp;

export const selectStatusShowPopupRedirect = (state: RootState) =>
  state.config.showPopupRedirect;

export const selectUrlProShop = (state: RootState) => state.config.urlProShop;

export default configSlice.reducer;
