import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import CryptoJS from 'crypto-js';

import { print } from 'graphql';
import sessionStorage from 'redux-persist/es/storage/session';
import returnBaseUrl from '../components/CommonFunction/baseUrl';
import {
  ComparePlayer,
  CreditData,
  PayloadMergeProfile,
  PlayerCredit,
  signInWithChildrenAccountParams,
  signInWithCredentialsParams,
  UserData,
} from '../models/user';
import { LOGOUT_M2 } from '../utils/mutations';
import request from '../utils/request';
import { RootState } from '../utils/store';

const baseUrl = returnBaseUrl();

export const signInWithTicket = createAsyncThunk(
  'user/signInWithTicket',
  async (ticket: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/player/admin-login?ticket=${encodeURIComponent(ticket)}`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const sytrakSignInWithTicket = createAsyncThunk(
  'user/sytrakSignInWithTicket',
  async (ticket: string, { rejectWithValue }) => {
    try {
      const { data } = await request.get(
        `/api/player/admin-login?ticket=${encodeURIComponent(
          ticket
        )}&isSkytrak=true`
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const signInWithCredentials = createAsyncThunk(
  'user/signInWithCredentials',
  async (params: signInWithCredentialsParams, { rejectWithValue }) => {
    try {
      let param;
      param = {
        username: params.username,
        password: params.password,
        verifiedRegion: params.verifiedRegion,
      };
      const hostName = window.location.hostname;
      if (hostName.match(/.skytrakgolf/) && params.type) {
        param = { ...param, type: params.type };
      }
      const { status, data } = await axios.post(`/api/v2/player/login`, param, {
        baseURL: baseUrl,
      });
      if (status === 200) {
        if (data.code === 90015) {
          const access_token = data.data.access_token;
          const refresh_token = data.data.refresh_token;
          const authToken = encodeURIComponent(
            CryptoJS.AES.encrypt(
              JSON.stringify([
                {
                  access_token,
                  refresh_token,
                },
              ]),
              'clubhouse',
              {
                keySize: 128 / 8,
                iv: CryptoJS.enc.Hex.parse('clubhouse'),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
              }
            ).toString()
          );

          if (
            window.location.hostname.includes('qa') ||
            window.location.hostname.includes('dev') ||
            window.location.hostname === 'localhost'
          ) {
            if (data.data.region === 'CN')
              window.location.assign(
                `https://clubhouseqa.golftec.cn?authToken=${authToken}`
              );
            else if (data.data.region === 'QB')
              window.location.assign(
                `https://clubhouseqa.golftec.ca?authToken=${authToken}`
              );
            else if (data.data.region === 'SG')
              window.location.assign(
                `https://clubhouseqa.golftec.com.sg?authToken=${authToken}`
              );
            else if (data.data.region === 'HK')
              window.location.assign(
                `https://clubhouseqa.golftec.com.hk?authToken=${authToken}`
              );
            else if (data.data.region === 'JP')
              window.location.assign(
                `https://clubhouseqa.golfdigest.co.jp?authToken=${authToken}`
              );
            else if (data.data.region === 'AE')
              window.location.assign(
                `https://clubhouseqa.golftec.ae/?authToken=${authToken}`
              );
            else if (data.data.region === 'ZA')
              window.location.assign(
                `https://clubhouseqa.golftec.co.za/?authToken=${authToken}`
              );
            else if (
              data.data.region === 'GB' ||
              data.data.region === 'US' ||
              data.data.region === 'CA'
            )
              window.location.assign(
                `https://clubhouseqa.golftec.com?authToken=${authToken}`
              );
          } else {
            if (data.data.region === 'CN') {
              window.location.assign(
                `https://clubhouse.golftec.cn?authToken=${authToken}`
              );
            } else if (data.data.region === 'QB') {
              window.location.assign(
                `https://clubhouse.golftec.ca?authToken=${authToken}`
              );
            } else if (data.data.region === 'SG') {
              window.location.assign(
                `https://clubhouse.golftec.com.sg?authToken=${authToken}`
              );
            } else if (data.data.region === 'HK') {
              window.location.assign(
                `https://clubhouse.golftec.com.hk?authToken=${authToken}`
              );
            } else if (data.data.region === 'JP') {
              window.location.assign(
                `https://clubhouse.golfdigest.co.jp?authToken=${authToken}`
              );
            } else if (data.data.region === 'AE') {
              window.location.assign(
                `https://clubhouse.golftec.ae/?authToken=${authToken}`
              );
            } else if (data.data.region === 'ZA') {
              window.location.assign(
                `https://clubhouse.golftec.co.za/?authToken=${authToken}`
              );
            } else if (
              data.data.region === 'GB' ||
              data.data.region === 'US' ||
              data.data.region === 'CA'
            ) {
              window.location.assign(
                `https://clubhouse.golftec.com?authToken=${authToken}`
              );
            }
          }
        } else {
          return data;
        }
      }
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const signInWithChildrenAccount = createAsyncThunk(
  'user/signInWithChildrenAccount',
  async (params: signInWithChildrenAccountParams, { rejectWithValue }) => {
    try {
      const { data } = await request.post(
        `/api/player/sub-account/login`,
        params
      );
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const refreshAuthToken = createAsyncThunk(
  'user/refreshAuthToken',
  async (_, { dispatch, getState }) => {
    try {
      const refreshToken = (getState() as RootState).user.refreshToken;
      const { data } = await axios.post(`/api/v2/player/refresh-token`, {
        refresh_token: refreshToken,
      });
      return data;
    } catch {
      dispatch(signOut());
    }
  }
);

export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v2/player/me`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchUserSkystrakData = createAsyncThunk(
  'user/fetchUserSkystrakData',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/skytrak/customer/me`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchUserCredit = createAsyncThunk(
  'user/fetchUserCredit',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v2/player/lesson/credit`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const fetchUserCreditV3 = createAsyncThunk(
  'user/fetchUserCreditV3',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/v3/player/lesson/credit`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const getTicketTokenQA = createAsyncThunk(
  'user/getTicketTokenQA',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/player/admin-ticket`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const logOutM2 = createAsyncThunk(
  'user/logOutM2',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.post('graphql', {
        query: print(LOGOUT_M2),
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getInfoComparePlayer = createAsyncThunk(
  'user/getInfoComparePlayer',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/player/compare`);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

export const mergePlayerInfo = createAsyncThunk(
  'user/mergePlayerInfo',
  async (payload: PayloadMergeProfile, { rejectWithValue }) => {
    try {
      const { data } = await request.post('/api/player/merge', payload);
      return data;
    } catch (err) {
      let error = err as AxiosError;
      return rejectWithValue(error?.response?.data?.code);
    }
  }
);

interface UserState {
  error: null | number;
  isAuthenticated: boolean;
  token: string;
  eToken: string;
  eStoreCode: string;
  eUrl: string;
  refreshToken: string;
  data: null | UserData;
  credit: null | CreditData;
  rememberMe: string;
  loading: boolean;
  tokenChildren: string;
  eTokenChildren: string;
  eStoreCodeChildren: string;
  eUrlChildren: string;
  isLoading: string;
  checkLoginFamily: string;
  loginByTicket: boolean;
  isSubAccountsLogin: boolean;
  isSkytrak: boolean;
  statusPlayGolfQA: 'my-game' | 'my-rounds' | 'my-bag';
  ticketLoginQA: string;
  checkLoginByTicket: boolean;
  creditV3: null | PlayerCredit[];
  tokenSource: null | string;
  userType: null | 1 | 2 | 3;
  isSpeedKPH: boolean;
  isMeter: boolean;
  eSkytrakStoreCode: string;
  eSkytrakToken: string;
  comparePlayer: ComparePlayer | null;
  loadingCredit: boolean;
}

const initialState: UserState = {
  error: null,
  isAuthenticated: false,
  token: '',
  tokenChildren: '',
  eToken: '',
  eStoreCode: '',
  eUrl: '',
  refreshToken: '',
  data: null,
  credit: null,
  creditV3: null,
  rememberMe: '',
  loading: false,
  isLoading: '',
  checkLoginFamily: '',
  loginByTicket: false,
  isSubAccountsLogin: false,
  eStoreCodeChildren: '',
  eTokenChildren: '',
  eUrlChildren: '',
  isSkytrak: false,
  statusPlayGolfQA: 'my-game',
  ticketLoginQA: '',
  checkLoginByTicket: false,
  tokenSource: null,
  userType: null,
  isSpeedKPH: false,
  isMeter: false,
  eSkytrakStoreCode: '',
  eSkytrakToken: '',
  comparePlayer: null,
  loadingCredit: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCrossDomainToken: (state, action) => {
      state.token = action.payload;
      state.refreshToken = '';
    },
    changeAppToken: (state, action) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.eToken = action.payload.eToken;
      state.eStoreCode = action.payload.eStoreCode;
      state.eUrl = action.payload.eUrl;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },

    setLoginFamilyAccount: (state, action) => {
      state.checkLoginFamily = action.payload;
    },
    checkLoginWithChildrenAccounts: (state) => {
      state.tokenChildren = '';
      state.eTokenChildren = '';
      state.eUrlChildren = '';
      state.eStoreCodeChildren = '';
    },
    checkSubAccountsLogin: (state, action) => {
      state.isSubAccountsLogin = action.payload;
    },
    changeStatusPlayGolfQA: (state, action) => {
      state.statusPlayGolfQA = action.payload;
    },
    changeStatusLoginByTicket: (state, action) => {
      state.checkLoginByTicket = action.payload;
    },

    signOut: (state) => {
      localStorage.setItem('signOut', 'true');
      return {
        ...initialState,
        rememberMe: state.rememberMe,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshAuthToken.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.token = data.access_token;
      state.eToken = data.eToken;
      state.eStoreCode = data.eStoreCode;
      state.eUrl = data.eUrl;
      state.refreshToken = data.refresh_token;
    });
    builder
      .addCase(signInWithTicket.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signInWithTicket.fulfilled, (state, action) => {
        const { data } = action.payload;
        sessionStorage.removeItem('parentToken');
        state.loading = false;
        state.isAuthenticated = true;
        state.token = data.access_token;
        state.eTokenChildren = data.eToken;
        state.eToken = data.eToken;
        state.eStoreCode = data.eStoreCode;
        state.eSkytrakStoreCode = data.eSkytrakStoreCode;
        state.eSkytrakToken = data.eSkytrakToken;
        state.eUrl = data.eUrl;
        state.refreshToken = data.refresh_token;
        state.checkLoginFamily = '';
        state.loginByTicket = true;
        state.isSubAccountsLogin = false;
        state.isSkytrak = false;
        state.tokenSource = data.tokenSource;
      })
      .addCase(sytrakSignInWithTicket.pending, (state, action) => {
        state.loading = true;
        state.isSkytrak = false;
      })
      .addCase(sytrakSignInWithTicket.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.isAuthenticated = true;
        state.token = data.access_token;
        state.eTokenChildren = data.eToken;
        state.eToken = data.eToken;
        state.eStoreCode = data.eStoreCode;
        state.eSkytrakStoreCode = data.eSkytrakStoreCode;
        state.eSkytrakToken = data.eSkytrakToken;
        state.eUrl = data.eUrl;
        state.refreshToken = data.refresh_token;
        state.checkLoginFamily = '';
        state.loginByTicket = true;
        state.isSubAccountsLogin = false;
        state.isSkytrak = true;
        state.tokenSource = data.tokenSource;
      })
      .addCase(signInWithCredentials.fulfilled, (state, action) => {
        const { data } = action.payload;
        sessionStorage.setItem('parentToken', data.access_token);
        state.token = data.access_token;
        state.eTokenChildren = data.eToken;
        state.eToken = data.eToken;
        state.eStoreCode = data.eStoreCode;
        state.eSkytrakStoreCode = data.eSkytrakStoreCode;
        state.eSkytrakToken = data.eSkytrakToken;
        state.eUrl = data.eUrl;
        state.refreshToken = data.refresh_token;
        state.checkLoginFamily = '';
        state.isSubAccountsLogin = false;
        state.isSkytrak = false;
        state.checkLoginByTicket = false;
        state.tokenSource = data.tokenSource;
      })
      .addCase(signInWithChildrenAccount.pending, (state, action) => {
        sessionStorage.removeItem('checkFamily');
      })
      .addCase(signInWithChildrenAccount.fulfilled, (state, action) => {
        const { data } = action.payload;
        sessionStorage.setItem('childrenToken', data.authToken);
        sessionStorage.removeItem('parentToken');
        state.isAuthenticated = true;
        state.tokenChildren = data.authToken;
        state.eTokenChildren = '';
        state.eStoreCodeChildren = '';
        state.eUrlChildren = '';
        state.refreshToken = '';
        state.checkLoginFamily = 'accept';
        state.isSubAccountsLogin = data?.profile.isSubAccountsLogin;
      })

      .addCase(fetchUserData.pending, (state, action) => {
        state.loading = true;
        state.isLoading = 'wait';
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.data = data;
        if (data.type === 'skytrak') {
          state.userType = 3;
        } else if (data.type === 'skytrak_golftec') {
          state.userType = 2;
        } else {
          state.userType = 1;
        }
        if (data.enableMetricDistances) {
          state.isMeter = true;
        }
        if (data.enableMetricSpeeds) {
          state.isSpeedKPH = true;
        }
        state.isLoading = 'success';
        sessionStorage.removeItem('checkFamily');
      })
      .addCase(fetchUserSkystrakData.pending, (state, action) => {
        state.loading = true;
        state.isLoading = 'wait';
      })
      .addCase(fetchUserSkystrakData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.data = data;
        state.isLoading = 'success';
        sessionStorage.removeItem('checkFamily');
        if (data.enableMetricDistances) {
          state.isMeter = true;
        }
        if (data.enableMetricSpeeds) {
          state.isSpeedKPH = true;
        }
        if (data.type === 'skytrak') {
          state.userType = 3;
        } else if (data.type === 'skytrak_golftec') {
          state.userType = 2;
        } else {
          state.userType = 1;
        }
      })
      .addCase(fetchUserCredit.pending, (state) => {
        state.loadingCredit = true;
      })
      .addCase(fetchUserCreditV3.pending, (state) => {
        state.loadingCredit = true;
      })
      .addCase(fetchUserCredit.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.credit = data;
        state.loadingCredit = false;
      })
      .addCase(fetchUserCreditV3.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.creditV3 = data;
        state.loadingCredit = false;
      })
      .addCase(getTicketTokenQA.fulfilled, (state, action) => {
        state.ticketLoginQA = action.payload.data;
      })
      .addCase(getInfoComparePlayer.fulfilled, (state, action) => {
        state.comparePlayer = action.payload.data;
      })

      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.error = action.payload;
        }
      );
  },
});

export const {
  changeAppToken,
  signOut,
  setRememberMe,
  setIsAuthenticated,
  setCrossDomainToken,
  setLoginFamilyAccount,
  checkLoginWithChildrenAccounts,
  checkSubAccountsLogin,
  changeStatusLoginByTicket,
  changeStatusPlayGolfQA,
} = userSlice.actions;

export const selectIsSubAccountsLogin = (state: RootState) =>
  state.user.isSubAccountsLogin;

export const selectAuthenticationStatus = (state: RootState) =>
  state.user.isAuthenticated;

export const selectStatusLoginFamilyAccount = (state: RootState) =>
  state.user.checkLoginFamily;

export const selectLoadingUserData = (state: RootState) => state.user.loading;

export const selectIsLoadingUserData = (state: RootState) =>
  state.user.isLoading;

export const selectAuthenticationEToken = (state: RootState) =>
  state.user.eToken;

export const selectAuthenticationETokenChildren = (state: RootState) =>
  state.user.eTokenChildren;

export const selectAuthenticationToken = (state: RootState) => state.user.token;

export const selectAuthenticationTokenChildren = (state: RootState) =>
  state.user.tokenChildren;

export const selectUserData = (state: RootState) => state.user.data as UserData;

export const selectUserCredit = (state: RootState) => state.user.credit;
export const selectUserCreditV3 = (state: RootState) => state.user.creditV3;

export const selectLoginByTicket = (state: RootState) =>
  state.user.loginByTicket;

export const selectIsSkytrak = (state: RootState) => state.user.isSkytrak;

export const selectStatusPlayGolfQA = (state: RootState) =>
  state.user.statusPlayGolfQA;
export const selectTicketTokenQA = (state: RootState) =>
  state.user.ticketLoginQA;
export const selectStoreCodeEcommerce = (state: RootState) =>
  state.user.eStoreCode;
export const selectSkytrakStoreCodeEcommerce = (state: RootState) =>
  state.user.eSkytrakStoreCode;
export const selectCheckLoginByTicket = (state: RootState) =>
  state.user.checkLoginByTicket;
export const selectTokenSource = (state: RootState) => state.user.tokenSource;
export const selectUserTypeData = (state: RootState) => state.user.userType;
export const selectIsMeter = (state: RootState) => state.user.isMeter;
export const selectIsSpeedKPH = (state: RootState) => state.user.isSpeedKPH;
export const selectCompareInfoPlayer = (state: RootState) =>
  state.user.comparePlayer;
export const selectEUrlEcommerce = (state: RootState) => state.user.eUrl;
export const selectSkytrakToken = (state: RootState) =>
  state.user.eSkytrakToken;
export const selectLoadingCredit = (state: RootState) =>
  state.user.loadingCredit;

export default userSlice.reducer;
