import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  AllSeriesVideoLibrary,
  CategoryVideoLibrary,
  ClassesSeriesIndexVideoLibrary,
  DataSessionAnalytics,
  EventAnalytics,
  Note,
  SeriesVideoLibrary,
  VideoLibrary,
} from '../models/videoLibrary';
import request from '../utils/request';
import { RootState } from '../utils/store';
import { signOut } from './user';

export const getLanguageVideoLibrary = createAsyncThunk(
  'videoLibrary/getLanguageVideoLibrary',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/video/languages');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getCategoriesVideoLibrary = createAsyncThunk(
  'videoLibrary/getCategoriesVideoLibrary',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/video/categories');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSeriesVideoLibrary = createAsyncThunk(
  'videoLibrary/getSeriesVideoLibrary',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get('/api/video/series');
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getSeriesVideoLibraryById = createAsyncThunk(
  'videoLibrary/getSeriesVideoLibraryById',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/video/series/${id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addFavoriteSeriesVideoLibrary = createAsyncThunk(
  'videoLibrary/addFavoriteSeriesVideoLibrary',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.post(`/api/video/favorites_series/${id}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteFavoriteSeriesVideoLibrary = createAsyncThunk(
  'videoLibrary/deleteFavoriteSeriesVideoLibrary',
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(
        `/api/video/favorites_series/${id}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addFavoriteVideoLibrary = createAsyncThunk(
  'videoLibrary/addFavoriteVideoLibrary',
  async (
    payload: { videoId: number; seriesId: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `api/video/favorite/${payload.videoId}/series/${payload.seriesId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteFavoriteVideoLibrary = createAsyncThunk(
  'videoLibrary/deleteFavoriteVideoLibrary',
  async (
    payload: { videoId: number; seriesId: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.delete(
        `api/video/favorite/${payload.videoId}/series/${payload.seriesId}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getListVideoFavorites = createAsyncThunk(
  'videoLibrary/getListVideoFavorites',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`api/video/favorites`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const trackingVideoLibrary = createAsyncThunk(
  'videoLibrary/trackingVideoLibrary',
  async (
    payload: { videoId: number; watchedTime: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(`/api/video/tracking`, {
        videoId: payload.videoId,
        watchedTime: payload.watchedTime,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getVideoLibraryById = createAsyncThunk(
  'videoLibrary/getVideoLibraryById',
  async (videoId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/video/${videoId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getNotesVideoLibraryById = createAsyncThunk(
  'videoLibrary/getNotesVideoLibraryById',
  async (videoId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.get(`/api/video/notes/${videoId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const createNoteVideoLibrary = createAsyncThunk(
  'videoLibrary/createNoteVideoLibrary',
  async (
    payload: { videoId: number; time: number; note: string }[],
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(`/api/video/note`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateNoteVideoLibrary = createAsyncThunk(
  'videoLibrary/updateNoteVideoLibrary',
  async (
    payload: { id: number; videoId: number; time: number; note: string }[],
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.put(`/api/video/note`, payload);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteNoteVideoLibrary = createAsyncThunk(
  'videoLibrary/updateNoteVideoLibrary',
  async (noteId: number, { rejectWithValue }) => {
    try {
      const { data } = await request.delete(`/api/video/note/${noteId}`);
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const searchVideoLibrary = createAsyncThunk(
  'videoLibrary/searchVideoLibrary',
  async (
    payload: { keyword: string; language: number },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.get(
        `/api/video/search?keyword=${payload.keyword}&language=${payload.language}`
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const initSessionAnalytics = createAsyncThunk(
  'videoLibrary/initSessionAnalytics',
  async (
    payload: {
      videoId: number;
      os: string;
      platform: string;
      browser: string;
      device: string;
      category: number[];
      seriesId: number;
      resolution: {
        width: number;
        height: number;
      };
      events: EventAnalytics[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(`/api/video-analytic/init-session`, {
        videoId: payload.videoId,
        os: payload.os,
        platform: payload.platform,
        browser: payload.browser,
        device: payload.device,
        category: payload.category,
        seriesId: payload.seriesId,
        resolution: {
          width: payload.resolution.width,
          height: payload.resolution.height,
        },
        events: payload.events,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addEventAnalytics = createAsyncThunk(
  'videoLibrary/addEventAnalytics',
  async (
    payload: {
      id: string;
      events: EventAnalytics[];
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(`/api/video-analytic/event`, {
        id: payload.id,
        events: payload.events,
      });
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const eventBuyGolftecPlus = createAsyncThunk(
  'videoLibrary/eventBuyGolftecPlus',
  async (
    payload: {
      videoId: number;
      categoriesId: number[];
      seriesId: number;
      eventTime: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await request.post(
        `/api/video-analytic/subscription-event`,
        {
          videoId: payload.videoId,
          categoriesId: payload.categoriesId,
          seriesId: payload.seriesId,
          eventTime: payload.eventTime,
        }
      );
      return data;
    } catch (e) {
      let error = e as AxiosError;
      return rejectWithValue(error?.response?.data);
    }
  }
);

interface VideoLibraryState {
  categoriesVideoLibrary: null | CategoryVideoLibrary[];
  loadingCategorySeriesVideoLibrary: boolean;
  arraySeriesVideoLibrary: null | AllSeriesVideoLibrary;
  seriesVideoLibraryById: null | SeriesVideoLibrary;
  loadingSeriesVideoLibraryById: boolean;
  statusOpenModalSocial: 'video' | 'series' | '';
  videoLibraryById: null | VideoLibrary;
  loadingVideoLibraryById: boolean;
  statusVideoPlay: boolean;
  notesLibraryById: null | Note[];
  loadingNotesLibraryById: boolean;
  currentTimeVideoPlay: number;
  chooseClassSeries: null | ClassesSeriesIndexVideoLibrary;
  indexInChooseClassSeries: number;
  dataSearchVideoLibrary: null | {
    videos: VideoLibrary[];
    series: SeriesVideoLibrary[];
    categories: CategoryVideoLibrary[];
  };
  dataRecentKeywords: string[];
  loadingSearchVideoLibrary: boolean;
  sessionAnalytics: DataSessionAnalytics | null;
  loadingTrackingVideo: boolean;
  useIntroVideo: VideoLibrary | null;
  statusPlayWithOutVideo: boolean;
  groupSeriesChosen: SeriesVideoLibrary[] | null;
  listVideoFavorites: VideoLibrary[] | null;
  // arraySeriesWatchedInGroup: number[];
  videoShare: null | VideoLibrary;
}

const initialState: VideoLibraryState = {
  categoriesVideoLibrary: null,
  loadingCategorySeriesVideoLibrary: false,
  arraySeriesVideoLibrary: null,
  seriesVideoLibraryById: null,
  loadingSeriesVideoLibraryById: false,
  statusOpenModalSocial: '',
  videoLibraryById: null,
  loadingVideoLibraryById: false,
  statusVideoPlay: false,
  notesLibraryById: null,
  loadingNotesLibraryById: false,
  currentTimeVideoPlay: 0,
  chooseClassSeries: null,
  indexInChooseClassSeries: 0,
  dataSearchVideoLibrary: null,
  dataRecentKeywords: [],
  loadingSearchVideoLibrary: false,
  sessionAnalytics: null,
  loadingTrackingVideo: false,
  useIntroVideo: null,
  statusPlayWithOutVideo: false,
  groupSeriesChosen: null,
  listVideoFavorites: null,
  // arraySeriesWatchedInGroup: [],
  videoShare: null,
};

export const videoLibrary = createSlice({
  name: 'videoLibrary',
  initialState,
  reducers: {
    changeStatusOpenModalSocial: (state, action) => {
      state.statusOpenModalSocial = action.payload;
    },
    changeStatusVideoPlay: (state, action) => {
      state.statusVideoPlay = action.payload;
    },
    changeCurrentTimeVideo: (state, action) => {
      state.currentTimeVideoPlay = action.payload;
    },
    changeChooseClassSeries: (state, action) => {
      state.chooseClassSeries = action.payload;
    },
    changeIndexInChooseClassSeries: (state, action) => {
      state.indexInChooseClassSeries = action.payload;
      state.useIntroVideo = null;
      state.currentTimeVideoPlay = 0;
      if (state.chooseClassSeries) {
        state.videoLibraryById = state.chooseClassSeries.videos[action.payload];
        state.notesLibraryById =
          state.chooseClassSeries.videos[action.payload].notes;
      }
    },
    changeKeywordsSearchVideoLibrary: (state, action) => {
      state.dataRecentKeywords = action.payload;
    },
    changeUseIntroVideo: (state, action) => {
      state.useIntroVideo = action.payload;
      state.chooseClassSeries = null;
      state.videoLibraryById = null;
      state.notesLibraryById = null;
      state.currentTimeVideoPlay = 0;
    },
    changeStatusPlayWithOutVideo: (state, action) => {
      state.statusPlayWithOutVideo = action.payload;
    },
    changeGroupSeriesChosen: (state, action) => {
      state.groupSeriesChosen = action.payload;
    },
    changeVideoShare: (state, action) => {
      state.videoShare = action.payload;
    },
    // changeSeriesWatchedInGroup: (state, action) => {
    //   state.arraySeriesWatchedInGroup = action.payload;
    // },
    resetClassAndVideoLibrary: (state) => {
      state.seriesVideoLibraryById = null;
      state.chooseClassSeries = null;
      state.videoLibraryById = null;
      state.indexInChooseClassSeries = 0;
      state.notesLibraryById = null;
      state.sessionAnalytics = null;
      state.useIntroVideo = null;
      state.statusPlayWithOutVideo = false;
      state.currentTimeVideoPlay = 0;
      state.dataSearchVideoLibrary = null;
      state.videoShare = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoriesVideoLibrary.pending, (state, action) => {
      state.loadingCategorySeriesVideoLibrary = true;
    });
    builder.addCase(getCategoriesVideoLibrary.fulfilled, (state, action) => {
      state.loadingCategorySeriesVideoLibrary = false;
      state.categoriesVideoLibrary = action.payload.data;
    });
    builder
      .addCase(getSeriesVideoLibrary.pending, (state) => {
        state.loadingCategorySeriesVideoLibrary = true;
      })
      .addCase(getSeriesVideoLibrary.fulfilled, (state, action) => {
        state.loadingCategorySeriesVideoLibrary = false;
        state.arraySeriesVideoLibrary = action.payload.data;
      });
    builder
      .addCase(getSeriesVideoLibraryById.pending, (state) => {
        state.loadingSeriesVideoLibraryById = true;
      })
      .addCase(getSeriesVideoLibraryById.fulfilled, (state, action) => {
        state.loadingSeriesVideoLibraryById = false;
        state.seriesVideoLibraryById = action.payload.data;
      });
    builder.addCase(initSessionAnalytics.fulfilled, (state, action) => {
      state.sessionAnalytics = action.payload.data;
    });
    builder.addCase(trackingVideoLibrary.pending, (state, action) => {
      state.loadingTrackingVideo = true;
    });
    builder.addCase(trackingVideoLibrary.fulfilled, (state, action) => {
      state.loadingTrackingVideo = false;
    });
    builder.addCase(getListVideoFavorites.fulfilled, (state, action) => {
      state.listVideoFavorites = action.payload.data;
    });
    builder
      .addCase(searchVideoLibrary.pending, (state) => {
        state.loadingSearchVideoLibrary = true;
      })
      .addCase(searchVideoLibrary.fulfilled, (state, action) => {
        state.dataSearchVideoLibrary = action.payload.data;
        state.loadingSearchVideoLibrary = false;
      })
      .addCase(searchVideoLibrary.rejected, (state) => {
        state.dataSearchVideoLibrary = null;
      });

    builder
      .addCase(signOut, () => ({ ...initialState }))
      .addMatcher(
        (action) => action.type.endsWith('/rejected'),
        (state, action) => {
          state.loadingCategorySeriesVideoLibrary = false;
          state.loadingVideoLibraryById = false;
          state.loadingSearchVideoLibrary = false;
          state.loadingTrackingVideo = false;
          state.loadingSeriesVideoLibraryById = false;
        }
      );
  },
});

export const {
  changeStatusOpenModalSocial,
  changeStatusVideoPlay,
  changeCurrentTimeVideo,
  changeChooseClassSeries,
  changeIndexInChooseClassSeries,
  resetClassAndVideoLibrary,
  changeKeywordsSearchVideoLibrary,
  changeUseIntroVideo,
  changeStatusPlayWithOutVideo,
  changeGroupSeriesChosen,
  changeVideoShare,
} = videoLibrary.actions;

export const selectCategoriesVideoLibrary = (state: RootState) =>
  state.videoLibrary.categoriesVideoLibrary;
export const selectSeriesVideoLibrary = (state: RootState) =>
  state.videoLibrary.arraySeriesVideoLibrary;
export const selectLoadingCategorySeriesVideoLibrary = (state: RootState) =>
  state.videoLibrary.loadingCategorySeriesVideoLibrary;
export const selectSeriesVideoLibraryById = (state: RootState) =>
  state.videoLibrary.seriesVideoLibraryById;
export const selectLoadingSeriesVideoLibraryById = (state: RootState) =>
  state.videoLibrary.loadingSeriesVideoLibraryById;
export const selectStatusOpenModalSocial = (state: RootState) =>
  state.videoLibrary.statusOpenModalSocial;
export const selectVideoLibraryById = (state: RootState) =>
  state.videoLibrary.videoLibraryById;
export const selectStatusVideoPlay = (state: RootState) =>
  state.videoLibrary.statusVideoPlay;
export const selectNotesVideoLibraryById = (state: RootState) =>
  state.videoLibrary.notesLibraryById;
export const selectLoadingNotesVideoLibraryById = (state: RootState) =>
  state.videoLibrary.loadingNotesLibraryById;
export const selectCurrentTimeVideoPlay = (state: RootState) =>
  state.videoLibrary.currentTimeVideoPlay;
export const selectChooseClassSeries = (state: RootState) =>
  state.videoLibrary.chooseClassSeries;
export const selectIndexInChooseClassSeries = (state: RootState) =>
  state.videoLibrary.indexInChooseClassSeries;
export const selectSearchVideoKeywords = (state: RootState) =>
  state.videoLibrary.dataRecentKeywords;
export const selectDataSearchVideoLibrary = (state: RootState) =>
  state.videoLibrary.dataSearchVideoLibrary;
export const selectLoadingSearchVideoLibrary = (state: RootState) =>
  state.videoLibrary.loadingSearchVideoLibrary;
export const selectSessionAnalytics = (state: RootState) =>
  state.videoLibrary.sessionAnalytics;
export const selectLoadingTrackingVideo = (state: RootState) =>
  state.videoLibrary.loadingTrackingVideo;
export const selectUseIntroVideo = (state: RootState) =>
  state.videoLibrary.useIntroVideo;
export const selectStatusPlayWithOutVideo = (state: RootState) =>
  state.videoLibrary.statusPlayWithOutVideo;
export const selectGroupSeriesChosen = (state: RootState) =>
  state.videoLibrary.groupSeriesChosen;
export const selectLisVideoFavorites = (state: RootState) =>
  state.videoLibrary.listVideoFavorites;
export const selectVideoShare = (state: RootState) =>
  state.videoLibrary.videoShare;
// export const selectGroupSeriesWatchedInGroup = (state: RootState) =>
//   state.videoLibrary.arraySeriesWatchedInGroup;

export default videoLibrary.reducer;
